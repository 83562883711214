import { gql } from 'graphql-tag';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { useSearchInput } from 'hooks/useSearchInput';

export const GET_ACCOUNTS = gql`
  query GetAccounts($getAccountsInput: GetAccountsInput!) {
    getAccounts(input: $getAccountsInput) {
      count
      results {
        id
        legal_name
        business_dba
        primary_contact
        open_credits {
          credit_total
          num_credits
        }
        redeemed_credits {
          credit_total
          num_credits
        }
        expired_credits {
          credit_total
          num_credits
        }
      }
    }
  }
`;

export function useGetAccounts() {
  const { input: search } = useSearchInput();

  const result = usePersistedQuery(GET_ACCOUNTS, `accounts-all-expired`, {
    variables: {
      getAccountsInput: {
        ...search,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return { ...result, res: result.data };
}
