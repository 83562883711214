import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/Button';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { IssueCreditModal } from 'pages/app/Credits/issueCreditModal';
import MobileSearchInput from '../../../employee/Events/search';
import { useGetAccounts } from '../gql/queries/get-accounts';
import { useGetTotalOpenCredits } from '../gql/queries/get-total-open-credits';
import CreditList from './CreditsList/CreditList';

const IssueButton = styled(Button)`
  border-radius: 5;
  background-color: '#fa4616';
  padding-top: 8;
  padding-bottom: 8;
  text-decoration: 'none';
  color: '#fff';
  font-family: 'Barlow Condensed';
  font-size: 20;
  font-weight: 600;
  text-align: 'center';
  display: 'block';
  line-height: 1.2;
  text-transform: 'uppercase';
  width: 100%;
  height: 40px;
`;

const CreditsMobile = withEventYearFilterContext((props) => {
  const { location } = props;

  const urlParams = new URLSearchParams(location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const [users, setUsers] = useState();
  const [count, setCount] = useState();
  const [search, setSearch] = useState('');
  const [showIssueCredit, setShowIssueCredit] = useState(false);

  const result = useGetTotalOpenCredits();

  const { res } = useGetAccounts();

  const { pathname } = useLocation();
  const isOpenCredits = pathname.includes('/openCredits');
  const isRedeemedCredits = pathname.includes('/redeemedCredits');
  const isExpiredCredits = pathname.includes('/expiredCredits');

  useEffect(() => {
    if (res && res.getAccounts) {
      setUsers(res?.getAccounts?.results);
      setCount(res?.getAccounts?.count);
    }
  }, [res]);

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  };

  if (!users) return false;

  return !result.data?.getTotalOpenCredits ? (
    <div />
  ) : (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        {isOpenCredits && (
          <div style={{ margin: 10, padding: 10 }}>
            <Text type="heading" fontSize={25} lineHeight={28} color="#fff">
              {`Open Credits  ${result.data?.getTotalOpenCredits}`}
            </Text>
          </div>
        )}
        <MobileSearchInput
          placeholder="Search Credits"
          onChange={handleChange}
          value={search}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleKeyPress={handleKeyPress}
        />
        <Spacer size={10} />

        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            margin: 'auto',
          }}
        >
          <IssueButton onClick={() => setShowIssueCredit(true)}>
            {'Issue Credit'}
          </IssueButton>
        </div>

        <Spacer size={10} />
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            margin: 'auto',
          }}
        >
          <YearDropdown showLabel={false} />
        </div>

        <Spacer size={10} />
        <CreditList
          search={search}
          users={users}
          url={
            isOpenCredits
              ? '/admin/openCredits'
              : isRedeemedCredits
              ? '/admin/redeemedCredits'
              : isExpiredCredits
              ? '/admin/expiredCredits'
              : '/admin/financials'
          }
          currentPage={currentPage}
          isMainList={true}
        />
        <div
          style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}
        >
          <Pagination
            count={count ?? 0}
            perPage={15}
            currentPage={currentPage || 1}
            color={'#fff'}
          />
        </div>
        <Spacer size={40} />
      </div>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={true}
      />
    </>
  );
});

export default withTheme(CreditsMobile);
