import moment from 'moment';

export function getTimes(date) {
  if (!date) return;
  return moment(date).format('HH:mm:ss');
}

export function setTime(value) {
  if (!value) {
    const defaultTime = new Date();
    defaultTime.setHours(18);
    defaultTime.setMinutes(0);
    return defaultTime;
  }
  if (Object.prototype.toString.call(value) === '[object Date]') {
    return value;
  }
  const date = new Date();

  const pieces = value.split(':');

  date.setHours(parseInt(pieces[0]));
  date.setMinutes(parseInt(pieces[1]));

  return date;
}
