import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSearchInput } from 'hooks/useSearchInput';

export const REGISTRASTIONS_REPORT = gql`
  mutation GetRegistrationsReport($input: EventRegistrationsInput!) {
    getRegistrationsReport(input: $input) {
      count
      results {
        id
        name
        user {
          first_name
          last_name
          name
        }
        data {
          name
          value
          label
        }
        promo {
          id
          name
        }
        purchase_date
        registration {
          name
          price
          color_code
        }
        is_checked
        discount
        serial_number
      }
    }
  }
`;

export function useGetRegistrationsReport() {
  const { id } = useParams();
  const { input } = useSearchInput();
  const [registrationsMutation] = useMutation(REGISTRASTIONS_REPORT);

  const result = useCallback(
    (typeId, division, search) => {
      return registrationsMutation({
        variables: {
          input: {
            ...input,
            ...(typeId ? { ticket_id: Number(typeId) } : null),
            ...(division ? { division } : null),
            ...(search ? { queryString: search } : null),
            event_id: Number(id),
          },
        },
        // skip: skip,
      });
    },
    [registrationsMutation, input, id]
  );
  return result;
}
