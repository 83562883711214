import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from 'components/Loading';
import { AppContext } from 'shared/AppContext';
import { useCart } from 'shared/CartContext';
import { useGetAccount } from '../Accounts/gql/queries/useGetAccount';
import { useGetEvent } from '../Events/EventsMenu/gql/queries/get-event';
import { CartList } from './Components/CartList';
import { EmptyCart } from './Components/EmptyCart';
import { PaymentOption } from './Components/PaymentOption';
import { ReaderIpModal } from './Components/ReaderIpModal';
import { TicketHeader } from './Components/TicketHeader';
import { TicketList } from './Components/TicketList';

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(0, 0, 31);
  color: white;
  font-size: large;
  font-family: 'Barlow Condensed';
  height: 100dvh; /* Fixed height on larger screens */

  @media (max-width: 768px) {
    height: auto; /* Allows expansion based on content */
    min-height: 90vh; /* Ensures it starts from the viewport height */
    overflow-y: auto; /* Enables scrolling if content exceeds max-height */
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack children vertically */
  flex: 65%; /* Proportional size within its parent */
  padding: 20px;
  box-sizing: border-box;
  height: 100%; /* Full height of its parent */
  overflow: hidden; /* Prevent content overflow */

  @media (max-width: 768px) {
    max-width: -webkit-fill-available;
    max-height: 120vh;
    overflow: auto;
  }
`;

const RightContainer = styled.div`
  flex: 35%;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  padding-left: 5px;
  @media (max-width: 1098px) {
    background: #00001f;
  }
  display: flex;

  @media (max-width: 768px) {
    padding-left: 20px;
  }
`;

const CartPage = () => {
  const params = useParams();
  const EventId = parseInt(params.id);

  const {
    ticketCount,
    setSeatId,
    setAccountId,
    accountId,
    event,
    setEvent,
    setAccount,
    tickets,
    setSelected,
  } = useCart();

  const { data, loading } = useGetEvent(EventId);

  const {
    state: { account },
  } = useContext(AppContext);

  const { data: accountData } = useGetAccount(
    event?.account_id,
    !event || account
  );

  const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const payment_intent = urlParams.get('payment_intent');
  const payment_id = urlParams.get('payment_intent_client_secret');

  const [isReaderIpVisible, setIsReaderIpVisible] = useState(false);

  useEffect(() => {
    // Clear storage initially
    localStorage.removeItem('tickets');
    localStorage.removeItem('selectedTicket');

    // Remove any old timer keys
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('timer')) localStorage.removeItem(key);
    });
  }, []);

  useEffect(() => {
    if (data && data.getEvent) {
      setEvent(data.getEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (accountData && accountData.getAccount) {
      setAccount(accountData.getAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData]);

  useEffect(() => {
    if (event && event.seats_id) {
      setSeatId(event.seats_id);
    }

    if (event && event.account_id) {
      setAccountId(event.account_id);
    }
  }, [data, event, setSeatId, setAccountId]);

  useEffect(() => {
    // Check if payment_intent and payment_id are in the url and open payment option modal if they are
    if (payment_intent && payment_id && accountId) {
      setIsCheckoutVisible(true);
    }
  }, [payment_intent, payment_id, accountId]);

  useEffect(() => {
    // Trigger IP modal if print option is enabled for any of the two options
    if (
      event?.pos_settings?.ticket_delivery === 'print' ||
      event?.pos_settings?.receipt_delivery === 'print'
    ) {
      setIsReaderIpVisible(true);
    } else {
      setIsReaderIpVisible(false);
    }
  }, [event]);

  // Reset selected ticket on initial load
  useEffect(() => {
    setSelected(Object.keys(tickets)[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !data) {
    return <Loading />;
  }

  return (
    <MainContainer>
      <LeftContainer>
        <TicketHeader event={event} />

        <TicketList />
      </LeftContainer>
      <RightContainer>
        {ticketCount === 0 ? <EmptyCart /> : <CartList />}
      </RightContainer>

      <PaymentOption
        isVisible={isCheckoutVisible}
        setIsVisible={setIsCheckoutVisible}
        manualCardPayment={!!payment_intent}
        accountIdPayment={accountId}
      />

      <ReaderIpModal
        isVisible={isReaderIpVisible}
        setIsVisible={setIsReaderIpVisible}
      />
    </MainContainer>
  );
};

export default CartPage;
