import React from 'react';
import { toast } from 'react-toastify';
import styled, { withTheme } from 'styled-components';
import { ExportExcelButton } from 'components/ExportExcelButton/ExportExcelButton';
import { useGetExportCustomers } from './gql/queries/get-export-customers';

const Container = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 20px 40px 0 40px;
  padding: 0;
  width: calc(100% - 80px);
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (max-width: 859px) {
    display: none;
  }
`;

const Export = () => {
  const getCustomers = useGetExportCustomers();

  const handleExport = async () => {
    const result = await getCustomers();

    if (result?.data?.getCustomerExport) {
      toast.success(
        `Request Successful! You will receive the report attached to an email once we have finished compiling the data.`
      );
    } else {
      toast.error(`Failed to export customers`);
    }
  };

  return (
    <Container onClick={handleExport}>
      <ExportExcelButton />
    </Container>
  );
};

export default withTheme(Export);
