import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ExportExcelButton } from 'components/ExportExcelButton/ExportExcelButton';
import { useGetAccountOpenCreditsReport } from '../gql/useGetAccountOpenCreditsReport.mutation';
import { useGetUserOpenCreditsReport } from '../gql/useGetUserOpenCreditsReport.mutation';
import { useGetUserOpenCreditsReportTrack } from '../gql/useGetUserOpenCreditsReportTrack.mutation';

const Container = styled.button`
  border-radius: 5px;
  background-color: #fa4616;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;

  &:focus {
    outline: none;
  }

  @media (min-width: 860px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 10px 0 10px;
    background-color: transparent;
    cursor: pointer;
    width: auto;
  }
`;

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const exportCsv = async (
  getUserOpenCreditReport,
  headerText,
  isAccountReport = false,
  totalCredit
) => {
  try {
    const response = await getUserOpenCreditReport();

    if (!response || response.errors) {
      toast.error('User Open Credit Report can not be retrieved');
      return;
    }

    if (response.data) {
      const { results } = isAccountReport
        ? response.data.getAccountOpenCreditsReport
        : response.data.getUserOpenCreditsByAccountReport;

      let _title = '',
        filename = '';

      _title = moment().format('MMM DD - YYYY h:mm A').toUpperCase();

      filename = isAccountReport
        ? 'Account Open Credit Report - '
        : 'User Open Credit Report - ' + _title;

      const dateTimeStamp = moment().format('MMM DD - YYYY h:mm A');

      const headers = [
        'Ticket Holder',
        'Credit Total',
        'Credit #',
        'Issued',
        'Issued By',
        'Original Total',
        'Track Credit',
        'Fee Credit',
      ];

      const csv = `${headerText} ${
        isAccountReport
          ? 'Account Open Credit Report'
          : 'User Open Credit Report'
      }
      Exported:, ${dateTimeStamp} \n ${
        totalCredit ? 'Total Open Credits: ' : ''
      }, ${totalCredit ? totalCredit : ''}
        \n,,,
      ${headers.join(',')}\n
      ${Object.values(results)
        .map(
          (value) =>
            `${value.user.formatted_name.split(',').join('')},${value.amount},${
              value.id
            },${value.date},${value.issued_by.formatted_name
              .split(',')
              .join('')},${value.original_amount},${value.track_credit},${
              value.fee_credit
            },`
        )
        .join('\n')}`;

      download(`${filename}.csv`, csv.trim());
    } else {
      toast.error('Events List Report can not be retrieved');
    }
  } catch (e) {}
};

const ExportOpenCreditsReport = ({
  headerText,
  year,
  isTrackAdmin,
  isAccountReport,
  totalCredit,
}) => {
  const getUserOpenCreditReport = useGetUserOpenCreditsReport(year);
  const getUserOpenCreditReportTrack = useGetUserOpenCreditsReportTrack(year);
  const getAccountOpenCreditReport = useGetAccountOpenCreditsReport(year);

  return (
    <div>
      <Container
        onClick={() =>
          exportCsv(
            isAccountReport
              ? getAccountOpenCreditReport
              : isTrackAdmin
              ? getUserOpenCreditReportTrack
              : getUserOpenCreditReport,
            headerText,
            isAccountReport,
            totalCredit
          )
        }
      >
        <ExportExcelButton />
      </Container>
    </div>
  );
};

export default ExportOpenCreditsReport;
