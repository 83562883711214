import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'components/Button';

const getDigits = (barcode) => {
  const digits = barcode?.match(/\d+/g);
  return digits ? digits.join('') : '';
};

const getString = (barcode) => {
  const digits = barcode?.match(/[a-zA-Z]+/g);
  return digits ? digits.join('') : '';
};

export const getTickets = (transaction) => {
  return transaction.users
    .reduce((acc, user) => {
      return acc.concat(user.tickets);
    }, [])
    .filter((ticket) => 'bundle' !== ticket.type && !ticket.refunded);
};

const format_reserve_seat_info = ({ seat_id }) => {
  if (!seat_id) {
    return null;
  }

  const pieces = seat_id.split('-');
  return { section: pieces[0], row: pieces[1], seat: pieces[2] };
};

export const sendPrintJob = async (prn) => {
  return new Promise((resolve, reject) => {
    prn.startMonitor();
    prn.send();
    prn.onreceive = (res) => {
      if (res.success) {
        return resolve();
      } else {
        reject();
      }
    };
    prn.stopMonitor();
  });
};

export const addPrintLogo = (prn) => {
  const canvas = document.getElementById('canvas');
  const context = canvas.getContext('2d');
  context.drawImage(document.getElementById('logo'), 0, 0, 300, 150);
  const width = 300; // Adjust as per your printer's specifications
  const height = 150; // Adjust height accordingly

  // Add the image to the print job
  prn.addImage(context, 0, 0, width, height, prn.COLOR_1);
};

export const printTickets = async ({ printer, transaction, setIsPrinting }) => {
  // Show Printing modal
  if (setIsPrinting) setIsPrinting(true);

  const prn = printer.current;

  if (!prn) {
    alert('Not connected to printer');
    return;
  }

  const tickets = getTickets(transaction);

  // Loop through tickets and print each asynchronously
  for (const ticket of tickets) {
    const reserved_info = format_reserve_seat_info(ticket);

    try {
      prn.addTextAlign(prn.ALIGN_CENTER);
      prn.addTextSmooth(true);

      addPrintLogo(prn);
      prn.addFeedLine(2);

      prn.addTextSize(2, 2);
      prn.addTextStyle(false, false, true, prn.COLOR_1);
      prn.addTextFont(prn.FONT_C);
      prn.addText(ticket.name);

      prn.addTextFont(prn.FONT_A);
      prn.addTextStyle(false, false, false, prn.COLOR_1);

      if (reserved_info) {
        prn.addFeedLine(2);
        prn.addTextSize(2, 2);

        if (
          reserved_info.section &&
          reserved_info.section.includes('section')
        ) {
          prn.addText(reserved_info.section);
        } else if (reserved_info.section) {
          prn.addText(`Section ${reserved_info.section}`);
        }

        prn.addFeedLine(2);
        prn.addText(`Row: ${reserved_info.row}   Seat: ${reserved_info.seat}`);
      }

      prn.addFeedLine(2);
      prn.addSymbol(
        ticket.barcode,
        prn.SYMBOL_QRCODE_MODEL_2,
        prn.LEVEL_Q,
        14,
        7,
        50
      );
      prn.addFeedLine(2);

      prn.addTextSize(3, 3);
      prn.addText(`${getDigits(ticket.barcode)} ${getString(ticket.barcode)}`);
      prn.addFeedLine(3);

      prn.addTextSize(2, 2);
      prn.addText(ticket.date);

      prn.addFeedLine(2);
      prn.addText(transaction.event.name);
      prn.addFeedLine(2);
      prn.addTextSize(1, 1);
      prn.addText(transaction.track.name);
      prn.addFeedLine(2);
      prn.addTextSize(2, 2);

      if (transaction.card) {
        prn.addText(`Order # ${transaction.id} | CARD`);
      } else {
        prn.addText(`Order # ${transaction.id} | CASH`);
      }

      prn.addFeedLine(2);
      prn.addTextSize(1, 1);
      prn.addText('RAIN CHECK - KEEP THIS TICKET');
      prn.addFeedLine(2);
      prn.addText('Get the Ticket Hoss App and Skip the Line!');
      prn.addFeedLine(5);
      prn.addCut(prn.CUT_FEED);

      // Send the print command and wait for it to complete
    } catch (error) {
      alert(`Error printing ticket for ${ticket.name}: ${error}`);
    }
  }

  await sendPrintJob(prn);
  // Hide Printing modal after completion
  if (setIsPrinting) setIsPrinting(false);
};

const STATUS_CONNECTED = 'Connected';

const PrintTickets = ({
  connectionStatus,
  printer,
  transaction,
  setIsPrinting,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const onPrintTicket = () => {
    printTickets({ printer, transaction, setIsPrinting: setIsPrinting });
  };

  return (
    <Button
      buttonStyle={
        isMobile ? { width: '100px', margin: '0 10px' } : { margin: '0 10px' }
      }
      onClick={onPrintTicket}
      disabled={connectionStatus !== STATUS_CONNECTED}
    >
      PRINT TICKETS
    </Button>
  );
};

export default PrintTickets;
