import React from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';
import { ExternalAnchor } from 'components/Anchor';
import { Button } from 'components/Button';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import { PhoneInput } from 'components/Form/PhoneInput';
import Tracks from 'components/Form/Tracks';
import { SeriesSelect } from 'components/SeriesSelect';
import { SectionWrapper } from '../AddAccounts';

const currencies = ['USD', 'CAD'];

const AccountDetailForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <SectionWrapper>
      <Input
        id="business_dba"
        name="business_dba"
        label="Business DBA"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={values.business_dba ? values.business_dba : ''}
        error={errors.business_dba ? errors.business_dba : ''}
      />

      <Input
        id="primary_contact"
        name="primary_contact"
        label="Primary Contact"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={values.primary_contact ? values.primary_contact : ''}
        error={errors.primary_contact ? errors.primary_contact : ''}
      />

      <SeriesSelect isMulti />

      <Input
        id="legal_name"
        name="legal_name"
        label="Legal Name"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={values.legal_name ? values.legal_name : ''}
        error={errors.legal_name ? errors.legal_name : ''}
      />

      <Input
        id="email"
        name="email"
        label="Email"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={values.email ? values.email : ''}
        error={errors.email ? errors.email : ''}
      />

      <Tracks
        label={'Tracks'}
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldValue}
        isMulti
      />

      <div>
        <AutoSuggest
          id="currency"
          label="Currency"
          closeMenuOnSelect
          options={currencies.map((currency) => ({
            value: currency,
            label: currency,
          }))}
          onChange={(value) => {
            setFieldValue('currency', value.value);
          }}
          value={{
            label: values.currency,
            value: values.currency,
          }}
          error={errors.currency}
        />
      </div>

      <PhoneInput
        id="phone"
        placeholder="___ ___ ____"
        size={25}
        label="Phone Number"
        onBlur={handleBlur}
        onChange={(value) => {
          setFieldValue('phone', value ?? '');
        }}
        onCountryChange={(e) => {
          if (e) {
            setFieldValue('country_code', e ?? '');
            setFieldValue('calling_code', getCountryCallingCode(e) ?? '');
          }
        }}
        value={values.phone}
        error={errors.phone}
      />

      <Input
        id="stripe_account_id"
        name="stripe_account_id"
        label="Stripe ID"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={values.stripe_account_id ? values.stripe_account_id : ''}
        error={errors.stripe_account_id ? errors.stripe_account_id : ''}
      />

      {values.stripe_url && (
        <ExternalAnchor
          href={values.stripe_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button buttonStyle={{ width: '100%', height: 46 }} type="button">
            View Stripe Account
          </Button>
        </ExternalAnchor>
      )}

      <Input
        id="title"
        name="title"
        label="Title"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={values.title ? values.title : ''}
        error={errors.title ? errors.title : ''}
      />

      <Dropzone
        id="logo_id"
        files={
          values.logo_id
            ? [
                {
                  name: values.logo_id,
                  preview: values.logo,
                },
              ]
            : []
        }
        onChange={setFieldValue}
        error={errors.logo_id && errors.logo_id}
        label="Account Logo"
      />
    </SectionWrapper>
  );
};

export default AccountDetailForm;
