import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const CREATE_CREDIT = gql`
  mutation CreateCredit($createCreditInput: CreateCreditInputV2!) {
    createCreditV2(input: $createCreditInput) {
      id
      user {
        formatted_name
      }
      amount
      date
      issued_by {
        formatted_name
      }
    }
  }
`;

export function useCreateCredit() {
  const [createCreditMutation] = useMutation(CREATE_CREDIT);

  const createCredit = useCallback(
    (
      user_id,
      account_id,
      credit_amount,
      message,
      firstName,
      lastName,
      cellphone,
      reason
    ) => {
      return createCreditMutation({
        variables: {
          createCreditInput: {
            user_id: user_id,
            account_id: account_id,
            credit_amount: credit_amount,
            message: message,
            first_name: firstName,
            last_name: lastName,
            cellphone,
            reason,
          },
        },
      });
    },
    [createCreditMutation]
  );
  return createCredit;
}
