import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/Button';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { IssueCreditModal } from 'pages/app/Credits/issueCreditModal';
import CreditList from 'pages/track/CreditTrackMobile/CreditsList/CreditList';
import MobileSearchInput from '../../../../employee/Events/search';
import { useGetAccountExpiredCredits } from '../../ExpiredCredits/gql/get-account-expired-credits';

const IssueButton = styled(Button)`
  border-radius: 5;
  background-color: '#fa4616';
  padding-top: 8;
  padding-bottom: 8;
  text-decoration: 'none';
  color: '#fff';
  font-family: 'Barlow Condensed';
  font-size: 20;
  font-weight: 600;
  text-align: 'center';
  display: 'block';
  line-height: 1.2;
  text-transform: 'uppercase';
  width: 100%;
  height: 40px;
`;

const ExpiredCreditMobile = withEventYearFilterContext((props) => {
  const { location } = props;

  const urlParams = new URLSearchParams(location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const [users, setUsers] = useState();
  const [count, setCount] = useState();
  const [search, setSearch] = useState('');
  const [showIssueCredit, setShowIssueCredit] = useState(false);

  const { id: account_id } = useParams();

  const { resExpired } = useGetAccountExpiredCredits();

  useEffect(() => {
    if (resExpired && resExpired.getAccountExpiredCredits) {
      setUsers(resExpired?.getAccountExpiredCredits?.results);
      setCount(resExpired?.getAccountExpiredCredits?.count);
    }
  }, [resExpired]);

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  };

  return (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <MobileSearchInput
          placeholder="Search Credits"
          onChange={handleChange}
          value={search}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleKeyPress={handleKeyPress}
        />
        <Spacer size={10} />

        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            margin: 'auto',
          }}
        >
          <IssueButton onClick={() => setShowIssueCredit(true)}>
            {'Issue Credit'}
          </IssueButton>
        </div>

        <Spacer size={10} />
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            margin: 'auto',
          }}
        >
          <YearDropdown showLabel={false} />
        </div>

        <Spacer size={10} />
        {users ? (
          <CreditList
            search={search}
            users={users}
            url={`/admin/expiredCredits/${account_id}/creditDetail`}
            currentPage={currentPage}
          />
        ) : null}
        <div
          style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}
        >
          <Pagination
            count={count ?? 0}
            perPage={15}
            currentPage={currentPage || 1}
            color={'#fff'}
          />
        </div>
        <Spacer size={40} />
      </div>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={true}
      />
    </>
  );
});

export default withTheme(ExpiredCreditMobile);
