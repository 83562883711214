import { Formik } from 'formik';
import moment from 'moment';
import React, { useRef } from 'react';
import { Col, Row } from 'react-grid-system';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ExternalAnchor } from 'components/Anchor';
import { Button } from 'components/Button';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { TitleContainer } from 'pages/track/Contact/style';
import CustomerDetail from 'pages/track/Customers/CustomerDetail';
import GetPendingUser from '../TemporaryUsers/gql/get-pending-user';
import { useVerifyUser } from './gql/approve-users';

const ColWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
`;

const Col1 = styled.div`
  width: 25%;
  margin: 10;
`;

const Col2 = styled.div`
  width: 25%;
  margin: 40px 0 0 100px;
`;

const formValues = [
  'user_id',
  'last_name',
  'middle_name',
  'first_name',
  'suffix',
  'cellphone',
  'created',
  'email',
  'birthday',
  'address_1',
  'address_2',
  'city',
  'state',
  'zipcode',
  'selfie_image',
  'temp_image_one',
  'temp_image_two',
  'image_id',
  'image',
  'status',
];

function getInitialValues(user, keys) {
  const blankValues = {
    ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
  };

  const initialValues = !user
    ? blankValues
    : {
        ...keys.reduce((formValues, key) => {
          return user[key]
            ? {
                ...formValues,
                [key]: user[key],
              }
            : formValues;
        }, {}),
        user_id: user?.id,
      };

  return initialValues;
}
const successMessage = () =>
  toast.success('Verificaion Completed Successfully');
const errorMessage = (response) =>
  toast.error(
    response
      ? response?.global
        ? 'Error Verifying User'
        : "There were errors with your submission check the form's field for errors."
      : 'Error Verifying User'
  );

const ApproveUser = (props) => {
  const { data, history, hideButtons = false } = props;
  const verifyUser = useVerifyUser();
  const formRef = useRef();

  return !data ? (
    <></>
  ) : !data.getPendingUser ? (
    <></>
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={getInitialValues(data?.getPendingUser, formValues)}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formRef}
      validate={(values) => {
        const errors = {};

        if (!values.last_name) {
          errors.last_name = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);

        delete values.created;
        delete values.selfie_image;
        delete values.temp_image_one;
        delete values.temp_image_two;
        delete values.image;

        // values.message_center = false;
        // values.registrations = false;
        // values.memberships = false;

        const response = await verifyUser(values);

        if (!response || response.errors) {
          errorMessage(response);
          setSubmitting(false);
          return setErrors(response.errors);
        } else {
          successMessage();
          setSubmitting(false);
          resetForm();
          history.push('/admin/temporaryUsers');
        }

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldError,
      }) => (
        <Container>
          {hideButtons && (
            <ContainerHeader>
              <TitleContainer>
                <Text
                  type="heading"
                  color="#3C4144"
                  inlineStyle={{ marginRight: 25, width: 400 }}
                >
                  User Submitted Data
                </Text>
              </TitleContainer>
            </ContainerHeader>
          )}
          <form onSubmit={handleSubmit} style={{ padding: 30 }}>
            <Spacer size={20} />
            <Row>
              <Col>
                <Input
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.last_name ? values.last_name : ''}
                  error={errors.last_name ? errors.last_name : ''}
                />
              </Col>
              <Col>
                <Input
                  id="middle_name"
                  name="middle_name"
                  label="Middle Name"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.middle_name ? values.middle_name : ''}
                  error={errors.middle_name ? errors.middle_name : ''}
                />
              </Col>
              <Col>
                <Input
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.first_name ? values.first_name : ''}
                  error={errors.first_name ? errors.first_name : ''}
                />
              </Col>
              <Col>
                <Input
                  id="suffix"
                  name="suffix"
                  label="Suffix"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.suffix ? values.suffix : ''}
                  error={errors.suffix ? errors.suffix : ''}
                />
              </Col>
            </Row>

            <Spacer size={20} />

            <Row>
              <Col>
                <Input
                  id="cellphone"
                  name="cellphone"
                  label="Mobile Number"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.cellphone ? values.cellphone : ''}
                  error={errors.cellphone ? errors.cellphone : ''}
                />
              </Col>
              <Col>
                <Input
                  id="created"
                  name="created"
                  label="Requested"
                  disabled
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={
                    values.created
                      ? moment(values.created, 'MM-DD-YYYY')
                          .format('ddd MMM D - YYYY')
                          .toUpperCase()
                      : ''
                  }
                  error={errors.created ? errors.created : ''}
                />
              </Col>
              <Col>
                <Input
                  id="email"
                  name="email"
                  label="Email"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.email ? values.email : ''}
                  error={errors.email ? errors.email : ''}
                />
              </Col>
              <Col>
                <Input
                  id="birthday"
                  name="birthday"
                  label="DoB"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.birthday ? values.birthday : ''}
                  error={errors.birthday ? errors.birthday : ''}
                />
              </Col>
            </Row>

            <Spacer size={20} />

            <ColWrapper>
              <Col1>
                <Input
                  id="address_1"
                  name="address_1"
                  label="Adress Line 1"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.address_1 ? values.address_1 : ''}
                  error={errors.address_1 ? errors.address_1 : ''}
                />
              </Col1>
              <Col2>
                <div inlineStyle={{ fontSize: 20 }}>ID Submission</div>
              </Col2>
              {!hideButtons && (
                <div style={{ width: '40%', margin: '0px 0 0 0' }}>
                  <Dropzone
                    id="image_id"
                    files={
                      values.image
                        ? [
                            {
                              name: values.image_id,
                              preview: values.image,
                            },
                          ]
                        : []
                    }
                    onChange={setFieldValue}
                    setError={setFieldError}
                    error={
                      errors.image_id && touched.image_id && errors.image_id
                    }
                    label="Upload Cropped Headshot"
                  />
                </div>
              )}
            </ColWrapper>

            {hideButtons && <Spacer size={20} />}

            <ColWrapper>
              <Col1>
                <Input
                  id="address_2"
                  name="address_2"
                  label="Adress Line 2"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.address_2 ? values.address_2 : ''}
                  error={errors.address_2 ? errors.address_2 : ''}
                />
              </Col1>
              <Col2>
                <ExternalAnchor
                  href={values.selfie_image}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Selfie Front
                </ExternalAnchor>
              </Col2>
            </ColWrapper>

            <Spacer size={20} />

            <ColWrapper>
              <Col1>
                <Input
                  id="city"
                  name="city"
                  label="City"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.city ? values.city : ''}
                  error={errors.city ? errors.city : ''}
                />
              </Col1>
              <Col2>
                <ExternalAnchor
                  href={values.temp_image_one}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ID Front
                </ExternalAnchor>
              </Col2>
              {!hideButtons && (
                <Col2 style={{ marginLeft: '10vw' }}>
                  <Button
                    type="button"
                    style={{ width: 100 }}
                    onClick={() => {
                      values.status = 'approved';
                      if (formRef.current) {
                        formRef.current.handleSubmit();
                      }
                    }}
                  >
                    Approve User
                  </Button>
                </Col2>
              )}
            </ColWrapper>

            <Spacer size={20} />

            <ColWrapper>
              <Col1>
                <Input
                  id="state"
                  name="state"
                  label="State / Province"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.state ? values.state : ''}
                  error={errors.state ? errors.state : ''}
                />
              </Col1>
              <Col2>
                <ExternalAnchor
                  href={values.temp_image_two}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ID Back
                </ExternalAnchor>
              </Col2>
              {!hideButtons && (
                <Col2 style={{ marginLeft: '10vw' }}>
                  <Button
                    type="button"
                    style={{ width: 100 }}
                    onClick={() => {
                      values.status = 'declined';
                      if (formRef.current) {
                        formRef.current.handleSubmit();
                      }
                    }}
                  >
                    Deny User
                  </Button>
                </Col2>
              )}
            </ColWrapper>

            <Spacer size={20} />

            <ColWrapper>
              <Col1>
                <Input
                  id="zipcode"
                  name="zipcode"
                  label="Postal Code"
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values.zipcode ? values.zipcode : ''}
                  error={errors.zipcode ? errors.zipcode : ''}
                />
              </Col1>
            </ColWrapper>
          </form>

          {!hideButtons && (
            <CustomerDetail
              match={props.match}
              location={props.location}
              hideIssueCredit={true}
            />
          )}
        </Container>
      )}
    </Formik>
  );
};

export default GetPendingUser(ApproveUser);
