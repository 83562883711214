import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_ANNUAL_TICKET = gql`
  query GetAnnualTicket($id: Int) {
    getAnnualTicket(id: $id) {
      id
      name
      color_code
      price
      description
      track_price
      fee_dollar
      fee_percent
      is_featured
      featured_text
      sale_start
      on_sale_time
      sale_end
      off_sale_time
      start_date
      end_date
      status
      generate_passes
      display_events
      display_track_series
      for_all_events
      limit
      low_ticket
      account_id
      show_fee
      international_fee
      marketing_fee
      track_ids
      tracks {
        id
        name
      }
      series_ids
      series {
        id
        name
      }
      event_ids
      type_id
      type {
        id
        name
      }
      ticket_type
      pages {
        id
        name
        form_id
        pdf_url
        waivers
        order
        pdf_id
        video_url
      }
      created_by {
        formatted_name
      }
      updated_by {
        formatted_name
      }
      notes {
        id
        note
      }
      set_member_number
      last_member_number
      prefix_text
      suffix_text
    }
  }
`;

export function useGetAnnualTicket(id) {
  const variables = {
    id,
  };

  return useQuery(GET_ANNUAL_TICKET, {
    variables,
    fetchPolicy: 'no-cache',
    skip: !id,
  });
}
