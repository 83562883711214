import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router';

export const GET_GA_TICKETS = gql`
  query GetGATicketsV2($event_id: Int!) {
    getGATicketsV2(event_id: $event_id) {
      type {
        id
        name
        logo
      }
      tickets {
        id
        name
        price
        track_price
        fee_dollar
        reserved_seat
        reserved_seat_category
        ticket_tiers {
          id
          price
          label
          ticketType
          track_price
        }
        seat_ids
        reserved_seat_prefix
        reserved_seat_section
      }
    }
  }
`;

export function useGetGATickets() {
  const { id } = useParams();

  return useQuery(GET_GA_TICKETS, {
    variables: {
      event_id: Number(id),
    },
    skip: !id,
  });
}
