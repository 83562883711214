import React from 'react';
import styled from 'styled-components';
import Icon from 'components/Icon';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (min-width: 860px) {
    flex-direction: column;
    padding: 0;
    border: 0;
    width: auto;
    background-color: transparent;
    cursor: pointer;
    min-width: 65px;
    width: auto;

    svg {
      fill: ${(props) => props.theme.colors.primary};
      width: 40px;
    }

    span {
      color: ${(props) => props.theme.colors.text.header};
      font-size: 12px;
      margin-top: 10px;
      margin-left: 0px;
      text-align: center;
    }
  }

  span {
    color: #fff;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    font-weight: 700;
    line-height: 16px;
  }

  svg {
    fill: #fff;
    width: 40px;

    @media (min-width: 860px) {
      fill: #fa4616;
      margin: 0;
    }
  }
`;

export const ExportExcelButton = () => {
  return (
    <IconWrapper>
      <Icon icon="CSV-Icon" />
    </IconWrapper>
  );
};
