import React, { useState } from 'react';
import { Col } from 'react-grid-system';
import { useMediaQuery } from 'react-responsive';
import { ReactSortable } from 'react-sortablejs';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import {
  DraggableRow,
  getHighestItemOrderNumber,
  getOrderedItems,
} from 'components/Form/DraggableFields';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import { Label, LabelWrapper } from 'components/Form/styles';
import Icon from 'components/Icon';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import {
  ResponsiveCol,
  ResponsiveRow,
} from 'pages/app/Events/AddEvents/styles';
import {
  InputWrapper,
  OrderText,
} from 'pages/app/Events/EventEdit/EditEventTickets';
import { WaiversRepeater } from 'pages/app/Events/EventEdit/WaiversRepeater';
import { FormstackAutoSuggest } from './FormstackAutoSuggest';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  width: 85%;
  & > div {
    // Fix for bad design of icomoon-react wrapper
    line-height: 14px;
  }
`;

export const AnnualPages = ({
  onChange,
  handleBlur,
  errors,
  touched,
  annualPages,
  onChangePage,
  values,
  handleSort,
}) => {
  const annualPagesWithOrder = getOrderedItems(
    values['pages_with_order'] || []
  );

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Col>
      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 60%' : '0 0 100%' }}>
          {annualPages
            ? annualPages.map((page, index) => (
                <Page
                  onChange={onChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  annualPages={annualPages}
                  annualPagesWithOrder={values.pages_with_order}
                  onChangePage={onChangePage}
                  index={index}
                  page={page}
                  key={index}
                  values={values}
                />
              ))
            : null}

          <ResponsiveRow>
            <ResponsiveCol>
              <Button
                type="button"
                onClick={() => {
                  onChangePage('pages', [
                    ...annualPages,
                    {
                      type: null,
                      order: annualPages
                        ? getHighestItemOrderNumber(annualPages) + 1
                        : 1,
                    },
                  ]);
                  onChangePage('pages_with_order', [
                    ...values['pages_with_order'],
                    {
                      type: null,
                      order: annualPages
                        ? getHighestItemOrderNumber(annualPages) + 1
                        : 1,
                    },
                  ]);
                }}
                buttonStyle={{ width: !isMobile ? '20vw' : '80vw' }}
              >
                {annualPages.length > 0 ? 'ADD ANOTHER PAGE' : 'ADD PAGE'}
              </Button>
            </ResponsiveCol>
          </ResponsiveRow>
        </ResponsiveCol>

        <ResponsiveCol
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginLeft: !isMobile ? '60px' : 0,
            flex: !isMobile ? '0 0 30%' : '0 0 100%',
          }}
        >
          {annualPagesWithOrder?.length > 0 && (
            <ResponsiveCol>
              <LabelWrapper style={{ marginLeft: -20 }}>
                <Label>{'Pages Order In App'}</Label>
              </LabelWrapper>
              <ReactSortable
                list={annualPagesWithOrder}
                setList={(values) => handleSort('pages_with_order', values)}
              >
                {annualPagesWithOrder.map((page) => (
                  <DraggableRow key={page.order}>
                    <InputWrapper style={{ width: '100%' }}>
                      <OrderText>{page.name}</OrderText>
                    </InputWrapper>
                  </DraggableRow>
                ))}
              </ReactSortable>
            </ResponsiveCol>
          )}
        </ResponsiveCol>
      </ResponsiveRow>

      <Spacer size={30} />
    </Col>
  );
};

const Page = ({
  onChange,
  handleBlur,
  errors,
  annualPages,
  annualPagesWithOrder,
  page,
  onChangePage,
  index,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <ButtonWrapper>
        <div style={{ marginTop: 10 }}>
          <Icon
            icon="trash"
            size={25}
            color={theme.colors.primary}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          />
        </div>
      </ButtonWrapper>
      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 80%' : '0 0 100%' }}>
          <Spacer size={10} />
          <Input
            id={`pages.${index}.name`}
            name={`pages.${index}.name`}
            label="Page Name"
            placeholder=""
            onChange={({ target }) => {
              onChange({
                target: {
                  name: 'name',
                  value: target.value,
                },
                order: page.order,
              });
              errors[`pages.${index}.name`] = '';
            }}
            onBlur={handleBlur}
            value={page.name ?? ''}
            error={
              errors[`pages.${index}.name`] ? errors[`pages.${index}.name`] : ''
            }
          />
        </ResponsiveCol>
      </ResponsiveRow>
      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 80%' : '0 0 100%' }}>
          <Spacer size={10} />
          <Input
            id={`pages.${index}.pdf_url`}
            name={`pages.${index}.pdf_url`}
            label="PDF Url"
            placeholder=""
            onChange={({ target }) => {
              onChange({
                target: {
                  name: 'pdf_url',
                  value: target.value,
                },
                order: page.order,
              });
              errors[`pages.${index}.pdf_url`] = '';
            }}
            onBlur={handleBlur}
            value={page.pdf_url ?? ''}
            error={
              errors[`pages.${index}.pdf_url`]
                ? errors[`pages.${index}.pdf_url`]
                : ''
            }
          />
        </ResponsiveCol>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 80%' : '0 0 100%' }}>
          <Spacer size={10} />
          <Input
            id={`pages.${index}.video_url`}
            name={`pages.${index}.video_url`}
            label="Video Url"
            placeholder=""
            onChange={({ target }) => {
              onChange({
                target: {
                  name: 'video_url',
                  value: target.value,
                },
                order: page.order,
              });
              errors[`pages.${index}.video_url`] = '';
            }}
            onBlur={handleBlur}
            value={page.video_url ?? ''}
            error={
              errors[`pages.${index}.video_url`]
                ? errors[`pages.${index}.video_url`]
                : ''
            }
          />
        </ResponsiveCol>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 80%' : '0 0 100%' }}>
          <Spacer size={10} />
          <Dropzone
            id={`pages.${index}.pdf_id`}
            files={
              page.pdf_id
                ? [
                    {
                      name: `pages.${index}.pdf_id`,
                      preview: page.pdf_id
                        ? 'https://d260w0yqhyrp8h.cloudfront.net/PDF_file_icon.svg.png'
                        : '',
                    },
                  ]
                : []
            }
            onChange={(name, value) => {
              if (name.includes('_id'))
                onChange({
                  target: {
                    name: 'pdf_id',
                    value: value,
                  },
                  order: page.order,
                });
              errors[`pages.${index}.pdf_id`] = '';
            }}
            error={
              errors[`pages.${index}.pdf_id`]
                ? errors[`pages.${index}.pdf_id`]
                : ''
            }
            label="PDF"
          />
        </ResponsiveCol>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 80%' : '0 0 100%' }}>
          <Spacer size={10} />

          <span style={{ fontWeight: 'bold' }}>Form ID</span>
          <Spacer size={4} />

          <FormstackAutoSuggest
            onChange={(value) => {
              onChange({
                target: {
                  name: 'form_id',
                  value: value?.value.toString() ?? null,
                },
                order: page.order,
              });
              errors[`pages.${index}.form_id`] = '';
            }}
            value={page.form_id}
          />
        </ResponsiveCol>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 80%' : '0 0 100%' }}>
          <Spacer size={10} />
          <WaiversRepeater
            handleChange={({ target }) => {
              onChange({
                target: {
                  name: 'waivers',
                  value: target.value,
                },
                order: page.order,
              });
              errors[`pages.${index}.waivers`] = '';
            }}
            handleBlur={handleBlur}
            errors={errors}
            values={page}
          />
        </ResponsiveCol>
      </ResponsiveRow>

      <Spacer size={38} />

      <Modal
        isVisible={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        title={'Are you sure you want to remove this Page?'}
        maxWidth={600}
      >
        <Spacer size={20} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            width: '50%',
            margin: 'auto',
          }}
        >
          <Button type="button" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => {
              const newPages = annualPages.filter(
                (_, currentIndex) => currentIndex !== index
              );
              const newPagesOrder = annualPagesWithOrder.filter((pageC) =>
                page.id ? pageC.id !== page.id : pageC.name !== page.name
              );
              onChangePage('pages', newPages);
              onChangePage('pages_with_order', newPagesOrder);
              setShowDeleteModal(false);
            }}
          >
            Okay
          </Button>
        </div>
        <Spacer size={20} />
      </Modal>
    </>
  );
};
