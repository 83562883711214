import { withApollo } from '@apollo/react-hoc';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import Icon from 'components/Icon';
import { useMe } from 'hooks/useMe';
import Images from 'images';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import storage from 'shared/storage';
import { getMenu } from './component/getMenusList';
import SubMenu from './component/SubMenu';
import GetStripeLogin from './gql/get-login';
import {
  GlobalStyle,
  BackgroundContainer,
  NavMenu,
  LogoWrapper,
  Logo,
  NavItem,
  NavItemExternal,
  UserName,
  Wrapper,
  TicketHossLogo,
  AccountsWrapper,
  NavItemButton,
} from './StyledAdminLayout';

const AdminLayout = ({ children, getStripeLogin, history }) => {
  const { data: user } = useMe();
  let menu = [];

  const isTab = useMediaQuery({ query: '(max-width: 1190px)' });

  const [isMenuOpen, setIsMenuOpen] = useState(isTab ? false : true);

  const { dispatch } = useContext(AppContext);

  // Change the state of the menu when the screen is resized
  useEffect(() => {
    setIsMenuOpen(isTab ? false : true);
    dispatch({
      type: 'SET_NAV_EXPANDED',
      navIsExpanded: isTab ? false : true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTab]);

  const [account, setAccount] = useState();
  const [capabilities, setCapabilities] = useState();

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const findCapabilities = (accountId) => {
    // eslint-disable-next-line no-unused-expressions
    user?.me?.capabilities?.forEach((capablity) => {
      if (capablity.account_id === accountId) {
        setCapabilities(capablity);
        dispatch({
          type: 'SET_CAPABILITIIES',
          capabilities: capablity,
        });
      }
    });
  };

  const setAccountId = (accountId) =>
    dispatch({
      type: 'SET_ACCOUNT_ID',
      accountId: accountId,
    });

  const setAccountName = (accountName) =>
    dispatch({
      type: 'SET_ACCOUNT_NAME',
      accountId: accountName,
    });

  const setAccountScanners = (janamScanners) =>
    dispatch({
      type: 'SET_ACCOUNT_SCANNERS',
      janamScanners: janamScanners,
    });

  const setAccountData = (account) =>
    dispatch({
      type: 'SET_ACCOUNT_DATA',
      account: account,
    });

  const setSelectedAccount = (value) => {
    setAccount(value);
    setAccountId(value.value);
    setAccountName(value.label);
    setAccountScanners(value.janam_scanners);
    setAccountData(value.data);
    findCapabilities(value.value);
  };

  useEffect(() => {
    if (user && user.me) {
      //save the me value on the context
      dispatch({
        type: 'SET_ME',
        user: user?.me,
      });

      if (user.me?.role !== 'admin') {
        if (Array.isArray(user?.me?.accounts)) {
          if (user.me.accounts && user.me.accounts.length > 0) {
            setAccount({
              value: user?.me?.accounts[0].id,
              label: user?.me?.accounts[0].business_dba,
              janam_scanners: user?.me?.accounts[0].janam_scanners,
              data: user?.me?.accounts[0],
            });

            const selectedAccount = storage.get('selected_account');

            if (selectedAccount) {
              setSelectedAccount(selectedAccount);
            } else {
              setAccountId(user?.me?.accounts[0].id);
              setAccountName(user?.me?.accounts[0].business_dba);
              setAccountScanners(user?.me?.accounts[0].janam_scanners);
              setAccountData(user?.me?.accounts[0]);

              findCapabilities(user?.me?.accounts[0].id);
            }
          } else {
            setShowPermissionModal(true);
          }
        }
        if (Array.isArray(user?.me?.promoters)) {
          dispatch({
            type: 'SET_PROMOTERS',
            promoters: user?.me?.promoters,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  menu = getMenu(user?.me?.role, capabilities);

  return !user ? null : (
    <Fragment>
      <BackgroundContainer>
        <NavMenu isMenuOpen={isMenuOpen}>
          <Wrapper isMenuOpen={isMenuOpen}>
            {'pitpay' === process.env.REACT_APP_PLATFORM ? (
              <LogoWrapper
                style={{ width: !isMenuOpen ? 40 : '90%' }}
                isMenuOpen={isMenuOpen}
              >
                <Logo
                  src={
                    !isMenuOpen
                      ? Images.pitpayCollapsedLogo
                      : Images.logoHorizontalWhite
                  }
                  style={{ width: !isMenuOpen ? 35 : 200 }}
                />
              </LogoWrapper>
            ) : 'tickethoss' === process.env.REACT_APP_PLATFORM ? (
              <LogoWrapper
                style={{ width: !isMenuOpen ? 40 : '90%' }}
                isMenuOpen={isMenuOpen}
              >
                <TicketHossLogo
                  src={
                    !isMenuOpen
                      ? Images.ticketHossCollapsedLogo
                      : Images.logoTickethossHorizontal
                  }
                  style={{ width: !isMenuOpen ? 35 : 200 }}
                />
              </LogoWrapper>
            ) : (
              <LogoWrapper
                style={{ width: !isMenuOpen ? 40 : '90%' }}
                isMenuOpen={isMenuOpen}
              >
                <Logo
                  src={Images.logoKart}
                  style={{ width: !isMenuOpen ? 30 : 200 }}
                />
              </LogoWrapper>
            )}

            <NavItemButton
              exact
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
                dispatch({
                  type: 'SET_NAV_EXPANDED',
                  navIsExpanded: !isMenuOpen,
                });
              }}
              key={9}
              style={{
                transform: isMenuOpen ? 'scaleX(-1)' : 'none',
                width: isMenuOpen ? '87%' : 'auto',
              }}
            >
              <div style={{ width: 20 }}>
                <Icon icon="Menu-Expand" size={20} color="white" />
              </div>{' '}
            </NavItemButton>

            {user?.me?.role !== 'admin' &&
              user?.me?.accounts?.length > 1 &&
              isMenuOpen && (
                <AccountsWrapper>
                  <AutoSuggest
                    name="account_id"
                    placeholder="Select Account"
                    value={account}
                    onChange={(value) => {
                      setSelectedAccount(value);

                      // Store the selected account in local storage
                      storage.set('selected_account', value);
                    }}
                    closeMenuOnSelect
                    options={user?.me?.accounts?.map((item) => ({
                      value: item.id,
                      label: item.business_dba,
                      janam_scanners: item.janam_scanners,
                      data: item,
                    }))}
                    customStyles={{
                      control: (provided) => ({
                        ...provided,
                        height: 40,
                        width: 190,
                      }),
                    }}
                  />
                </AccountsWrapper>
              )}

            {menu.map((item) =>
              !item.external ? (
                item.shown === true ? (
                  item.submenu &&
                  item.id === 5 &&
                  'tickethoss' !== process.env.REACT_APP_PLATFORM ? (
                    <SubMenu
                      item={item}
                      isMenuOpen={isMenuOpen}
                      setIsMenuOpen={setIsMenuOpen}
                    />
                  ) : item.submenu && item.id !== 5 ? (
                    <SubMenu
                      item={item}
                      isMenuOpen={isMenuOpen}
                      setIsMenuOpen={setIsMenuOpen}
                    />
                  ) : (
                    <NavItem
                      exact
                      to={item.link}
                      key={item.id}
                      isMenuOpen={isMenuOpen}
                    >
                      <div style={{ width: 30, height: 20 }}>
                        <Icon
                          icon={item.icon}
                          color="white"
                          customStyle={{
                            justifyContent: 'center',
                            alignContent: 'center',
                            display: 'flex',
                            width: 30,
                            height: 20,
                          }}
                        />
                      </div>{' '}
                      {!isMenuOpen ? '' : item.name}
                    </NavItem>
                  )
                ) : item.shown === false ? (
                  <NavItemButton
                    exact
                    onClick={() => {
                      setShowPermissionModal(true);
                    }}
                    key={item.id}
                    isMenuOpen={isMenuOpen}
                  >
                    <div style={{ width: 30, height: 20 }}>
                      <Icon
                        icon={item.icon}
                        color="white"
                        customStyle={{
                          justifyContent: 'center',
                          alignContent: 'center',
                          display: 'flex',
                          width: 30,
                          height: 20,
                        }}
                      />
                    </div>{' '}
                    {!isMenuOpen ? '' : item.name}
                  </NavItemButton>
                ) : null
              ) : (
                <NavItemExternal
                  href={item.external}
                  key={item.id}
                  target="_blank"
                  isMenuOpen={isMenuOpen}
                >
                  <div style={{ width: 30, height: 20 }}>
                    <Icon
                      icon={item.icon}
                      color="white"
                      customStyle={{
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        width: 30,
                        height: 20,
                      }}
                    />
                  </div>{' '}
                  {!isMenuOpen ? '' : item.name}
                </NavItemExternal>
              )
            )}
            {user?.me?.role !== 'admin' ? (
              capabilities?.stripe ? (
                <NavItemExternal
                  onClick={async () => {
                    const result = await getStripeLogin();

                    if (result && result.data && result.data.getStripeLogin) {
                      window.location.assign(result.data.getStripeLogin);
                    } else {
                      history.push({
                        pathname: '/stripe',
                        search: '',
                        state: {
                          email: storage.get('user').email,
                          id: storage.get('user').id,
                          role: storage.get('user').role,
                        },
                      });
                    }
                  }}
                  target="_blank"
                  key={9}
                  isMenuOpen={isMenuOpen}
                >
                  <div style={{ width: 30, height: 20 }}>
                    <Icon
                      icon="Stripe-Icon"
                      color="white"
                      customStyle={{
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        width: 30,
                        height: 20,
                      }}
                    />
                  </div>{' '}
                  {!isMenuOpen ? '' : 'Stripe'}
                </NavItemExternal>
              ) : (
                <NavItemButton
                  exact
                  onClick={() => {
                    setShowPermissionModal(true);
                  }}
                  key={9}
                  isMenuOpen={isMenuOpen}
                >
                  <div style={{ width: 30, height: 20 }}>
                    <Icon
                      icon="Stripe-Icon"
                      color="white"
                      customStyle={{
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        width: 30,
                        height: 20,
                      }}
                    />
                  </div>{' '}
                  {!isMenuOpen ? '' : 'Stripe'}
                </NavItemButton>
              )
            ) : null}

            <NavItem
              exact
              to={
                'admin' === user?.me?.role
                  ? '/admin/settings'
                  : 'track' === user?.me?.role
                  ? '/admin-track/settings/0'
                  : '/admin-employee/settings/0'
              }
              key={'username'}
              isMenuOpen={isMenuOpen}
            >
              <UserName>
                {/* Text with the initials of the names */}
                {`${user?.me?.first_name[0]}${user?.me?.last_name[0]}`}
              </UserName>
              {/* Capitalize the first letter and make the others in lowercase */}
              {isMenuOpen &&
                `${
                  user?.me?.first_name.charAt(0).toUpperCase() +
                  user?.me?.first_name.slice(1).toLowerCase()
                } ${
                  user?.me?.last_name.charAt(0).toUpperCase() +
                  user?.me?.last_name.slice(1).toLowerCase()
                }`}
            </NavItem>
          </Wrapper>
        </NavMenu>

        <div
          style={{
            width: !isMenuOpen ? 'calc(100% - 50px)' : 'calc(100% - 220px)',
            marginLeft: !isMenuOpen ? 50 : 220,
            height: '100%',
          }}
        >
          {children}
          <PermissionModal
            isVisible={showPermissionModal}
            hideModal={() => setShowPermissionModal(false)}
            link={
              'track' === user?.me?.role
                ? '/admin-track/settings/1'
                : '/admin-employee/settings/1'
            }
            history={history}
          />
        </div>
      </BackgroundContainer>
      <GlobalStyle />
    </Fragment>
  );
};

export default compose(withRouter, withApollo, GetStripeLogin)(AdminLayout);
