import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_ANNUAL_TYPE = gql`
  query GetAnnualType($id: Int) {
    getAnnualType(id: $id) {
      id
      name
      account {
        id
        business_dba
      }
      logo_id
      logo
      annual_tickets {
        id
        name
        order
        color_code
        price
        start_date
        end_date
      }
      type
      code
      code_date
      code_time
    }
  }
`;

export function useGetAnnualType(id) {
  const variables = {
    id,
  };

  return useQuery(GET_ANNUAL_TYPE, {
    variables,
    fetchPolicy: 'no-cache',
    skip: !id,
  });
}
