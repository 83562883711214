import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/Button';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { TitleContainer } from 'pages/track/Contact/style';
import { useGetAccounts } from './gql/queries/get-accounts';
import { useGetTotalOpenCredits } from './gql/queries/get-total-open-credits';
import { IssueCreditModal } from './issueCreditModal';

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 860px) {
    height: auto;
    padding: 20px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const IssueCreditsButtonWrapper = styled.div`
  margin-right: 25;
  height: 100%;
  padding-top: 20px;
  display: none;

  @media (min-width: 860px) {
    display: block;
  }
`;

const Credits = (props) => {
  const { theme } = props;
  const [searchInput, setSearchInput] = useState('');
  const [accounts, setAccounts] = useState();
  const [count, setCount] = useState();

  const [showIssueCredit, setShowIssueCredit] = useState(false);

  const result = useGetTotalOpenCredits();

  const { res } = useGetAccounts();

  const { pathname } = useLocation();
  const isOpenCredits = pathname.includes('/openCredits');
  const isRedeemedCredits = pathname.includes('/redeemedCredits');
  const isExpiredCredits = pathname.includes('/expiredCredits');

  useEffect(() => {
    if (res && res.getAccounts) {
      setAccounts(res?.getAccounts?.results);
      setCount(res?.getAccounts?.count);
    }
  }, [res]);

  const query = qs.parse(window.location.search);
  const currentPage = query['?page'] ? parseInt(query['?page']) : 1;

  const columns = [
    {
      label: 'Account',
      key: 'account',
    },
    {
      label: '# of credits',
      key: 'credits',
    },
    {
      label: 'Credit Total',
      key: 'total',
    },
  ];

  function renderRows(account) {
    const {
      id,
      business_dba,
      open_credits: { credit_total, num_credits },
      redeemed_credits: {
        credit_total: credit_total_red,
        num_credits: num_credits_red,
      },
      expired_credits: {
        credit_total: credit_total_expired,
        num_credits: num_credits_expired,
      },
    } = account;
    return {
      account: (
        <Link
          to={
            isOpenCredits
              ? `/admin/openCredits/${id}?accountName=${business_dba}`
              : isRedeemedCredits
              ? `/admin/redeemedCredits/${id}`
              : isExpiredCredits
              ? `/admin/expiredCredits/${id}`
              : `/admin/financials/${id}`
          }
          style={{
            color: theme.colors.primary,
          }}
        >
          <LineHeightText> {business_dba}</LineHeightText>
        </Link>
      ),
      credits: isRedeemedCredits
        ? num_credits_red
        : isExpiredCredits
        ? num_credits_expired
        : num_credits,
      total: isRedeemedCredits
        ? credit_total_red
        : isExpiredCredits
        ? credit_total_expired
        : credit_total,
    };
  }

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearchInput(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  return !result.data?.getTotalOpenCredits ? (
    <div />
  ) : !accounts && !count ? (
    <div />
  ) : (
    <>
      <Container>
        <ContainerHeader borderWidth={'1px'} style={{ position: 'relative' }}>
          <TitleContainer>
            <Text
              type="heading"
              color={theme.colors.secondary}
              inlineStyle={{
                whiteSpace: 'nowrap',
                display: 'inline-block',
                marginRight: 25,
              }}
            >
              Credits
            </Text>
            <SearchInput
              placeholder={'Search Credits'}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyPress}
              value={searchInput}
            />
          </TitleContainer>
          <ButtonContainer>
            <IssueCreditsButtonWrapper
              style={{
                marginRight: 25,
                height: '100%',
                paddingTop: 20,
              }}
            >
              <Button onClick={() => setShowIssueCredit(true)}>
                Issue Credit
              </Button>
            </IssueCreditsButtonWrapper>
          </ButtonContainer>
        </ContainerHeader>
        {isOpenCredits && (
          <ContainerHeader>
            <TitleContainer>
              <Text
                type="heading"
                as="h1"
                color={theme.colors.text.header}
                inlineStyle={{ marginRight: 25, whiteSpace: 'nowrap' }}
              >
                Open Credits
              </Text>
              <Text type="heading" as="h1" color={theme.colors.text.header}>
                {result.data?.getTotalOpenCredits}
              </Text>
            </TitleContainer>
          </ContainerHeader>
        )}
        <div style={{ padding: 20 }}>
          <Table columns={columns} items={accounts} renderRows={renderRows} />
        </div>

        <Pagination count={count} perPage={15} currentPage={currentPage || 1} />
      </Container>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={false}
      />
    </>
  );
};

export default withTheme(Credits);
