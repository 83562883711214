import { Formik } from 'formik';
import React, { useState } from 'react';
import { Row } from 'react-grid-system';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { Checkbox } from 'components/Form/Checkbox';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { TitleContainer } from 'pages/track/Contact/style';
import { useGetAccount } from '../gql/queries/useGetAccount';
import { AddMemberForm } from './AddMember/AddMemberForm';
import { AddReaderForm } from './AddReader/AddReaderForm';
import AccountDetailForm from './components/AccountDetailForm';
import AddressColumn, { SectionHeader } from './components/AddressColumn';
import { PointOfSaleSettings } from './components/PointOfSaleSettings';
import Readers from './components/Readers';
import TeamMembers from './components/TeamMembers';
import CreateAccount from './gql/CreateAccount';
import UpdateAccount from './gql/UpdateAccount';

export const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(250px, 1fr)
  ); // Adjust the 200px based on your needs
  grid-gap: 20px; // Spacing between the grid items
  border-bottom-width: 2px;
  border-color: #e6e6e6;
  border-style: solid;
  padding: 30px 10px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr); // Max 3 columns on larger screens
  }
`;

const Wrapper = styled.div`
  width: 90%;
  margin: auto;
`;

const AddressWrapper = styled.div`
  border-bottom-width: 2px;
  border-color: #e6e6e6;
  border-style: solid;
  padding: 30px 10px;
`;

const formValues = [
  'business_dba',
  'primary_contact',
  'series_ids',
  'legal_name',
  'email',
  'track_id',
  'track_ids',
  'currency',
  'phone',
  'country_code',
  'calling_code',
  'title',
  'track',
  'mailing',
  'shipping',
  'stripe_account_id',
  'stripe_url',
  'members',
  'logo_id',
  'logo',
  'janam_scanners',
  'pos',
  'pos_settings',
  'readers',
  'pos_fee_dollar',
  'pos_fee_percent',
];

function getInitialValues(account, keys) {
  const blankValues = {
    ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
  };

  const addresses = ['track', 'mailing', 'shipping'];
  addresses.forEach((address) => {
    blankValues[`${address}`] = {
      name: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zipcode: '',
      type: `${address}`,
    };
  });

  const initialValues = !account
    ? blankValues
    : {
        ...keys.reduce((formValues, key) => {
          return account[key]
            ? {
                ...formValues,
                [key]: account[key],
              }
            : formValues;
        }, {}),
        phone: account.phone.includes('+')
          ? account.phone
          : `+${account.calling_code ? account.calling_code : '1'}${
              account.phone
            }`,
        series_ids: Array.isArray(account.series)
          ? account.series.map((series) => ({
              value: series.id,
              label: series.name,
            }))
          : [],
        track_id: Array.isArray(account.tracks)
          ? account.tracks.map((track) => ({
              value: track.id,
              label: track.name,
            }))
          : [],
        track: {
          ...account?.track,
          type: 'track',
        },
        mailing: {
          ...account?.mailing,
          type: 'mailing',
        },
        shipping: {
          ...account?.shipping,
          type: 'shipping',
        },
      };

  return initialValues;
}

function getAccountInput({ data, currentAccount, action }) {
  const input = {
    ...data,
    ...(action === 'update' && currentAccount
      ? { id: parseInt(currentAccount) }
      : null),
    calling_code: data.calling_code ? data.calling_code : '1',
    phone: data.country_code
      ? data.phone.replace(`+${data.calling_code}`, '')
      : data.phone.replace(`+1`, ''),
    series_ids: data.series_ids
      ? data.series_ids.map((item) => item.value)
      : [],
    track_ids: data.track_id ? data.track_id.map((item) => item.value) : [],
    pos_fee_dollar: data.pos_fee_dollar ? +data.pos_fee_dollar : null,
    pos_fee_percent: data.pos_fee_percent ? +data.pos_fee_percent : null,
  };
  return input;
}

const AddAccounts = ({ createAccount, updateAccount, history }) => {
  const theme = useTheme();
  const params = useParams();

  const currentAccount = params.id;

  const [showAddMember, setShowAddMember] = useState(false);

  const [showAddReader, setShowAddReader] = useState(false);

  const { data } = useGetAccount(parseInt(currentAccount));

  const successMessage = () =>
    toast.success(
      currentAccount
        ? 'Account Updated Successfully'
        : 'Account Created Successfully'
    );
  const errorMessage = (response, message) =>
    toast.error(
      message
        ? message
        : response?.global
        ? currentAccount
          ? 'Error Updating Account'
          : 'Error Creating Account'
        : "There were errors with your submission check the form's field for errors."
    );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={getInitialValues(data?.getAccount, formValues)}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values) => {
          const errors = {};

          if (!values.currency) {
            errors.currency = 'Required';
            errorMessage({}, 'Currency value required');
          }

          const addresses = ['track', 'mailing', 'shipping'];
          if (!currentAccount) {
            addresses.every((address) => {
              if (!values[`${address}`].name) {
                errors[`${address}.name`] = 'Required';
                errorMessage();
                return false;
              } else if (!values[`${address}`].address_line_1) {
                errors[`${address}.address_line_1`] = 'Required';
                errorMessage();
                return false;
              } else if (!values[`${address}`].city) {
                errors[`${address}.city`] = 'Required';
                errorMessage();
                return false;
              } else if (!values[`${address}`].state) {
                errors[`${address}.state`] = 'Required';
                errorMessage();
                return false;
              } else if (!values[`${address}`].zipcode) {
                errors[`${address}.zipcode`] = 'Required';
                errorMessage();
                return false;
              }
              return true;
            });
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          let response;
          setSubmitting(true);

          const data = getAccountInput({
            data: values,
            currentAccount,
            action: currentAccount ? 'update' : 'create',
          });

          formValues.forEach((key) => {
            if (data[key] === '' || data[key] === undefined) delete data[key];
          });

          if (currentAccount) {
            const addresses = ['track', 'mailing', 'shipping'];
            addresses.forEach(
              (address) => delete data[`${address}`]['__typename']
            );
          }
          delete data['track_id'];
          delete data['stripe_url'];
          delete data['members'];
          delete data['logo'];
          delete data['readers'];

          if (data['pos_settings']) {
            delete data[`pos_settings`]['__typename'];
          }

          if (currentAccount) {
            response = await updateAccount(data);
          } else {
            response = await createAccount(data);
          }

          if (!response || response.errors) {
            errorMessage(response);
            setSubmitting(false);
            return setErrors(response.errors);
          } else {
            successMessage();
            setSubmitting(false);
            resetForm();
            history.push('/admin/accounts');
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Container>
            <form onSubmit={handleSubmit}>
              <ContainerHeader>
                <TitleContainer>
                  <Text
                    type="heading"
                    as="h1"
                    color={theme.colors.text.header}
                    inlineStyle={{ marginRight: 25, whiteSpace: 'nowrap' }}
                  >
                    Account Details
                  </Text>
                </TitleContainer>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  block
                  buttonStyle={{ width: '15vw', marginRight: 15 }}
                >
                  {!currentAccount ? 'Add Account' : 'Update'}
                </Button>
              </ContainerHeader>
              <Wrapper>
                <AccountDetailForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />

                <AddressWrapper>
                  <Row>
                    <AddressColumn
                      title={'Physical Address'}
                      addressName={'track'}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                    />
                    <AddressColumn
                      title={'Mailing Address'}
                      addressName={'mailing'}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                    />
                    <AddressColumn
                      title={'Shipping Address'}
                      addressName={'shipping'}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                    />
                  </Row>
                </AddressWrapper>
              </Wrapper>

              <Wrapper>
                <AddressWrapper>
                  <Row style={{ justifyContent: 'space-between', margin: 0 }}>
                    <SectionHeader style={{ marginTop: 10 }}>
                      Staff
                    </SectionHeader>
                    <Button
                      buttonStyle={{ width: 180 }}
                      onClick={() => setShowAddMember(true)}
                      type="button"
                    >
                      Add Staff{' '}
                    </Button>
                  </Row>
                  <Spacer size={20} />
                  {values.members ? (
                    values.members.map((team, index) => (
                      <TeamMembers
                        team={team}
                        key={index}
                        members={values.members}
                        account_id={currentAccount}
                        setFieldValue={setFieldValue}
                        history={history}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </AddressWrapper>
              </Wrapper>

              <Wrapper>
                <SectionWrapper
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Checkbox
                    name="janam_scanners"
                    checked={values.janam_scanners}
                    onChange={(event) => {
                      const value = event.target?.checked;
                      setFieldValue('janam_scanners', value);
                    }}
                    size={[36, 20, 3]}
                    rightText={'Uses Janam Scanners'}
                  />

                  <Spacer size={10} />

                  <Checkbox
                    name="pos"
                    checked={values.pos}
                    onChange={(event) => {
                      const value = event.target?.checked;
                      setFieldValue('pos', value);
                    }}
                    size={[36, 20, 3]}
                    rightText={'Uses Point of Sale'}
                  />

                  {values.pos && (
                    <PointOfSaleSettings
                      handleChange={(target) => {
                        const { name, value } = target.target;

                        const newSettings = {
                          ...values.pos_settings,
                          [name]: value,
                        };

                        typeof name === 'string' &&
                          handleChange({
                            target: {
                              name: 'pos_settings',
                              value: newSettings,
                            },
                          });
                      }}
                      handleMainChange={handleChange}
                      mainValues={values}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                </SectionWrapper>
              </Wrapper>

              <Wrapper>
                <div style={{ paddingTop: 30 }}>
                  <Row style={{ justifyContent: 'space-between', margin: 0 }}>
                    <SectionHeader style={{ marginTop: 10 }}>
                      Readers
                    </SectionHeader>
                    <Button
                      buttonStyle={{ width: 180 }}
                      onClick={() => setShowAddReader(true)}
                      type="button"
                    >
                      Add Reader{' '}
                    </Button>
                  </Row>
                  <Spacer size={20} />
                  {values.readers ? (
                    values.readers.map((reader, index) => (
                      <Readers
                        reader={reader}
                        key={index}
                        readers={values.readers}
                        setFieldValue={setFieldValue}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </div>

                <Spacer size={40} />
              </Wrapper>
            </form>

            <AddMemberForm
              isVisible={showAddMember}
              handleOutClick={() => setShowAddMember(false)}
              account_id={currentAccount}
              members={values.members}
              setFieldValue={setFieldValue}
              history={history}
            />

            <AddReaderForm
              isVisible={showAddReader}
              handleOutClick={() => setShowAddReader(false)}
              account_id={currentAccount}
              readers={values.readers}
              setFieldValue={setFieldValue}
            />
          </Container>
        )}
      </Formik>
    </>
  );
};

export default compose(CreateAccount, UpdateAccount)(AddAccounts);
