import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/Button';
import { ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import { HelpModals } from 'components/HelpModals';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { IssueCreditModal } from 'pages/app/Credits/issueCreditModal';
import ExportOpenCreditsReport from 'pages/app/Credits/OpenCredits/ExportOpenCredits/ExportOpenCreditsReport';
import { PrintOpenCreditsReport } from 'pages/app/Credits/OpenCredits/ExportOpenCredits/PrintOpenCreditsReport';
import { TitleContainer } from 'pages/track/Contact/style';
import { useGetTrackOpenCredits } from './gql/get-track-open-credits';
import { useGetTrackTotalOpenCredits } from './gql/get-track-total-open-credits';

const CreditButton = styled.div`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`;

const FilterContainer = styled.div`
  min-width: 150px;
`;

const TrackOpenCredit = withEventYearFilterContext((props) => {
  const { theme } = props;

  const [users, setUsers] = useState();
  const [count, setCount] = useState();
  const [search, setSearch] = useState('');

  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';
  const [showIssueCredit, setShowIssueCredit] = useState(false);

  const [year, setYear] = useState();

  const result = useGetTrackTotalOpenCredits();

  const { res } = useGetTrackOpenCredits();

  useEffect(() => {
    if (res && res.getTrackOpenCredits) {
      setUsers(res?.getTrackOpenCredits?.results);
      setCount(res?.getTrackOpenCredits?.count);
    }
  }, [res]);

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  function renderRows(user) {
    const { id, formatted_name } = user;
    return {
      name: (
        <Link
          to={`/admin-${userType}/openCredits/creditDetail/${id}`}
          style={{
            color: theme.colors.primary,
          }}
        >
          <LineHeightText> {formatted_name}</LineHeightText>
        </Link>
      ),
    };
  }

  return !result.data?.getTrackTotalOpenCredits ? (
    <div />
  ) : !users ? null : (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'Row',
          justifyContent: 'end',
          marginTop: 20,
          marginRight: 40,
        }}
      >
        <ExportOpenCreditsReport
          headerText={''}
          year={year}
          totalCredit={result.data.getTrackTotalOpenCredits}
          isAccountReport={true}
        />

        <PrintOpenCreditsReport
          headerText={''}
          year={year}
          isAccountReport={true}
          totalCredit={result.data.getTrackTotalOpenCredits}
        />

        <HelpModals />
      </div>
      <Container>
        <ContainerHeader>
          <ContainerWrapper>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginTop: 10 }}
              >
                Open Credits
              </Text>
              <FilterContainer>
                <YearDropdown showLabel={false} setYear={setYear} />
              </FilterContainer>
              <div style={{ marginLeft: 15 }}>
                <SearchInput
                  placeholder="Search Credits"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                  style={{ width: 150, marginRight: 15 }}
                />
              </div>
            </TitleContainer>
            <CreditButton>
              <Button
                // eslint-disable-next-line no-console
                onClick={() => setShowIssueCredit(true)}
                buttonStyle={{ height: 35 }}
              >
                {'Issue Credit'}
              </Button>
            </CreditButton>
          </ContainerWrapper>
        </ContainerHeader>
        <ContainerHeader>
          <TitleContainer>
            <Text
              type="heading"
              as="h1"
              color={theme.colors.text.header}
              inlineStyle={{ marginRight: 25, whiteSpace: 'nowrap' }}
            >
              Open Credits
            </Text>
            <Text type="heading" as="h1" color={theme.colors.text.header}>
              {result.data?.getTrackTotalOpenCredits}
            </Text>
          </TitleContainer>
        </ContainerHeader>
        <div style={{ padding: 20 }}>
          <Table items={users} columns={columns} renderRows={renderRows} />
          <Pagination
            count={count}
            perPage={15}
            currentPage={currentPage || 1}
          />
        </div>
      </Container>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={true}
      />
    </>
  );
});

export default withTheme(TrackOpenCredit);
