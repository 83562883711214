import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Icon from 'components/Icon';
import { logDevError } from 'shared/alerts';
import { timeout } from 'shared/timeout';

const Container = styled.button`
  background-color: ${(props) =>
    props.variant === 'primary' ? props.theme.colors.primary : 'transparent'};
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  padding: ${(props) => (props.variant === 'primary' ? '15px' : 0)};

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media (min-width: 860px) {
    background-color: transparent;
    padding: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${(props) =>
    props.variant === 'primary' || props.color === 'white'
      ? props.theme.colors.primary
      : 'transparent'};
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  width: calc(100% - 20px);
  margin: 0;
  min-width: 110px;

  span {
    color: ${(props) => props.theme.colors.white};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin-left: ${(props) => (props.variant === 'minimal' ? '10px' : '20px')};
    text-align: left;
  }

  svg {
    fill: ${(props) =>
      props.variant === 'minimal'
        ? props.theme.colors.primary
        : props.theme.colors.white};
    width: ${(props) => (props.variant === 'minimal' ? '22px' : '40px')};
  }

  @media (min-width: 860px) {
    flex-direction: column;
    padding: 0;
    border: 0;
    width: auto;
    background-color: transparent;
    cursor: pointer;
    min-width: 40px;
    width: auto;

    svg {
      fill: ${(props) => props.theme.colors.primary};
      width: 40px;
    }

    span {
      color: ${(props) => props.theme.colors.text.header};
      font-size: 12px;
      margin-top: 10px;
      margin-left: 0px;
      text-align: center;
    }
  }
`;

export const PrintButton = (props) => {
  const {
    buildPdf,
    filename,
    generateLapSponsorshipReport,
    style = {},
    disabled: _disabled,
    variant = 'primary',
    color,
    printReceipt,
    button,
  } = props;
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(_disabled);
  }, [_disabled, filename]);

  function exportPdf(buildPdf) {
    return new Promise(async (resolve) => {
      const pdf = await buildPdf();
      if (pdf.fileName || filename) {
        pdf.setProperties({
          title: pdf.fileName ?? filename,
        });
      }
      if (
        navigator.userAgent.includes('iPhone') &&
        navigator.maxTouchPoints > 1
      ) {
        var blob = new Blob([pdf.output('blob')], {
          type: 'data:application/pdf,',
        });
        FileSaver.saveAs(blob, pdf.fileName ?? filename + '.pdf');
      } else if (navigator.maxTouchPoints > 1) {
        pdf.output('datauri', pdf.fileName ?? filename + '.pdf');
      } else {
        // Generate the PDF Blob
        const blob = pdf.output('blob');

        // Create a Blob URL for the PDF
        const blobUrl = URL.createObjectURL(blob);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = pdf.fileName ?? filename + '.pdf'; // Set the desired filename

        // Trigger a click event on the download link
        downloadLink.click();
      }
      timeout(resolve, 6000);
    });
  }

  const onClick = async () => {
    if (printReceipt) {
      printReceipt();
    } else if (generateLapSponsorshipReport) {
      if ('tickethoss' === process.env.REACT_APP_PLATFORM)
        generateLapSponsorshipReport();
      else {
        setDisabled(true);
        try {
          await buildPdf();
        } catch (error) {
          logDevError(error);
          toast.error('Cannot print list');
        }
        setDisabled(false);
      }
    } else {
      setDisabled(true);
      try {
        await exportPdf(buildPdf);
      } catch (error) {
        logDevError(error);
        toast.error('Cannot download participants');
      }
      setDisabled(false);
    }
  };

  return button ? (
    <div onClick={onClick} role="presentation">
      {button}
    </div>
  ) : (
    <Container
      style={style}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
    >
      <IconWrapper variant={variant} color={color}>
        <Icon icon="PDF-Icon" color={color ? color : '#fa4616'} />
      </IconWrapper>
    </Container>
  );
};
PrintButton.propTypes = {
  buildPdf: PropTypes.func.isRequired,
  filename: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'minimal']),
};
