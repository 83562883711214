import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ExportExcelButton } from 'components/ExportExcelButton/ExportExcelButton';
import { useGetAnnualPurchaseReport } from './gql/useGetAnnualPurchaseReport.mutation';

const Container = styled.button`
  border-radius: 5px;
  background-color: #fa4616;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;

  &:focus {
    outline: none;
  }

  @media (min-width: 860px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 10px 0 10px;
    background-color: transparent;
    cursor: pointer;
    width: auto;
  }
`;

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const exportCsv = async (
  getAnnualPurchaseReport,
  annualTicketName,
  pathname,
  ticket_id,
  accountName
) => {
  try {
    const userType = pathname?.split('/').includes('admin-employee')
      ? 'employee'
      : pathname?.split('/').includes('admin-track')
      ? 'admin-track'
      : 'admin';
    const isStaging = pathname.includes('-staging');

    const response = await getAnnualPurchaseReport();

    if (!response || response.errors) {
      toast.error('Annual Purchase List can not be retrieved');
      return;
    }

    if (response.data.getAnnualPurchaseListV2) {
      const transactions = response.data.getAnnualPurchaseListV2;

      let _title = '',
        filename = '';

      _title = moment().format('MMM DD - YYYY ').toUpperCase();

      filename = _title + annualTicketName + '- Annual Purchase List';

      const dateTimeStamp = moment().format('MMM DD - YYYY h:mm A');

      const headers = `Purchaser Last Name,Purchaser First Name,Order Total,Discount,Refund,Credit,Absorbed Fees,Transaction Total,Time/Date,Transaction Link${'\n'}`;

      const transactionRows = transactions.data.map((data) =>
        data.data
          .map((transaction) => {
            const {
              id,
              purchaser,
              credit,
              discount,
              total,
              refund,
              subtotal,
              fee,
              purchase_date,
            } = transaction;

            const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
              process.env.REACT_APP_PLATFORM
            }.com/${userType}/annualPurchases/purchase/${ticket_id}?ticketName=${annualTicketName}&purchase_id=${id}`;

            return `"${purchaser?.split(',')[0]}","${
              purchaser?.split(',')[1]
            }","${subtotal}","${discount}","${refund}","${credit}","${fee}","${total}","${purchase_date}","${linkUrl}"`;
          })
          .join('\n')
      );

      const csv = `Annual Purchase List
      Exported:, ${dateTimeStamp}
      Ticket Name:, ${annualTicketName}
      Account Name:, ${accountName}
      ${
        transactionRows[1]
          ? `Credits Redeemed - ${transactions.data[1].total}
      ${headers}${transactionRows[1]} \n`
          : `Credits Redeemed - ${transactions.data[1].total} \n`
      }
      ${
        transactionRows[2]
          ? `Credits Issued - ${transactions.data[2].total}
      ${headers}${transactionRows[2]} \n`
          : `Credits Issued - ${transactions.data[2].total} \n`
      }
      ${
        transactionRows[4]
          ? `Refunds Issued - ${transactions.data[4].total}
      ${headers}${transactionRows[4]} \n`
          : `Refunds Issued - ${transactions.data[4].total} \n`
      }
      ${
        transactionRows[3]
          ? `Discounts - ${transactions.data[3].total}
      ${headers}${transactionRows[3]} \n`
          : `Discounts - ${transactions.data[3].total} \n`
      }
      ${
        transactionRows[0]
          ? `All Transactions - ${transactions.data[0].total}
      ${headers}${transactionRows[0]},`
          : `All Transactions - ${transactions.data[0].total}`
      }
    `;

      download(`${filename}.csv`, csv.trim());
    } else {
      toast.error('Annual Purchase List can not be retrieved');
    }
  } catch (e) {}
};

const ExportAnnualPurchase = ({
  annualTicketName,
  ticket_id,
  accountName,
  account_id,
}) => {
  const getAnnualPurchaseReport = useGetAnnualPurchaseReport(account_id);

  const { pathname } = useLocation();

  return (
    <div>
      <Container
        onClick={() =>
          exportCsv(
            getAnnualPurchaseReport,
            annualTicketName,
            pathname,
            ticket_id,
            accountName
          )
        }
      >
        <ExportExcelButton />
      </Container>
    </div>
  );
};

export default ExportAnnualPurchase;
