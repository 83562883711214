import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { useEventYearFilter } from 'components/YearDropdown';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { useSearchInput } from 'hooks/useSearchInput';
import { AppContext } from 'shared/AppContext';

export const GET_TRACK_EXPIRED_CREDITS = gql`
  query GetTrackExpiredCredits($creditsInput: GetTrackCreditsInput!) {
    getTrackExpiredCredits(input: $creditsInput) {
      count
      results {
        id
        formatted_name
      }
    }
  }
`;

export function useGetTrackExpiredCredits() {
  const { yearFilter } = useEventYearFilter();
  const { input: search } = useSearchInput();
  const {
    state: { accountId },
  } = useContext(AppContext);

  const result = usePersistedQuery(
    GET_TRACK_EXPIRED_CREDITS,
    `track-expired-credits-${accountId}`,
    {
      variables: {
        creditsInput: {
          ...(yearFilter ? { year: yearFilter } : null),
          ...search,
          ...{ account_id: accountId },
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  return { ...result, resExpired: result.data };
}
