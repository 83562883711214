import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled, { useTheme } from 'styled-components';
import { HelpModals } from 'components/HelpModals';
import LineHeightText from 'components/LineHeightText';
import { SearchableListContainer } from 'components/SearchableListContainer';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import { TransactionModal } from 'components/Transactions';
import { useGetTrackTransaction } from 'components/Transactions/gql/useGetPurchase';
import {
  TransactionDateFilter,
  withTransactionDateFilterContext,
} from 'components/Transactions/TransactionDateFilter';
import { formatTimestamp } from 'shared/formatters';
import ExportAnnualPurchase from './ExportAnnualPurchase';
import { useGetAnnualPurchaseList } from './gql/useGetAnnualPurchaseList';
import { PrintAnnualPurchaseReport } from './PrintAnnualPurchaseReport';

const OpenModal = styled.button`
  color: #fa4616;
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-family: 'Roboto';
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const PurchaseList = withTransactionDateFilterContext(({ location, match }) => {
  const { data } = useGetAnnualPurchaseList();
  const theme = useTheme();

  const queryString = location.search;
  const searchParams = new URLSearchParams(queryString);
  const name = searchParams.get('ticketName');
  const accountName = searchParams.get('accountName');
  const purchase_id = searchParams.get('purchase_id');
  const account_id = searchParams.get('account_id');

  const [currentTransaction, setCurrentTransaction] = useState(null);

  const [fetch, setFetch] = useState(false);
  const { data: purchase } = useGetTrackTransaction(purchase_id, fetch);

  useEffect(() => {
    if (purchase_id && data?.getAnnualPurchaseList?.results) {
      const transaction = purchase
        ? purchase.getTrackTransaction
        : data?.getTrackTransactions?.results.find(
            (transaction) => transaction.id === Number(purchase_id)
          );

      if (transaction) setCurrentTransaction(transaction);
      else {
        setFetch(true);
      }
    }
  }, [data, purchase_id, purchase]);

  const { id } = useParams();

  if (!data?.getAnnualPurchaseList) return null;

  return (
    <>
      {data.getAnnualPurchaseList.count > 0 ? (
        <div
          style={{
            marginTop: 35,
            marginLeft: '80%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <ExportAnnualPurchase
            annualTicketName={name}
            accountName={accountName}
            ticket_id={id}
            account_id={account_id}
          />

          <PrintAnnualPurchaseReport
            ticket_id={id}
            annualTicketName={name}
            accountName={accountName}
            account_id={account_id}
          />

          <HelpModals />
        </div>
      ) : null}
      <SearchableListContainer
        pageCount={data.getAnnualPurchaseList.count}
        paginated
        searchInputPlaceholder="Search Purchases"
        searchCriteria="Supports search by Last Name only"
        inputMaxWidth="200px"
        title={name}
        titleBarContent={<TransactionDateFilter />}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col">Purchase Date</TableCell>
              <TableCell scope="col">Name</TableCell>
              <TableCell scope="col">Cost</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!data.getAnnualPurchaseList.results &&
            data.variables.getAnnualPurchaseList.queryString ? (
              <TableRow>
                <p
                  style={{
                    color: '#000',
                    fontSize: 20,
                    fontFamily: 'Barlow Condensed',
                    fontWeight: 600,
                    paddingTop: 20,
                  }}
                >
                  No Purchase with this name
                </p>
              </TableRow>
            ) : !data.getAnnualPurchaseList.results.length ? (
              <TableRow>
                <p
                  style={{
                    color: '#000',
                    fontSize: 20,
                    fontFamily: 'Barlow Condensed',
                    fontWeight: 600,
                    paddingTop: 20,
                  }}
                >
                  No Purchases for this ticket yet
                </p>
              </TableRow>
            ) : null}
            {data.getAnnualPurchaseList.results.map((purchase) => (
              <TableRow key={0}>
                <TableCell>
                  {formatTimestamp(
                    Number(purchase.purchase_date),
                    'MMM DD - YYYY h:mm A',
                    true
                  ).toUpperCase()}
                </TableCell>
                <TableCell>
                  <LineHeightText>
                    <Link
                      to={`/admin/users/transactions/${purchase.user.id}`}
                      style={{
                        color: theme.colors.primary,
                      }}
                    >
                      <LineHeightText>
                        {' '}
                        {purchase.user ? `${purchase.user.formatted_name}` : ''}
                      </LineHeightText>
                    </Link>
                  </LineHeightText>
                </TableCell>
                <TableCell>
                  <LineHeightText>
                    <OpenModal onClick={() => setCurrentTransaction(purchase)}>
                      {purchase.total}
                    </OpenModal>
                  </LineHeightText>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SearchableListContainer>
      {currentTransaction ? (
        <TransactionModal
          admin={location.pathname.includes('/admin/')}
          match={match}
          currentTransaction={currentTransaction}
          close={() => {
            setCurrentTransaction(null);
          }}
        />
      ) : null}
    </>
  );
});

export default PurchaseList;
