import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useEventYearFilter } from 'components/YearDropdown';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { useSearchInput } from 'hooks/useSearchInput';

export const GET_ACCOUNT_EXPIRED_CREDITS = gql`
  query GetAccountExpiredCredits($creditsInput: GetAccountCreditsInput!) {
    getAccountExpiredCredits(input: $creditsInput) {
      count
      results {
        id
        formatted_name
      }
    }
  }
`;

export function useGetAccountExpiredCredits() {
  const { id: account_id } = useParams();
  const { yearFilter } = useEventYearFilter();
  const { input: search } = useSearchInput();

  const result = usePersistedQuery(
    GET_ACCOUNT_EXPIRED_CREDITS,
    `account-expired-credits-${account_id}`,
    {
      variables: {
        creditsInput: {
          account_id: Number(account_id),
          ...(yearFilter ? { year: yearFilter } : null),
          ...search,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  return { ...result, resExpired: result.data };
}
