import React, { useEffect, useState } from 'react';
import { Col } from 'react-grid-system';
import { useMediaQuery } from 'react-responsive';
import { ReactSortable } from 'react-sortablejs';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Form/Checkbox';
import {
  DraggableRow,
  getHighestItemOrderNumber,
  getOrderedItems,
} from 'components/Form/DraggableFields';
import { Input } from 'components/Form/Input';
import { Label, LabelWrapper } from 'components/Form/styles';
import Icon from 'components/Icon';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import { ResponsiveCol, ResponsiveRow } from '../AddEvents/styles';
import { InputWrapper, OrderText } from '../EventEdit/EditEventTickets';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  & > div {
    // Fix for bad design of icomoon-react wrapper
    line-height: 14px;
  }
`;

export const LapSponsorshipEvent = ({
  onChange,
  handleBlur,
  errors,
  touched,
  lapSponsorships,
  onChangeSponsorship,
  values,
  handleSort,
}) => {
  const lapsWithOrder = getOrderedItems(
    values['lap_sponsorships_with_order'] || []
  );

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Col>
      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 60%' : '0 0 100%' }}>
          {lapSponsorships
            ? lapSponsorships.map((lapSponsorship, index) => (
                <LapSponsorship
                  onChange={onChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  lapSponsorships={lapSponsorships}
                  onChangeSponsorship={onChangeSponsorship}
                  index={index}
                  lapSponsorship={lapSponsorship}
                  key={index}
                />
              ))
            : null}

          <ResponsiveRow>
            <ResponsiveCol>
              <Button
                type="button"
                onClick={() => {
                  onChangeSponsorship('lap_sponsorships', [
                    ...lapSponsorships,
                    {
                      name: '',
                      order: getHighestItemOrderNumber(lapSponsorships) + 1,
                    },
                  ]);
                  onChangeSponsorship('lap_sponsorships_with_order', [
                    ...values['lap_sponsorships_with_order'],
                    {
                      name: '',
                      order: getHighestItemOrderNumber(lapSponsorships) + 1,
                    },
                  ]);
                }}
                buttonStyle={{ width: !isMobile ? '20vw' : '80vw' }}
              >
                {lapSponsorships.length > 0
                  ? 'ADD ANOTHER LAP SPONSORSHIP'
                  : 'ADD LAP SPONSORSHIP'}
              </Button>
            </ResponsiveCol>
          </ResponsiveRow>
        </ResponsiveCol>

        <ResponsiveCol
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginLeft: !isMobile ? '60px' : 0,
            flex: !isMobile ? '0 0 30%' : '0 0 100%',
          }}
        >
          {lapsWithOrder?.length > 0 && (
            <ResponsiveCol>
              <LabelWrapper style={{ marginLeft: -20 }}>
                <Label>{'Lap Sponsorship Order In App'}</Label>
              </LabelWrapper>
              <ReactSortable
                list={lapSponsorships}
                setList={(values) =>
                  handleSort('lap_sponsorships_with_order', values)
                }
              >
                {lapsWithOrder.map((sponsorship) => (
                  <DraggableRow key={sponsorship.order}>
                    <InputWrapper style={{ width: '100%' }}>
                      <OrderText>{sponsorship.name}</OrderText>
                    </InputWrapper>
                  </DraggableRow>
                ))}
              </ReactSortable>
            </ResponsiveCol>
          )}
        </ResponsiveCol>
      </ResponsiveRow>

      <Spacer size={30} />
    </Col>
  );
};

const LapSponsorship = ({
  onChange,
  handleBlur,
  errors,
  touched,
  lapSponsorships,
  lapSponsorship,
  onChangeSponsorship,
  index,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [payoutCheck1, setPayoutCheck1] = useState(false);
  const [payoutCheck2, setPayoutCheck2] = useState(false);
  const [payoutCheck3, setPayoutCheck3] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (lapSponsorship.payout_1) setPayoutCheck1(true);
    if (lapSponsorship.payout_2) setPayoutCheck2(true);
    if (lapSponsorship.payout_3) setPayoutCheck3(true);
  }, [
    lapSponsorship.payout_1,
    lapSponsorship.payout_2,
    lapSponsorship.payout_3,
  ]);
  return (
    <>
      <ButtonWrapper>
        <div style={{ marginLeft: 10 }}>
          <Icon
            icon="trash"
            size={35}
            color={theme.colors.primary}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          />
        </div>
      </ButtonWrapper>
      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 50%' : '0 0 100%' }}>
          <Input
            id={`lap_sponsorships.${index}.name`}
            name={`lap_sponsorships.${index}.name`}
            label="Race Name"
            placeholder=""
            onChange={({ target }) => {
              onChange({
                target: {
                  name: 'name',
                  value: target.value,
                },
                order: lapSponsorship.order,
              });
              errors[`lap_sponsorships.${index}.name`] = '';
            }}
            onBlur={handleBlur}
            value={lapSponsorship.name ?? ''}
            error={
              errors[`lap_sponsorships.${index}.name`]
                ? errors[`lap_sponsorships.${index}.name`]
                : ''
            }
          />
        </ResponsiveCol>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 25%' : '0 0 100%' }}>
          <Input
            id={`lap_sponsorships.${index}.num_laps`}
            name={`lap_sponsorships.${index}.num_laps`}
            label="Total Laps"
            type="number"
            placeholder=""
            onChange={({ target }) => {
              onChange({
                target: {
                  name: 'num_laps',
                  value: parseInt(target.value),
                },
                order: lapSponsorship.order,
              });
              errors[`lap_sponsorships.${index}.num_laps`] = '';
            }}
            onBlur={handleBlur}
            value={lapSponsorship.num_laps}
            error={
              errors[`lap_sponsorships.${index}.num_laps`]
                ? errors[`lap_sponsorships.${index}.num_laps`]
                : ''
            }
          />
        </ResponsiveCol>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 25%' : '0 0 100%' }}>
          <Input
            id={`lap_sponsorships.${index}.price`}
            name={`lap_sponsorships.${index}.price`}
            label="Price"
            type="number"
            placeholder=""
            onChange={({ target }) => {
              onChange({
                target: {
                  name: 'price',
                  value: target.value ? parseFloat(target.value) : '',
                },
                order: lapSponsorship.order,
              });

              errors[`lap_sponsorships.${index}.price`] = '';
            }}
            onBlur={handleBlur}
            value={lapSponsorship.price ?? ''}
            error={
              errors[`lap_sponsorships.${index}.price`]
                ? errors[`lap_sponsorships.${index}.price`]
                : ''
            }
          />
        </ResponsiveCol>
      </ResponsiveRow>

      <Spacer size={38} />
      <ResponsiveRow>
        <p style={{ fontSize: '16px', fontWeight: 500 }}>Breakdown</p>
      </ResponsiveRow>
      <Spacer size={15} />

      <ResponsiveCol>
        <ResponsiveRow>
          <ResponsiveCol style={{ flex: '0 0 10%', paddingTop: 25 }}>
            <Checkbox
              name="first"
              checked={payoutCheck1}
              onChange={(event) => {
                const value = event.target?.checked;
                setPayoutCheck1(value);
                onChange({
                  target: {
                    name: 'payout_1',
                    value: null,
                  },
                  order: lapSponsorship.order,
                });
              }}
              onBlur={handleBlur}
              error={errors.first && touched.first && errors.first}
              rightText="1st"
            />
          </ResponsiveCol>

          {payoutCheck1 && (
            <>
              <ResponsiveCol>
                <Input
                  id={`lap_sponsorships.${index}.payout_1`}
                  name={`lap_sponsorships.${index}.payout_1`}
                  label="Payout"
                  placeholder=""
                  onChange={({ target }) =>
                    onChange({
                      target: {
                        name: 'payout_1',
                        value: target.value ? parseFloat(target.value) : '',
                      },
                      order: lapSponsorship.order,
                    })
                  }
                  onBlur={handleBlur}
                  value={lapSponsorship.payout_1 ?? ''}
                  error={errors.payout_1}
                  disabled={!payoutCheck1}
                  type="number"
                />
              </ResponsiveCol>

              <ResponsiveCol>
                <Input
                  id={`lap_sponsorships.${index}.payout_1_label`}
                  name={`lap_sponsorships.${index}.payout_1_label`}
                  label="Name"
                  placeholder=""
                  onChange={({ target }) =>
                    onChange({
                      target: {
                        name: 'payout_1_label',
                        value: target.value,
                      },
                      order: lapSponsorship.order,
                    })
                  }
                  onBlur={handleBlur}
                  value={lapSponsorship.payout_1_label ?? ''}
                  error={errors.payout_1_label}
                  disabled={!payoutCheck1}
                />
              </ResponsiveCol>
            </>
          )}
        </ResponsiveRow>

        <Spacer size={10} />

        <ResponsiveRow>
          <ResponsiveCol style={{ flex: '0 0 10%', paddingTop: 25 }}>
            <Checkbox
              name="second"
              checked={payoutCheck2}
              onChange={(event) => {
                const value = event.target?.checked;
                setPayoutCheck2(value);
                if (!value)
                  onChange({
                    target: {
                      name: 'payout_2',
                      value: null,
                    },
                    order: lapSponsorship.order,
                  });
              }}
              onBlur={handleBlur}
              error={errors.second && touched.second && errors.second}
              rightText="2nd"
            />
          </ResponsiveCol>
          {payoutCheck2 && (
            <>
              <ResponsiveCol>
                <Input
                  id={`lap_sponsorships.${index}.payout_2`}
                  name={`lap_sponsorships.${index}.payout2`}
                  label="Payout"
                  placeholder=""
                  onChange={({ target }) =>
                    onChange({
                      target: {
                        name: 'payout_2',
                        value: target.value ? parseFloat(target.value) : '',
                      },
                      order: lapSponsorship.order,
                    })
                  }
                  onBlur={handleBlur}
                  value={lapSponsorship.payout_2 ?? ''}
                  error={errors.payout_2}
                  disabled={!payoutCheck2}
                  type="number"
                />
              </ResponsiveCol>

              <ResponsiveCol>
                <Input
                  id={`lap_sponsorships.${index}.payout_2_label`}
                  name={`lap_sponsorships.${index}.payout_2_label`}
                  label="Name"
                  placeholder=""
                  onChange={({ target }) =>
                    onChange({
                      target: {
                        name: 'payout_2_label',
                        value: target.value,
                      },
                      order: lapSponsorship.order,
                    })
                  }
                  onBlur={handleBlur}
                  value={lapSponsorship.payout_2_label ?? ''}
                  error={errors.payout_2_label}
                  disabled={!payoutCheck1}
                />
              </ResponsiveCol>
            </>
          )}
        </ResponsiveRow>

        <Spacer size={10} />

        <ResponsiveRow>
          <ResponsiveCol style={{ flex: '0 0 10%', paddingTop: 25 }}>
            <Checkbox
              name="third"
              checked={payoutCheck3}
              onChange={(event) => {
                const value = event.target?.checked;
                setPayoutCheck3(value);
                onChange({
                  target: {
                    name: 'payout_3',
                    value: null,
                  },
                  order: lapSponsorship.order,
                });
              }}
              onBlur={handleBlur}
              error={errors.third && touched.third && errors.third}
              rightText="3rd"
            />
          </ResponsiveCol>
          {payoutCheck3 && (
            <>
              <ResponsiveCol>
                <Input
                  id={`lap_sponsorships.${index}.payout_3`}
                  name={`lap_sponsorships.${index}.payout_3`}
                  label="Payout"
                  placeholder=""
                  onChange={({ target }) =>
                    onChange({
                      target: {
                        name: 'payout_3',
                        value: target.value ? parseFloat(target.value) : '',
                      },
                      order: lapSponsorship.order,
                    })
                  }
                  onBlur={handleBlur}
                  value={lapSponsorship.payout_3 ?? ''}
                  error={errors.payout_3}
                  disabled={!payoutCheck3}
                  type="number"
                />
              </ResponsiveCol>

              <ResponsiveCol>
                <Input
                  id={`lap_sponsorships.${index}.payout_3_label`}
                  name={`lap_sponsorships.${index}.payout_3_label`}
                  label="Name"
                  placeholder=""
                  onChange={({ target }) =>
                    onChange({
                      target: {
                        name: 'payout_3_label',
                        value: target.value,
                      },
                      order: lapSponsorship.order,
                    })
                  }
                  onBlur={handleBlur}
                  value={lapSponsorship.payout_3_label ?? ''}
                  error={errors.payout_3_label}
                  disabled={!payoutCheck1}
                />
              </ResponsiveCol>
            </>
          )}
        </ResponsiveRow>
      </ResponsiveCol>

      <Spacer size={30} />

      <Modal
        isVisible={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        title={'Are you sure you want to remove this Lap Sponsorship?'}
        maxWidth={600}
      >
        <Spacer size={20} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            width: '50%',
            margin: 'auto',
          }}
        >
          <Button type="button" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => {
              const newSponsorships = lapSponsorships.filter(
                (_, currentIndex) => currentIndex !== index
              );
              onChangeSponsorship('lap_sponsorships', newSponsorships);
              setShowDeleteModal(false);
            }}
          >
            Okay
          </Button>
        </div>
        <Spacer size={20} />
      </Modal>
    </>
  );
};
