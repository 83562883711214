import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import Loading from 'components/Loading';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import { withEventYearFilterContext } from 'components/YearDropdown';
import { useGetUserExpiredCreditsByAccount } from 'pages/track/CreditTrack/TrackExpiredCredits/gql/get-user-expired-credits-by-account';
import CreditDetail from '../CreditsList/CreditDetail';
import UserCreditList from '../CreditsList/UserCreditList';

const TrackExpiredCreditDetailMobile = withEventYearFilterContext((props) => {
  const { location } = props;

  const urlParams = new URLSearchParams(location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const [credits, setCredits] = useState();
  const [count, setCount] = useState();

  const [detailIndex, setDetailIndex] = useState();

  const { res, loading } = useGetUserExpiredCreditsByAccount();

  useEffect(() => {
    if (res && res.getUserExpiredCreditsByAccount) {
      setCredits(res?.getUserExpiredCreditsByAccount?.results);
      setCount(res?.getUserExpiredCreditsByAccount?.count);
    }
  }, [res]);

  if (loading) return <Loading />;

  return detailIndex === undefined ? (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <Spacer size={10} />
        {credits && credits.length > 0 && (
          <UserCreditList
            credits={credits}
            currentPage={currentPage}
            setDetailIndex={setDetailIndex}
          />
        )}
        <div
          style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}
        >
          <Pagination
            count={count ?? 0}
            perPage={15}
            currentPage={currentPage || 1}
            color={'#fff'}
          />
        </div>
        <Spacer size={40} />
      </div>
    </>
  ) : (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <Spacer size={10} />
        <CreditDetail credit={credits[detailIndex]} />
      </div>
    </>
  );
});

export default withTheme(TrackExpiredCreditDetailMobile);
