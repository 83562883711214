import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ExportExcelButton } from 'components/ExportExcelButton/ExportExcelButton';
import { formatTimestampEST } from 'shared/formatters';
import { useGetAccountMembersReport } from './gql/useGetAccountMembersReport.mutation';
import 'moment-timezone';

const Container = styled.button`
  border-radius: 5px;
  background-color: #fa4616;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;

  &:focus {
    outline: none;
  }

  @media (min-width: 860px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 10px 0 10px;
    background-color: transparent;
    cursor: pointer;
    width: auto;
  }
`;

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const exportCsv = async (props, getAccountMembersReport) => {
  try {
    const { accountId, year, typeId, division, search, type } = props;
    const response = await getAccountMembersReport(
      accountId,
      year,
      typeId,
      division,
      search,
      type
    );

    if (!response || response.errors) {
      toast.error('Account Members List can not be retrieved');
      return;
    }

    if (response.data.getAccountMembersReport) {
      const { results } = response.data.getAccountMembersReport;

      let _title = '',
        filename = '';

      _title = moment().format('MMM DD - YYYY h:mm A').toUpperCase();

      filename = 'Account Members List - ' + _title;

      const dateTimeStamp = moment().format('MMM DD - YYYY h:mm A');

      const index = results.findIndex((item) => item.forms.length > 0) ?? 0;

      const csv = `Account Members List
      Exported:, ${dateTimeStamp} \n,,,
      Name,Email,Phone,Annual #,Annual Name,Reserved,Price,Purchase Date,${
        results[index]?.forms[0]?.data?.length
          ? results[index]?.forms[0]?.data?.map((item) => item.label)
          : ''
      }
      ${Object.values(results)
        .map(
          (value) =>
            `${value.user.formatted_name.replace(/,/g, '') ?? ''},${
              value.user.email ?? ''
            },${value.user.cellphone ?? ''},${value.serial_number ?? ''},${
              value.membership.name ?? ''
            },${
              value.section || value.row || value.seat
                ? `${value.section ? value.section : ''}${
                    value.section && (value.row || value.seat) ? ',' : ''
                  }${value.row ? value.row : ''}${
                    value.row && value.seat ? ',' : ''
                  }${value.seat ? value.seat : ''}`
                : '-'
            },${value.cost},${formatTimestampEST(
              value.purchase.purchase_date
            )},${
              value.forms && value.forms.length > 0
                ? value.forms[0].data
                    .map((item) =>
                      item.value
                        ? typeof item.value === 'boolean'
                          ? item.value
                            ? 'Yes'
                            : 'No'
                          : item.value
                              .replace(/,/g, '')
                              .replace(/(\r\n|\n|\r)/g, '')
                        : ''
                    )
                    .join(',')
                : ''
            },`
        )
        .join('\n')}`;

      download(`${filename}.csv`, csv.trim());
    } else {
      toast.error('Account Members List can not be retrieved');
    }
  } catch (e) {}
};

const ExportAccountMembers = ({ ...props }) => {
  const getAccountMembersReport = useGetAccountMembersReport();

  return (
    <div>
      <Container onClick={() => exportCsv(props, getAccountMembersReport)}>
        <ExportExcelButton />
      </Container>
    </div>
  );
};

export default ExportAccountMembers;
