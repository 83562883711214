import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Button, IconButton } from 'components/Button';
import { IndicatorDots } from 'components/Carousel/IndicatorDots';
import Icon from 'components/Icon';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e0dfdf;
  padding: 10px 0px 5px 0px;
  margin-top: 20px;
  max-height: 84px;
  width: 85%;
  margin: 0 auto;
`;

const Header = styled.span`
  font-family: Barlow Condensed;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
`;

export const HelpModals = ({ isVisible, setIsVisible, contents }) => {
  const [showHelpModal, setShowHelpModal] = React.useState(false);

  const isTicketHoss = 'tickethoss' === process.env.REACT_APP_PLATFORM;

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const slidesToScroll = 1;

  const theme = useTheme();

  const onClose = () => {
    if (setIsVisible) {
      setIsVisible(false);
    } else {
      setShowHelpModal(false);
    }
    setCurrentSlide(0);
  };

  const PlaceholderContent = [
    {
      title: '',
      content: '',
      video: isTicketHoss
        ? 'https://player.vimeo.com/video/1063764809'
        : 'https://player.vimeo.com/video/1063765384',
      hideFooter: true,
    },
    // {
    //   title: 'Explanation Part 2',
    //   content: (
    //     <div>
    //       <p>
    //         Lorem ipsum is placeholder text commonly used in the graphic, print,
    //         and publishing industries for previewing layouts and visual mockups.
    //       </p>
    //       <Spacer size={16} />
    //       <p>
    //         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //         eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
    //         ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //         aliquip ex ea commodo consequat. Duis aute irure dolor in
    //         reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //         pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //         culpa qui officia deserunt mollit anim id est laborum.
    //       </p>
    //     </div>
    //   ),
    // },
    // {
    //   title: 'Explanation Part 3',
    //   content: (
    //     <span>
    //       Lorem ipsum is placeholder text commonly used in the graphic, print,
    //       and publishing industries for previewing layouts and visual mockups.
    //     </span>
    //   ),
    // },
    // {
    //   title: 'Hide Help Like This?',
    //   titleStyle: {
    //     fontSize: 32,
    //     fontWeight: 700,
    //     lineHeight: '40px',
    //     fontFamily: 'Barlow Condensed',
    //   },
    //   content: (
    //     <div style={{ alignContent: 'center', textAlign: 'center' }}>
    //       <p style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
    //         Click on any{' '}
    //         <Icon
    //           icon="Video-Info-Icon"
    //           size={18}
    //           color={theme.colors.primary}
    //         />{' '}
    //         icon to bring it back.
    //       </p>

    //       <Spacer size={20} />
    //       <Button onClick={onClose}> Hide For Today </Button>

    //       <Spacer size={10} />
    //       <Button onClick={onClose} outlined={true}>
    //         {' '}
    //         Hide Forever
    //       </Button>

    //       <Spacer size={10} />
    //       <Button
    //         onClick={onClose}
    //         outlined={true}
    //         buttonStyle={{ border: 'none', minWidth: 130 }}
    //       >
    //         {' '}
    //         Hide Now
    //       </Button>
    //     </div>
    //   ),
    //   hideFooter: true,
    //   customStyle: {
    //     justifyContent: 'center',
    //     margin: 'auto',
    //     textAlign: 'center',
    //   },
    // },
  ];

  const modalContent = contents ?? PlaceholderContent;

  const slideCount = modalContent.length;

  const goToSlide = (slide) => {
    setCurrentSlide(slide);
  };

  const nextSlide = () => {
    if (currentSlide <= slideCount) onClose();
    else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  return (
    <>
      {!setIsVisible && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            marginLeft: 15,
            minWidth: 40,
          }}
        >
          <Icon
            style={{ marginTop: 7 }}
            icon="Video-Info-Icon"
            onClick={() => setShowHelpModal(true)}
            size={45}
            color={theme.colors.primary}
          />
        </div>
      )}
      <Modal
        isVisible={isVisible ?? showHelpModal}
        setIsVisible={setIsVisible ?? setShowHelpModal}
        maxWidth={500}
        height={565}
        modalStyle={{
          borderRadius: '8px',
          maxHeight: 565,
          position: 'fixed',
          bottom: 20,
          right: 20,
          top: 'unset',
          display: 'flex',
          flexDirection: 'column',
          background: 'transparent',
          height: 'auto',
          overflow: 'hidden',
        }}
        closable={false}
        noHeader={true}
      >
        <div
          style={{ alignSelf: 'flex-end', marginRight: 10 }}
          onClick={onClose}
          role="presentation"
        >
          <IconButton
            onClick={onClose}
            icon="close"
            size="normal"
            backgroundColor="white"
            style={{
              cursor: 'pointer',
              background: 'white',
              borderRadius: '50%',
              padding: 5,
              width: 28,
              height: 28,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              boxShadow: 'none',
            }}
            color="#5b372d"
            hoverColor="#5b372d"
          />
        </div>

        <Spacer height={10} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flex: 1,
            borderRadius: '8px',
            background: 'white',
          }}
        >
          {modalContent[currentSlide]?.video && (
            <iframe
              style={{
                width: '100%',
                height: '280px',
                borderRadius: '8px 8px 0 0',
              }}
              src={modalContent[currentSlide]?.video}
              allow="autoplay; fullscreen"
              allowFullScreen
              title={modalContent[currentSlide]?.title ?? 'Help Video'}
            />
          )}
          {modalContent[currentSlide]?.content &&
            modalContent[currentSlide].title && (
              <div
                style={{
                  padding: 20,
                  textAlign: 'start',
                  textJustify: 'initial',
                  ...modalContent[currentSlide]?.customStyle,
                }}
              >
                {modalContent[currentSlide]?.title ? (
                  <Header style={modalContent[currentSlide].titleStyle}>
                    {modalContent[currentSlide].title}
                  </Header>
                ) : null}
                <Spacer height={20} />

                {modalContent[currentSlide]?.content ? (
                  <div>{modalContent[currentSlide].content}</div>
                ) : null}
              </div>
            )}

          {!modalContent[currentSlide]?.hideFooter && (
            <Footer>
              {currentSlide > 0 ? (
                <Button
                  outlined={true}
                  onClick={() => goToSlide(currentSlide - 1)}
                  buttonStyle={{ height: 44, borderRadius: 8 }}
                >
                  Back
                </Button>
              ) : (
                <div style={{ minWidth: 87 }}>
                  <Spacer width={100} />
                </div>
              )}

              <IndicatorDots
                currentSlide={currentSlide}
                slideCount={slideCount}
                slidesToScroll={slidesToScroll}
                goToSlide={goToSlide}
                inactiveColor="#FA461633"
                customStyle={{ height: 8, width: 8 }}
              />

              <Button
                onClick={nextSlide}
                buttonStyle={{ height: 44, borderRadius: 8 }}
              >
                {currentSlide <= slideCount ? 'Got it' : 'Next'}
              </Button>
            </Footer>
          )}
        </div>
      </Modal>
    </>
  );
};
