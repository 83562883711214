import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { Button } from 'components/Button';
import { Drawer, DrawerPadding } from 'components/Drawer';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { DatePicker } from 'components/Form/DatePicker';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';
import { getTimes, setTime } from 'shared/timeUtils';
import CreateCategory from './gql/CreateCategory';
import UpdateCategory from './gql/UpdateCategory';

const formValues = [
  'name',
  'type',
  'icon_id',
  'icon',
  'code',
  'code_date',
  'code_time',
];

const CATEGORY_TYPES = [
  'ticket',
  'other',
  'annual',
  'membership',
  'registration',
];

function getInitialValues(category, keys) {
  const blankValues = {
    ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
  };
  const initialValues = !category
    ? blankValues
    : {
        ...keys.reduce((formValues, key) => {
          return key === 'type'
            ? {
                ...formValues,
                [key]: {
                  value: category['type'],
                  label: category['type'],
                },
              }
            : key === 'code_time'
            ? {
                ...formValues,
                [key]:
                  category['code_time'] &&
                  category['code_time'] !== 'Invalid date'
                    ? setTime(category['code_time'])
                    : null,
              }
            : category[key]
            ? {
                ...formValues,
                [key]: category[key],
              }
            : formValues;
        }, {}),
      };
  return initialValues;
}

function getCategoriesInput({ data, currentCategory, action }) {
  const input = {
    ...data,
    ...(action === 'update' && currentCategory
      ? { id: currentCategory.id }
      : null),
    type: data.type ? data.type.value : '',
  };

  formValues.forEach((key) => {
    if (input[key] === '' || input[key] === undefined) delete input[key];
  });

  return input;
}

const CategoriesEdit = ({
  isVisible,
  handleOutClick,
  createCategory,
  updateCategory,
  currentCategory,
}) => {
  const successMessage = () =>
    toast.success(currentCategory ? 'Category Updated' : 'Category Created');
  const errorMessage = (response) =>
    toast.error(
      currentCategory
        ? response.global
          ? 'Error Updating Category'
          : "There were errors with your submission check the form's field for errors."
        : 'Error Creating Category'
    );

  return (
    <Drawer
      title={!currentCategory ? 'Create New Category' : 'Update Category'}
      isVisible={isVisible}
      handleOutClick={handleOutClick}
    >
      <Formik
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={getInitialValues(currentCategory, formValues)}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }

          if (Object.keys(errors).length) {
            toast.error('Please check your form for errors.');
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          let response;
          setSubmitting(true);

          const data = Object.entries(values).reduce((acc, [key, value]) => {
            return {
              ...acc,
              [key]: value === '' ? null : value,
            };
          }, {});

          // Update the time to be in the correct format
          if (data.code_time) data.code_time = getTimes(data.code_time);

          delete data['icon'];

          if (currentCategory) {
            response = await updateCategory(
              getCategoriesInput({ data, currentCategory, action: 'update' })
            );
          } else {
            response = await createCategory(
              getCategoriesInput({ data, currentCategory, action: 'create' })
            );
          }

          setSubmitting(false);

          if (!response || response.errors) {
            errorMessage(response);
            return setErrors(response.errors);
          } else {
            successMessage();
            resetForm();
            handleOutClick();
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DrawerPadding border>
              <Input
                id="name"
                label="Name"
                placeholder="Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.name}
              />

              <Spacer size={18} />

              <AutoSuggest
                label="Ticket Type"
                placeholder="type"
                options={CATEGORY_TYPES.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={values.type ? values.type : null}
                isSearchable
                isClearable
                onChange={(value) => {
                  setFieldValue('type', value);
                }}
              />

              <Spacer size={18} />

              <Dropzone
                id="icon_id"
                files={
                  values.icon_id
                    ? [
                        {
                          name: values.icon_id,
                          preview: values.icon,
                        },
                      ]
                    : []
                }
                onChange={setFieldValue}
                error={errors.icon_id && errors.icon_id}
                label="Icon"
              />

              <Spacer size={18} />

              <Input
                id="code"
                label="Access Code"
                placeholder="Access Code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
                error={errors.code}
              />

              <Spacer size={18} />

              <DatePicker
                id="code_date"
                name="code_date"
                label="End Date"
                type="date"
                test
                onChange={setFieldValue}
                value={values.code_date}
                error={errors.code_date && errors.code_date}
                isResponsive={true}
              />

              <Spacer size={18} />

              <DatePicker
                id={'code_time'}
                type="time"
                label={`End Time`}
                name="code_time"
                value={values.code_time}
                onChange={(name, event) => {
                  handleChange({
                    target: {
                      name,
                      value: event.target.value,
                    },
                  });
                }}
                isResponsive={true}
              />

              <Spacer size={18} />
            </DrawerPadding>

            <DrawerPadding>
              <Button type="submit" disabled={isSubmitting} block>
                {currentCategory ? 'Update Category' : 'Add Category'}
              </Button>
            </DrawerPadding>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default compose(CreateCategory, UpdateCategory)(CategoriesEdit);
