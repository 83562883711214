import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { ExportExcelButton } from 'components/ExportExcelButton/ExportExcelButton';
import { formatPhoneNumber } from 'shared/formatters';

const Container = styled.button`
  border-radius: 5px;
  background-color: #fa4616;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;

  &:focus {
    outline: none;
  }

  @media (min-width: 860px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 10px 0 10px;
    background-color: transparent;
    cursor: pointer;
    width: auto;
  }
`;

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const exportCvs = ({ participants, event }) => {
  const csv = `${
    event.start_date
      ? moment(event.start_date, 'MM-DD-YYYY').format('MMM DD')
      : null
  } ${
    event.end_date
      ? `- ${moment(event.end_date, 'MM-DD-YYYY').format('MMM DD, YYYY')}`
      : null
  } - ${event.name},,,,,,
Name, Duty, Age, Email, Phone, Address, Passes, Reserved, Pass Price, Promo, Waiver Signed
${Object.values(participants)
  .map(
    (value) =>
      `"${value.name ?? ''}","${value.duty ?? ''}","${value.age ?? ''}","${
        value.email ?? ''
      }","${formatPhoneNumber(value.phone ?? '')}","${
        value.address ?? ''
      }","${value.tickets.reduce(
        (curr, pass) =>
          value.status === 'refunded'
            ? 'REFUNDED'
            : `${curr} ${pass.ticket_name}${'\n'}`,
        ''
      )}","${value.tickets.reduce(
        (curr, pass) =>
          value.status === 'refunded'
            ? curr
            : pass.section || pass.row || pass.seat
            ? `${pass.section ? pass.section : ''}${
                pass.section && pass.row ? ',' : ''
              } 
        ${pass.row ? pass.row : ''}${pass.row && pass.seat ? ',' : ''} 
        ${pass.seat ? pass.seat : ''}${'\n'}`
            : curr,
        ''
      )}","${value.tickets.reduce(
        (curr, pass) =>
          value.status === 'refunded' ? '' : `${curr} $${pass.cost.toFixed(2)}`,
        ''
      )}","${value.promo_code ?? ''}","${value.has_waiver ? 'Yes' : 'No'}"`
  )
  .join('\n')}`;

  download('ParticipantsList.csv', csv.trim());
};

const Export = ({ ...props }) => {
  return (
    <Container onClick={() => exportCvs(props)}>
      <ExportExcelButton />
    </Container>
  );
};

export default Export;
