import jsPDF from 'jspdf';
import moment from 'moment';
import React, { useState } from 'react';
import 'jspdf-autotable';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { PrintButton } from 'components/PrintButton';
import { useGetAppReport } from '../gql/mutation/useGetAppReport';
import { useGetCardReport } from '../gql/mutation/useGetCardReport';
import { useGetCashReport } from '../gql/mutation/useGetCashReport';
import checkbox from './checkbox.png';
import { KartPassLogo } from './kartpass-logo';
import { Logo } from './logo';
import { TicketHossLogo } from './tickethoss-logo';
import 'moment-timezone';

const ButtonContainer = styled.div`
  & > button {
    width: 100%;

    @media screen and (min-width: 861px) {
      width: auto;
    }
  }
`;

const getHeaders = (hide_fee) => {
  return [
    hide_fee
      ? [
          'Date/Time',
          'Purchaser',
          'Order Total',
          'Discount',
          'Refund',
          'Credit',
          'Absorbed Fees',
          'Transaction Total',
        ]
      : [
          'Date/Time',
          'Purchaser',
          'Order Total',
          'Discount',
          'Refund',
          'Credit',
          'Transaction Total',
        ],
  ];
};

const getColumns = (hide_fee) => {
  return hide_fee
    ? [
        { dataKey: 'date', header: 'Date/Time' },
        { dataKey: 'purchaser', header: 'Purchaser' },
        { dataKey: 'subtotal', header: 'Order Total' },
        { dataKey: 'discount', header: 'Discount' },
        { dataKey: 'refund', header: 'Refund' },
        { dataKey: 'credit', header: 'Credit' },
        { dataKey: 'fee', header: 'Absorbed Fees' },
        { dataKey: 'total', header: 'Transaction Total' },
      ]
    : [
        { dataKey: 'date', header: 'Date/Time' },
        { dataKey: 'purchaser', header: 'Purchaser' },
        { dataKey: 'subtotal', header: 'Order Total' },
        { dataKey: 'discount', header: 'Discount' },
        { dataKey: 'refund', header: 'Refund' },
        { dataKey: 'credit', header: 'Credit' },
        { dataKey: 'total', header: 'Transaction Total' },
      ];
};

const buildData = (transactions, hide_fee) => {
  return transactions.map((transaction) =>
    hide_fee
      ? [
          transaction.purchase_date,
          transaction.purchaser,
          transaction.subtotal,
          transaction.discount,
          transaction.refund ? `${transaction.refund}` : '',
          transaction.credit,
          transaction.fee,
          transaction.total,
        ]
      : [
          transaction.purchase_date,
          transaction.purchaser,
          transaction.subtotal,
          transaction.discount,
          transaction.refund ? `${transaction.refund}` : '',
          transaction.credit,
          transaction.total,
        ]
  );
};

export const TransactionsPrintList = ({
  text,
  button,
  eventId,
  reportType,
}) => {
  const isKartPass = 'kartpass' === process.env.REACT_APP_PLATFORM;
  const isTicketHoss = 'tickethoss' === process.env.REACT_APP_PLATFORM;

  const reportTypeText =
    reportType === 'appReport'
      ? 'App Transaction Report'
      : reportType === 'cashReport'
      ? 'POS Cash Report'
      : 'POS Card Report';

  const getAppReport = useGetAppReport(eventId);
  const getCashReport = useGetCashReport(eventId);
  const getCardReport = useGetCardReport(eventId);

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : pathname?.split('/').includes('admin-track')
    ? 'admin-track'
    : 'admin';
  const isStaging = window.location.href.includes('dashboard-staging');

  const [disabled, setDisabled] = useState(false);

  let filename = '';

  const getData = async () => {
    try {
      let response;
      let eventPerformanceReport;

      if (reportType === 'appReport') {
        response = await getAppReport(eventId);
        eventPerformanceReport = response.data.getEventAppTransactions;
      } else if (reportType === 'cashReport') {
        response = await getCashReport(eventId);
        eventPerformanceReport = response.data.getEventPOSCashTransactions;
      } else if (reportType === 'cardReport') {
        response = await getCardReport(eventId);
        eventPerformanceReport = response.data.getEventPOSCardTransactions;
      }

      let title = '';

      const { event } = eventPerformanceReport;

      if (event.isMultiDay)
        title = `${moment(event.start_date, 'MM-DD-YYYY')
          .format('MMM DD')
          .toUpperCase()} - ${moment(event.end_date, 'MM-DD-YYYY')
          .format('MMM DD - YYYY')
          .toUpperCase()}`;
      else
        title = moment(event.start_date, 'MM-DD-YYYY')
          .format('MMM DD - YYYY')
          .toUpperCase();

      filename = reportTypeText + ' - ' + event?.name + ' - ' + title;
      return { eventPerformanceReport, title, filename };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      toast.error('Error generating report');
      setDisabled(false);
    }
  };

  const exportPDF = async () => {
    const { eventPerformanceReport, filename, title } = await getData();

    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape
    const event = eventPerformanceReport.event;

    const doc = new jsPDF(orientation, unit, size, true);

    doc.setFontSize(15);
    doc.setFontStyle('bold');

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ?? pageSize.getHeight();

    const text = doc.splitTextToSize(title, pageWidth - 650, {});

    const trackOrSeriesName =
      event.track?.name ??
      (event.series?.length > 0 ? event.series[0].name : '');
    doc.text(text, 40, 40);
    doc.text(
      event?.name.length > 75 ? event?.name.slice(0, 75) : event?.name,
      40,
      60
    );
    let Y = 60;
    if (event?.name.length > 75) {
      Y += 20;
      doc.text(event?.name.slice(75, 150), 40, Y);
    }

    if (event?.account_name) {
      Y += 20;
      doc.text(event?.account_name, 40, Y);
    }

    if (trackOrSeriesName) {
      Y += 20;
      doc.text(trackOrSeriesName, 40, Y);
    }

    Y += 20;

    doc.setTextColor('#fa4616');

    doc.setTextColor('#000');

    doc.text(reportTypeText, 650, Y + 20);
    Y += 30;

    if (checkbox) {
      if (isKartPass) {
        doc.addImage(
          KartPassLogo,
          'PNG',
          650,
          reportType === 'appReport' ? 40 : 20,
          100,
          75,
          '',
          'FAST'
        );
      } else if (isTicketHoss) {
        doc.addImage(
          TicketHossLogo,
          'PNG',
          660,
          reportType === 'appReport' ? 40 : 20,
          100,
          54,
          '',
          'FAST'
        );
      } else {
        doc.addImage(Logo, 'PNG', 660, 20, 75, 75, '', 'FAST');
      }
    }

    // const extra = 30;
    const headers = getHeaders(eventPerformanceReport.event.hide_fee);

    doc.setTextColor('#fa4616');

    if (event.hide_fee) {
      Y += 10;
      doc.text(`Promoter Absorbed Fees`, 620, Y);
      Y += 10;
    }

    doc.setTextColor('#000');

    const data5 = buildData(
      eventPerformanceReport.data[0].data,
      eventPerformanceReport.event.hide_fee
    );

    doc.text(`Tickets Sold - (${eventPerformanceReport?.num_tickets})`, 40, Y);
    Y += 20;

    doc.autoTable({
      startY: Y,
      rowPageBreak: 'avoid',
      columns: getColumns(eventPerformanceReport.event.hide_fee),
      head: headers,
      body: data5,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/transactions/event/${event.id}?purchase_id=${
            eventPerformanceReport.data[0].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setFontStyle('normal');

      const now = moment();
      const momentInSpecificTimezone = now.tz('America/New_York');
      const dateTimeStamp =
        momentInSpecificTimezone.format('MMM DD - YYYY h:mm A').toUpperCase() +
        ' EST';
      doc.text(`Exported ${dateTimeStamp}`, pageWidth - 40, pageHeight - 20, {
        align: 'right',
      });
    }
    // Set typography back to default
    doc.setFontSize(15);
    doc.setFontStyle('bold');

    doc.setProperties({
      title: `${filename}.pdf`,
      subject: `${filename}`,
      author: process.env.REACT_APP_PLATFORM,
    });

    return {
      ...doc,
      fileName: `${filename}.pdf`,
    };
  };

  return (
    <ButtonContainer>
      <PrintButton
        buildPdf={exportPDF}
        text={text}
        disabled={disabled}
        variant={'minimal'}
        button={button}
      />
    </ButtonContainer>
  );
};
