import { Formik } from 'formik';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Form/Checkbox';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import { AppContext } from 'shared/AppContext';
import { useCart } from 'shared/CartContext';
import { useMobileDelivery } from './gql/queries/useMobileDelivery';
import { useTextReceipt } from './gql/queries/useTextReceipt';
import { successMessage } from './PaymentOption';

const formValues = {
  cellphone: '',
  country_code: '',
  calling_code: '',
  send_receipt: true,
};

export const Container = styled.div`
  width: 100%;

  & .PhoneInput {
    background: ${(props) => props.theme.colors.white};
    box-sizing: border-box;
    & .PhoneInputInput {
      color: ${(props) => props.theme.colors.secondary};
      background: ${(props) => props.theme.colors.white};
      flex: 1 1;
      outline: none;
      min-width: 0;
      font-family: 'Barlow Condensed';
      font-weight: 600;
      border: 0;
      text-align: center;
      box-shadow: inset 0 0 0 2px #888888;
      border-radius: 8px;
      margin: 6px;
      padding: 10px;
      font-size: 33px;
      max-width: 100%;

      @media (max-width: 450px) {
        min-width: 200px;
      }
    }
    & .PhoneInputInput::placeholder {
      word-spacing: -3px;
      letter-spacing: 3px;
      font-size: 31px;
      font-weight: 800;
    }

    &.PhoneInput--focus {
      border-color: ${(props) => props.theme.colors.primary};
      .PhoneInputInput {
        outline: none;
      }
    }
    .PhoneInputCountry {
      .PhoneInputCountrySelectArrow {
        color: ${(props) => props.theme.colors.primary};
        border-right-width: 3px;
        border-bottom-width: 3px;
      }
    }
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  align-items: center;
  width: 100%;

  @media (max-width: 450px) {
    gap: 10px;
  }
`;

const MobileDeliveryModal = ({
  isVisible,
  setIsVisible,
  printReceipt = false,
  showSuccessMessage,
}) => {
  const theme = useTheme();

  const isTab = useMediaQuery({ query: '(max-width: 1190px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isTicketHoss = 'tickethoss' === process.env.REACT_APP_PLATFORM;

  const { clearTicket, purchaseId, event, account: eventAccount } = useCart();

  const {
    state: { account },
  } = useContext(AppContext);

  const availableAccount = account || eventAccount;

  const posSettings =
    event && event?.pos_settings
      ? event?.pos_settings
      : availableAccount && availableAccount?.pos_settings
      ? availableAccount?.pos_settings
      : undefined;

  const showMobilePrint = posSettings
    ? posSettings?.ticket_delivery === 'mobile' && isTicketHoss
    : false;
  const showTextReceipt = posSettings
    ? posSettings?.receipt_delivery === 'mobile' && isTicketHoss
    : false;

  const [isPrintReceipt, setIsPrintReceipt] = useState(printReceipt);
  const [printReceiptSelected, setPrintReceiptSelected] = useState(true);

  const mobileDelivery = useMobileDelivery();
  const textReceipt = useTextReceipt();

  const resetAndClose = () => {
    // Delay for 1 second to allow the clearTicket function to complete
    setTimeout(() => {
      clearTicket();

      setIsVisible(false);
      setIsPrintReceipt(false);
    }, 1000);
  };

  useEffect(() => {
    if (showSuccessMessage && isVisible) {
      successMessage();
    }
  }, [showSuccessMessage, isVisible]);

  // Reset values on close
  useEffect(() => {
    if (!isVisible) {
      setIsPrintReceipt(false);
      setPrintReceiptSelected(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && !showMobilePrint && showTextReceipt)
      setIsPrintReceipt(true);
    else if (isVisible && !showMobilePrint && !showTextReceipt) resetAndClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, showMobilePrint, showTextReceipt]);

  return showMobilePrint || showTextReceipt ? (
    <Modal
      maxWidth={600}
      modalStyle={{
        height: isMobile ? '85dvh' : '95dvh',
        maxHeight: '95dvh',
        padding: 0,
        right: isMobile ? 0 : 20,
        top: isTab ? '30px' : '2.5vh',
        margin: 'auto 0',
        width: isMobile ? '100vw' : isTab ? '60vw' : '40vw', // Conditional width
        maxWidth: 550, // Disable maxWidth on smaller screens
        minWidth: isMobile ? 350 : null, // Full width on small screens
        display: 'flex',
        flexDirection: 'column',
      }}
      overlayStyle={{
        zIndex: 10,
      }}
      isVisible={isVisible}
      hideModal={resetAndClose}
      noHeader={true}
    >
      <div
        style={{
          display: 'flex',
          padding: 10,
          justifyContent: 'space-between',
          marginBottom: 10,
          borderBottom: '1px solid #EAEAEA',
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: 26,
            fontWeight: 'bold',
            fontFamily: 'Barlow Condensed',
            paddingBottom: 20,
            marginLeft: 25,
          }}
        >
          {isPrintReceipt ? 'Text Receipt' : 'Mobile Ticket Delivery'}
        </div>
        <div
          style={{
            transform: 'rotate(45deg)',
            display: 'flex',
            justifyContent: 'end',
            marginRight: 20,
          }}
        >
          <Icon
            icon="plus"
            color={theme.colors.primary}
            size={24}
            onClick={resetAndClose}
            style={{ transform: 'rotate(45deg)', fontWeight: 'bold' }}
          />
        </div>
      </div>

      {showMobilePrint || showTextReceipt ? (
        <Formik
          initialValues={formValues}
          validate={(values) => {
            const errors = {};

            if (!values.cellphone) {
              errors.cellphone = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (!values.cellphone) return;

            setSubmitting(true);
            try {
              const result = !isPrintReceipt
                ? mobileDelivery({
                    cellphone: values.cellphone,
                    calling_code: values.calling_code ?? '+1',
                    purchase_id: +purchaseId?.id,
                    text_receipt: values.send_receipt,
                  })
                : textReceipt({
                    cellphone: values.cellphone,
                    calling_code: values.calling_code ?? '+1',
                    purchase_id: +purchaseId?.id,
                  });

              if (result && values.send_receipt) {
                toast.success('Sent Successfully');
                resetForm();
                resetAndClose();
              } else if (result) {
                toast.success('Sent Successfully');
                resetForm();
                setIsPrintReceipt(true);
              }
            } catch (e) {
              console.error(e);
              toast.error('Failed to send');
            } finally {
              setSubmitting(false);
            }
          }}
          style={{}}
        >
          {({
            values,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 20,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexGrow: 1,
                }}
                onSubmit={handleSubmit}
              >
                <div
                  style={{
                    paddingInline: isMobile ? 10 : 35,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                    width: isMobile ? '95%' : '90%',
                  }}
                >
                  <FormContainer>
                    <Container theme={theme}>
                      <PhoneInput
                        id="cellphone"
                        placeholder="___ ___ ____"
                        size={25}
                        onBlur={handleBlur}
                        defaultCountry={'US'}
                        onChange={(value) => {
                          setFieldValue('cellphone', value ?? '');
                        }}
                        onCountryChange={(e) => {
                          if (e) {
                            setFieldValue('country_code', e ?? '');
                            setFieldValue(
                              'calling_code',
                              getCountryCallingCode(e) ?? ''
                            );
                          }
                        }}
                        value={values.cellphone}
                        error={errors.cellphone}
                        style={{
                          textAlign: 'center',
                          padding: '0px',
                          color: 'white',
                          fontSize: 20,
                          borderRadius: 6,
                          fontFamily: 'Barlow Condensed Semi',
                          borderColor: '#00001d',
                        }}
                      />
                    </Container>
                  </FormContainer>

                  {!isPrintReceipt && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        paddingLeft: 10,
                      }}
                    >
                      <Checkbox
                        name="checkbox1"
                        checkedIcon={'check1'}
                        checkedBgColor={'#1dbe1d'}
                        checked={values?.send_receipt || false}
                        onChange={() => {
                          setFieldValue(
                            'send_receipt',
                            !values?.send_receipt ?? false
                          );

                          setPrintReceiptSelected(!values?.send_receipt);
                        }}
                        size={[32, 32, 8]}
                        check={values?.send_receipt || false}
                        rightText={'Send Receipt'}
                        myStyle={{ cursor: 'pointer' }}
                        textProps={{
                          style: {
                            fontFamily: 'Barlow Condensed',
                            fontSize: '24px',
                            fontWeight: '700',
                            lineHeight: '34px',
                            letterSpacing: '0px',
                            textAlign: 'center',
                          },
                        }}
                      />
                    </div>
                  )}
                </div>

                <div
                  style={{
                    paddingInline: 35,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    width: '90%',
                  }}
                >
                  {showTextReceipt && !printReceiptSelected && (
                    <Button
                      buttonStyle={{
                        borderRadius: 8,
                        minWidth: '40%',
                        marginInline: 'auto',
                        flex: 1,
                        padding: '10px 0',
                      }}
                      textColor={'#fa4616'}
                      onClick={() =>
                        isPrintReceipt || printReceiptSelected
                          ? resetAndClose()
                          : setIsPrintReceipt(true)
                      }
                      outlined
                    >
                      <span
                        style={{
                          fontSize: '24px',
                          fontWeight: 700,
                          lineHeight: '34px',
                        }}
                      >
                        {isPrintReceipt ? 'New Transaction' : 'Skip'}
                      </span>
                    </Button>
                  )}

                  <Spacer size={5} />

                  <Button
                    type="submit"
                    buttonStyle={{
                      borderRadius: 8,
                      flex: 1,
                      padding: '13px 0',
                    }}
                    disabled={isSubmitting}
                  >
                    <span
                      style={{
                        fontSize: '24px',
                        fontWeight: 700,
                        lineHeight: '34px',
                      }}
                    >
                      {'Send'}
                    </span>
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      ) : (
        <Loading />
      )}
    </Modal>
  ) : (
    <Fragment />
  );
};

export default MobileDeliveryModal;
