import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_SALES_BY_TYPE = gql`
  query GetEventSalesByType($input: GetEventSalesByTypeInput!) {
    getEventSalesByType(input: $input)
  }
`;

export function useGetEventSalesByType(event_id, type) {
  return useQuery(GET_SALES_BY_TYPE, {
    variables: {
      input: {
        event_id: parseInt(event_id),
        type,
      },
    },
  });
}
