import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import React, { useState } from 'react';
import checkbox from 'components/Participants/PrintParticipants/checkbox.png';
import { CheckedInIcon } from 'components/Participants/PrintParticipants/checkedInIcon';
import { Logo } from 'components/Participants/PrintParticipants/logo';
import { PrintButton } from 'components/PrintButton';
import useTheme from 'hooks/useTheme';
import { useGetRegistrationsReport } from './gql/useGetRegistrationsReport';

export function getDriverName(data, user) {
  const firstName =
    data
      .find(
        (field) =>
          field.name === 'first_name' || field.name === 'drivers_first_name'
      )
      ?.value.trim() ?? '';
  const middleName =
    data
      .find(
        (field) =>
          field.name === 'middle_name' || field.name === 'drivers_middle_name'
      )
      ?.value.trim() ?? '';
  const lastName =
    data
      .find(
        (field) =>
          field.name === 'last_name' || field.name === 'drivers_last_name'
      )
      ?.value.trim() ?? '';

  if (firstName.length && lastName.length) {
    const driverName = `${lastName ? `${lastName}, ` : ''}${firstName}${
      middleName ? ` ${middleName}` : ''
    }`;
    return driverName;
  } else {
    const userName = `${user.last_name ? `${user.last_name}, ` : ''}${
      user.first_name
    }${user.middle_name ? ` ${user.middle_name}` : ''}`;
    return userName;
  }
}

export const PrintRegistrations = ({
  date,
  event,
  text,
  ticketFilter,
  division,
}) => {
  const theme = useTheme();

  const getEventRegistrations = useGetRegistrationsReport();
  const [disabled] = useState(false);

  const getData = async () => {
    return getEventRegistrations(
      ticketFilter ? ticketFilter : null,
      division ? division.value : null
    )
      .then((response) => {
        const eventRegistrations = response.data.getRegistrationsReport.results;
        return eventRegistrations;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const exportPDF = async () => {
    const eventRegistrations = await getData();

    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size, true);

    doc.setFontSize(15);
    doc.setFontStyle('bold');

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ?? pageSize.getHeight();

    const text = doc.splitTextToSize(date, pageWidth - 650, {});
    doc.text(text, 40, 40);
    doc.text(event.name, 40, 60);
    doc.text('Registration List', 630, 120);
    if (Logo) {
      doc.addImage(Logo, 'PNG', 660, 20, 75, 75, '', 'FAST');
    }

    const headers = [
      [
        'CHECK IN',
        'DATE',
        'NAME',
        'REGISTRATION NAME',
        'PURCHASER NAME',
        'MEMBER ID',
        'DIVISION',
        'TOTAL',
      ],
    ];

    const dataTable = eventRegistrations.map((participant) => {
      const {
        data,
        is_checked,
        purchase_date,
        registration,
        user,
        division,
        serial_number,
      } = participant;

      const driverName = getDriverName(data, user);
      const { name, price } = registration;

      return [
        is_checked,
        purchase_date,
        driverName,
        name,
        user ? `${user.last_name}, ${user.first_name}` : '',
        serial_number,
        division,
        price ?? '',
      ];
    });

    doc.autoTable({
      startY: 170,
      rowPageBreak: 'avoid',
      columns: [
        { dataKey: 'checkIn', header: 'CHECK IN' },
        { dataKey: 'date', header: 'DATE' },
        { dataKey: 'name', header: 'NAME' },
        { dataKey: 'registrationName', header: 'REGISTRATION NAME' },
        { dataKey: 'purchaserName', header: 'PURCHASER NAME' },
        { dataKey: 'memberId', header: 'MEMBER ID' },
        { dataKey: 'division', header: 'DIVISION' },
        { dataKey: 'total', header: 'TOTAL' },
      ],
      head: headers,
      body: dataTable,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: '#fa4616',
      },
      columnStyles: {
        date: {
          cellWidth: 130,
        },
        name: {
          cellWidth: 160,
          cellPadding: { top: 5, right: 5, bottom: 5, left: 20 },
          textColor: theme.colors.text.black,
        },
      },
      didDrawCell: function (data) {
        if (data.row.section === 'body' && data.column.dataKey === 'checkIn') {
          const isCheckedIn = data.cell.raw;

          // Clear the cell's content
          doc.setFillColor(242, 242, 242);
          doc.rect(
            data.cell.x,
            data.cell.y,
            data.cell.width,
            data.cell.height,
            'F'
          );

          if (isCheckedIn) {
            doc.addImage(
              CheckedInIcon,
              'PNG',
              data.cell.x + 5,
              data.cell.y + 4,
              12,
              12,
              '',
              'FAST'
            );
          } else {
            doc.addImage(
              checkbox,
              'PNG',
              data.cell.x + 5,
              data.cell.y + 4,
              12,
              12,
              '',
              'FAST'
            );
          }
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setFontStyle('normal');

      const now = moment();
      const momentInSpecificTimezone = now.tz('America/New_York');
      const dateTimeStamp =
        momentInSpecificTimezone.format('MMM DD - YYYY h:mm A').toUpperCase() +
        ' EST';
      doc.text(`Exported ${dateTimeStamp}`, pageWidth - 40, pageHeight - 20, {
        align: 'right',
      });
    }
    // Set typography back to default
    doc.setFontSize(15);
    doc.setFontStyle('bold');

    doc.setProperties({
      title: `${event.name} - Registration List`,
      subject: `${event.name} - Registration List`,
      author: 'Pit Pay',
    });
    return doc;
  };

  return (
    <PrintButton
      buildPdf={exportPDF}
      text={text}
      variant="minimal"
      disabled={disabled}
      filename={`${event.name} - Registration List`}
    />
  );
};
