import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled, { useTheme } from 'styled-components';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { HelpModals } from 'components/HelpModals';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import { useGetTrackTransactionsGetEvent } from 'components/Transactions';
import { useSearchInput } from 'hooks/useSearchInput';
import ExportTransaction from 'pages/app/Transactions/export';
import { is_cellphone } from 'pages/app/Users';
import { formatTimestamp, formatPhoneNumber } from 'shared/formatters';
import { useTransactionFilter } from 'shared/transactionFilterContext';
import { EventHeader } from '../../EventsMenu/components/EventHeader';
import { useGetEventSalesByType } from '../../gql/queries/useGetEventSaleByType';
import { Column } from '../../Header/StyledHeader';
import { TransactionsPrintList } from '../TransactionsPrintList';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: start;
  max-width: 80vw;
`;

const PosCardReport = () => {
  const theme = useTheme();

  const { id } = useParams();
  const { setSource, setPaymentMethod } = useTransactionFilter();

  const { data } = useGetTrackTransactionsGetEvent();

  const { data: sales_data } = useGetEventSalesByType(id, 'app');

  const { pathname } = useLocation();
  const userTypeRoute = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  useEffect(() => {
    const getData = async () => {
      setPaymentMethod({ label: 'Card', value: 'card' });
      setSource({ label: 'App', value: 'app' });
    };

    getData();
  }, [id, setSource, setPaymentMethod]);

  const { input } = useSearchInput();
  const currentPage = input.page ? Number(input.page) : 1;

  const columns = [
    {
      label: 'Date/Time',
      key: 'date',
    },
    {
      label: 'Purchaser Name',
      key: 'purchaser',
    },
    {
      label: 'Order Total',
      key: 'subtotal',
    },
    {
      label: 'Discounts',
      key: 'discount',
    },
    {
      label: 'Refund',
      key: 'refund',
    },
    {
      label: 'Credit',
      key: 'credit',
    },
    {
      label: 'Transaction Total',
      key: 'total',
    },
  ];

  function renderRows(purchase) {
    return {
      ...purchase,
      date: formatTimestamp(
        Number(purchase.purchase_date),
        'MMM DD - YYYY h:mm A',
        true
      ).toUpperCase(),
      refund: purchase.refund ? purchase.refund : '$0.00',
      purchaser: (
        <LineHeightText>
          <Link
            to={{
              pathname:
                userTypeRoute === 'admin'
                  ? `/admin/transactions/detail/${purchase.id}`
                  : `/admin-${userTypeRoute}/transactions/detail/${purchase.id}`,
              state: { event: data?.getEvent },
            }}
            style={{
              color: theme.colors.primary,
            }}
          >
            {purchase.user
              ? is_cellphone(purchase.user.last_name)
                ? formatPhoneNumber(purchase.user.last_name)
                : `${purchase.user.last_name}, ${purchase.user.first_name}`
              : purchase.id}
          </Link>
        </LineHeightText>
      ),
    };
  }

  return !data?.getEvent && !data?.getTrackTransactionsV2 ? (
    <div />
  ) : (
    <>
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          paddingTop: 20,
          paddingRight: 40,
        }}
      >
        <EventHeader event={data?.getEvent} margin={'0px 40px 0px 40px'} />

        <Column noBorder>
          <ExportTransaction
            disabled={!data?.getTrackTransactionsV2}
            reportType={'appReport'}
            eventId={id}
          />

          <TransactionsPrintList reportType={'appReport'} eventId={id} />

          <HelpModals />
        </Column>
      </div>

      <Container>
        <ContainerHeader
          borderWidth={'1px'}
          style={{ position: 'relative', width: '97%' }}
        >
          <TitleContainer
            style={{
              flexDirection: 'row',
              width: '95%',
              justifyContent: 'space-between',
            }}
          >
            <FilterContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, whiteSpace: 'nowrap' }}
              >
                App Transactions Report
              </Text>
            </FilterContainer>
            <FilterContainer>
              <Text
                type="heading"
                as="h3"
                color="#3C4144"
                inlineStyle={{ marginRight: 20, whiteSpace: 'nowrap' }}
              >
                Total App Sales: {sales_data?.getEventSalesByType || '0'}
              </Text>
            </FilterContainer>
          </TitleContainer>
        </ContainerHeader>
        <div style={{ padding: 20 }}>
          <Table
            items={data?.getTrackTransactionsV2?.results}
            columns={columns}
            renderRows={renderRows}
          />
          <Pagination
            count={data?.getTrackTransactionsV2?.count}
            perPage={15}
            currentPage={currentPage || 1}
          />
        </div>
      </Container>
    </>
  );
};

export default PosCardReport;
