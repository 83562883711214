import React from 'react';
import { Checkbox } from 'components/Form/Checkbox';
import Text from 'components/Text';
import { PassItem } from './styles';

const getDigits = (barcode) => {
  const digits = barcode?.match(/\d+/g);
  return digits ? digits.join('') : '';
};

const getString = (barcode) => {
  const digits = barcode?.match(/[a-zA-Z]+/g);
  return digits ? digits.join('') : '';
};

export const Tickets = ({
  ticket,
  onChange,
  selectedTickets,
  employeeAdmin,
  isRefund,
}) => {
  return (
    <PassItem>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Checkbox
          inversed
          name="checkbox"
          check={
            ticket.refunded === false || (isRefund && ticket.credited)
              ? true
              : false
          }
          checked={
            (isRefund
              ? !!ticket.refunded && !ticket.credited
              : !!ticket.refunded) ||
            !!selectedTickets.find(
              (selectedTicket) => selectedTicket.id === ticket.id
            )
          }
          // credited: false AND refunded: true === refunded
          // credited: true AND refunded: true === credit
          refunded={!!ticket.refunded && !ticket.credited}
          credited={!isRefund && !!ticket.credited}
          onChange={() => {
            if (employeeAdmin) return;
            if (
              isRefund ? !!ticket.refunded && !ticket.credited : ticket.refunded
            )
              return;
            const ticketExists = selectedTickets.find(
              (selectedTicket) => selectedTicket.id === ticket.id
            );

            if (ticketExists)
              return onChange(
                selectedTickets.filter(
                  (selectedTicket) => selectedTicket.id !== ticket.id
                )
              );

            return onChange([
              ...(selectedTickets || []),
              { ...ticket, refunded: false },
            ]);
          }}
          size={[36, 20, 3]}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            inlineStyle={{
              fontWeight: '600',
              fontSize: 20,
              marginLeft: 10,
              whiteSpace: 'normal',
              lineHeight: '1',
            }}
          >
            {ticket.name}
          </Text>
          <Text
            inlineStyle={{
              fontWeight: '600',
              fontSize: 20,
              marginLeft: 10,
              whiteSpace: 'normal',
              marginTop: 5,
              lineHeight: '1',
            }}
          >
            {ticket.reserved_seat_section
              ? ticket.reserved_seat_prefix
                ? `${ticket.reserved_seat_prefix} ${ticket.section}, Row: ${ticket.row}, Section: ${ticket.seat}`
                : `${ticket.section}, Row: ${ticket.row}, Section: ${ticket.seat}`
              : ticket.reserved_seat_prefix
              ? `${ticket.reserved_seat_prefix} ${ticket.seat}`
              : ticket.seat_id}
          </Text>
          <Text
            inlineStyle={{
              fontWeight: '600',
              fontSize: 20,
              marginLeft: 10,
              marginTop: 5,
              whiteSpace: 'normal',
            }}
          >
            <span> {`${getDigits(ticket?.barcode)}`} </span>
            <span
              style={{
                fontSize: 24,
              }}
            >
              {getString(ticket?.barcode)}
            </span>
          </Text>
        </div>
      </div>
      <Text
        inlineStyle={{
          fontWeight: '600',
          fontSize: 20,
          marginLeft: 10,
        }}
      >
        {ticket.price}
      </Text>
    </PassItem>
  );
};
