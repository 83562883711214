import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Form/Checkbox';
import { Input } from 'components/Form/Input';
import Icon from 'components/Icon';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { useCreateTransactionCredit } from '../gql/useCreateTransactionCredit';
import { useGetRefundEstimate } from '../gql/useGetRefundEstimate';
import { Person } from './Person';
import { Header, ModalContainer, PassItem } from './styles';
import { TransactionInformationRow } from './TransactionModal';

const formValues = { credit_amount: '' };

const AlignmentContainer = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-around';
  margin-top: 10;
  margin-bottom: 10;
`;

export const TransactionCreditModal = ({
  currentTransaction,
  close,
  ...props
}) => {
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [includeFees, setIncludeFees] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [creditAmount, setCreditAmount] = useState();
  const [creditMessage, setCreditMessage] = useState();
  const createTransactionCredit = useCreateTransactionCredit();
  const [finalModal, setFinalModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [estimate, setEstimate] = useState();

  const [passes, setPasses] = useState([]);

  useEffect(() => {
    let newPasses = [];

    const ticketCount = currentTransaction.users.reduce(
      (acc, person) => acc + person.tickets.length,
      0
    );

    const selectedCount = selectedPeople.reduce(
      (acc, person) => acc + person.tickets.length,
      0
    );

    if (ticketCount === selectedCount) {
      newPasses = currentTransaction.users
        .map((person) =>
          person.tickets
            .filter((ticket) => !ticket.refunded)
            .map((ticket) => ticket.id)
        )
        .flat();
    } else {
      newPasses = selectedPeople
        .map((person) =>
          person.tickets
            .filter((ticket) => !ticket.refunded)
            .map((ticket) => ticket.id)
        )
        .flat();
    }
    setPasses(newPasses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll, selectedPeople]);

  const { data, loading } = useGetRefundEstimate(
    currentTransaction.id,
    passes,
    includeFees
  );

  useEffect(() => {
    if (props.location.query) {
      setSelectedPeople([...props.location.query]);
    }
  }, [props.location.query]);

  useEffect(() => {
    if (!loading && data?.refundEstimate) {
      setEstimate(data?.refundEstimate.refund_amount);
    }
  }, [data, loading]);

  useEffect(() => {
    if (passes.length === 0) {
      setEstimate(null);
    }
  }, [passes]);

  const handleMessageChange = (e) => setCreditMessage(e.target.value);

  if (!currentTransaction) return false;

  return !finalModal ? (
    <ModalContainer>
      <Header>
        <Text type="heading">Credit</Text>
        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </Header>
      <div style={{ borderBottom: '2px solid #eeeeee' }}>
        <TransactionInformationRow
          label="Purchased By"
          data={currentTransaction.user ? currentTransaction.user.name : ''}
        />
        <TransactionInformationRow
          label="Subtotal"
          data={currentTransaction.subtotal}
        />
        <TransactionInformationRow label="Fees" data={currentTransaction.fee} />
        <TransactionInformationRow
          label="Promos"
          data={`-${currentTransaction.discount}`}
        />
        <TransactionInformationRow
          label="Credit"
          data={`-${currentTransaction.credit}`}
        />
        <TransactionInformationRow
          label="Total"
          data={currentTransaction.total}
        />
      </div>
      <Spacer size={10} />
      <div
        style={{
          display: 'flex',
          alignItems: 'right',
          marginTop: 20,
          marginBottom: -28,
        }}
      >
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 0,
            display: 'flex',
            zIndex: 5,
          }}
        >
          <Checkbox
            inversed
            name="checkbox"
            checked={selectAll}
            onChange={(value) => {
              if (value.target.checked) {
                const newSelectedPeople = currentTransaction.users.map(
                  (person) => person
                );

                setSelectedPeople(newSelectedPeople);
              } else {
                setSelectedPeople([]);
              }
              setSelectAll(!selectAll);
            }}
            size={[26, 20]}
          />
          <Text
            inlineStyle={{
              fontWeight: '600',
              fontSize: 20,
              marginLeft: 10,
              marginTop: 5,
              whiteSpace: 'normal',
              color: '#fa4616',
            }}
          >
            {!selectAll ? 'Select All' : 'Unselect All'}
          </Text>
        </div>
      </div>

      {currentTransaction.users && currentTransaction.users.length ? (
        <>
          {currentTransaction.users &&
            currentTransaction.users.length &&
            currentTransaction.users.map((person, index) => (
              <Person
                key={person.id ?? index}
                person={person}
                setSelectedPeople={setSelectedPeople}
                selectedPeople={selectedPeople}
                index={index}
                setSelectAll={setSelectAll}
              />
            ))}
          <div style={{ paddingBottom: 20, borderBottom: '2px solid #eeeeee' }}>
            {' '}
          </div>
          <PassItem>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              <Checkbox
                inversed
                name="checkbox"
                checked={includeFees}
                onChange={() => {
                  setIncludeFees(!includeFees);
                }}
                size={[36, 20, 3]}
              />
              <Text
                inlineStyle={{
                  fontWeight: '600',
                  fontSize: 20,
                  marginLeft: 10,
                  whiteSpace: 'normal',
                }}
              >
                Includes Ticket and Processing Fees
              </Text>
            </div>
          </PassItem>

          {/* <Text
            inlineStyle={{
              fontWeight: '600',
              fontSize: 20,
              marginLeft: 10,
              whiteSpace: 'normal',
              marginBottom: 5,
            }}
          >
            Subtotal {currentTransaction.total} Fees:{' '}
            {currentTransaction.fee} Total: {currentTransaction.total}
          </Text> */}
          <div
            style={{
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Text
              inlineStyle={{
                fontWeight: '600',
                fontSize: 20,
                marginLeft: 10,
                whiteSpace: 'normal',
              }}
            >
              OR
            </Text>
          </div>

          <Formik
            initialValues={formValues}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              if (values.credit_amount)
                setCreditAmount(Number(values.credit_amount));

              setSubmitting(false);
              resetForm();
              setFinalModal(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      marginBottom: 10,
                    }}
                  >
                    <Text
                      inlineStyle={{
                        display: 'flex',
                        fontWeight: '600',
                        fontSize: 20,
                        marginLeft: 10,
                        whiteSpace: 'normal',
                      }}
                    >
                      Credit $ Amount
                    </Text>
                    <AlignmentContainer>
                      <Input
                        id="credit_amount"
                        name="credit_amount"
                        onChange={handleChange}
                        value={values.credit_amount}
                        onBlur={handleBlur}
                        error={
                          errors.message && touched.message && errors.message
                        }
                      />
                    </AlignmentContainer>
                  </div>

                  <Spacer size={18} />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: '#00001f',
                      padding: 20,
                      borderRadius: 5,
                    }}
                  >
                    <Text
                      inlineStyle={{
                        display: 'flex',
                        fontWeight: '500',
                        fontSize: 18,
                        marginLeft: 10,
                        whiteSpace: 'normal',
                        color: '#fff',
                      }}
                    >
                      Credit Estimate
                    </Text>
                    <Text
                      inlineStyle={{
                        display: 'flex',
                        fontWeight: '600',
                        fontSize: 20,
                        marginRight: 10,
                        whiteSpace: 'normal',
                        color: '#fa4616',
                      }}
                    >
                      {values.credit_amount && values.credit_amount !== ''
                        ? '$' + values.credit_amount
                        : estimate ?? '0.00'}
                    </Text>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      marginTop: 30,
                      marginBottom: 10,
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={
                        isSubmitting || (!values?.credit_amount && !estimate)
                      }
                    >
                      Continue
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </>
      ) : null}
    </ModalContainer>
  ) : (
    <ModalContainer>
      <Header>
        <Text type="heading">Issue Credit</Text>
        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </Header>
      <AlignmentContainer
        style={{
          width: '80%',
          margin: 'auto',
        }}
      >
        <Text
          inlineStyle={{
            fontWeight: '600',
            fontSize: 22,
            whiteSpace: 'normal',
          }}
        >
          Include Personalized message
        </Text>
        <Spacer size={20} />
        <Input
          inputStyle={{ height: 200, fontSize: 18 }}
          id="credit_message"
          as="textarea"
          onChange={handleMessageChange}
        />
        <Spacer size={20} />
        <AlignmentContainer style={{ margin: 'auto', width: '95%' }}>
          <Text
            inlineStyle={{
              fontWeight: '600',
              fontSize: 20,
              whiteSpace: 'normal',
              lineHeight: 1.2,
            }}
          >
            A text Message will be sent notifying them of their credit, with
            instructions on how to use the credit.
          </Text>
        </AlignmentContainer>
      </AlignmentContainer>
      <Spacer size={20} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          marginTop: '10px 0',
        }}
      >
        <Button
          disabled={disabled}
          onClick={async () => {
            setDisabled(true);
            let passes;
            try {
              passes = selectedPeople
                .map((person) => person.tickets.map((ticket) => ticket.id))
                .flat();

              const response = await createTransactionCredit(
                currentTransaction.id,
                passes,
                creditAmount,
                includeFees,
                creditMessage ?? ''
              );
              if (!response || response.errors) {
                toast.error('Credit could not be issued');
                return;
              }

              if (response.data.createTransactionCredit) {
                toast.success('Credit has been issued successfully');
                close();
              } else {
                toast.error('Credit could not be issued');
              }
            } catch (e) {}
          }}
        >
          Continue
        </Button>
      </div>
    </ModalContainer>
  );
};
