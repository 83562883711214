import React from 'react';
import styled from 'styled-components';
import Text from 'components/Text';

const DateCardWrapper = styled.div`
  border-radius: 5px;
  padding: ${(props) => (props.small ? '4px' : '8px')};
  height: ${(props) => (props.isCartList ? '38px' : '')};
  background-color: ${(props) => props.theme.colors.primary};
  width: ${(props) =>
    props.isCartList || props.isAnnual
      ? '156px'
      : props.horizontal
      ? '95px'
      : props.small
      ? '50px'
      : '60px'};
  text-align: center;
  ${(props) => (props.margin ? props.margin : 'margin: 0 10px 10px 0')};
  text-transform: uppercase;
  display: ${(props) => (props.horizontal ? 'flex' : 'block')};
  flex-direction: ${(props) => (props.horizontal ? 'row' : null)};
  justify-content: ${(props) => (props.isCartList ? 'start' : 'space-between')};
`;

const DateDay = styled.div`
  background-color: white;
  border-radius: 3px;
  padding: 0px 5px;
  text-align: center;
  display: ${(props) => (props.isCartList ? 'flex' : 'inline-block')};
  margin-bottom: 2px;
  height: ${(props) => (props.isCartList ? '36px' : '')};
  width: ${(props) => (props.isCartList ? '72px' : '')};
  align-items: center;
  justify-content: center;

  //styleName: PP - H4;
  font-family: Barlow Condensed;
  font-size: ${(props) => (props.isCartList ? '24px' : '20px')};
  font-weight: ${(props) => (props.isCartList ? 700 : 600)};
  line-height: ${(props) => (props.isCartList ? '34px' : '20px')};
  text-align: center;
  color: #000033;
`;

const DateListContainer = styled.div`
  display: ${(props) => (props.isCartList ? 'flex' : 'inline')};
  justify-content: ${(props) => (props.isCartList ? 'center' : 'center')};
  align-items: center;
  max-height: ${(props) => (props.isCartList ? '36px' : '')};
  padding-left: ${(props) => (props.isCartList ? '10px' : '5px')};

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const DateCard = ({
  item,
  small,
  margin,
  isAnnual = false,
  horizontal,
  isCartList,
}) => {
  return (
    <DateCardWrapper
      small={small}
      margin={margin}
      isAnnual={isAnnual}
      horizontal={horizontal}
      isCartList={isCartList}
    >
      {!isAnnual && (
        <DateDay isCartList={isCartList}>
          <Text
            type="heading"
            fontSize={small ? 14 : 20}
            lineHeight={small ? 14 : 20}
            color="black"
            fontWeight={700}
          >
            {item.isMultiDay && item.listDates ? item.month : item.day}
          </Text>
        </DateDay>
      )}
      <DateListContainer isCartList={isCartList}>
        <Text
          type="heading"
          fontSize={small ? 14 : 20}
          lineHeight={small ? 14 : 20}
          color="white"
          fontWeight={700}
          style={{ whiteSpace: 'nowrap' }}
        >
          {item.isMultiDay && item.listDates ? item.listDates : item.date}
        </Text>
      </DateListContainer>
    </DateCardWrapper>
  );
};
