import moment from 'moment';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import Text from 'components/Text';
import { TransactionModal } from 'components/Transactions';
import { formatTimestamp } from 'shared/formatters';
import { IssueCreditModal } from '../Credits/issueCreditModal';
import { CreditButton } from '../Credits/RedeemedCredits/RedeemedCredits';
import ApproveUser from '../NewUsers/ApproveUser/ApproveUser';
import GetUserTransaction from './gql/queries/get-user-transaction';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const OpenModal = styled.button`
  color: #fa4616;
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-family: 'Roboto';
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const Transactions = (props) => {
  const { data } = props;
  const [showModal, setShowModal] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));
  const theme = useTheme();
  const location = useLocation();

  const history = useHistory();

  const [showIssueCredit, setShowIssueCredit] = useState(false);

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  const openTransactionDetail = (transactionId) => {
    history.push(`/${userType}/transactions/detail/${transactionId}`);
  };

  if (!data.getUserTransactions) return false;

  return (
    <>
      <Container>
        <ContainerHeader>
          <TitleContainer>
            <Text
              type="heading"
              color="#3C4144"
              inlineStyle={{ marginRight: 25, width: 400 }}
            >
              Transaction History
            </Text>
          </TitleContainer>
          <CreditButton>
            <Button
              onClick={() => setShowIssueCredit(true)}
              buttonStyle={{ height: 35 }}
            >
              {'Issue Credit'}
            </Button>
          </CreditButton>
        </ContainerHeader>
        <div style={{ padding: 20 }}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col">Date</TableCell>
                <TableCell scope="col">Event Date</TableCell>
                <TableCell scope="col">Account</TableCell>
                <TableCell scope="col">Track Name</TableCell>
                <TableCell scope="col">Total</TableCell>
                <TableCell scope="col">Fee</TableCell>
                <TableCell scope="col">Discount</TableCell>
                <TableCell scope="col">Refund</TableCell>
                <TableCell scope="col">Credit</TableCell>

                <TableCell scope="col" />
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.getUserTransactions.results.length ? (
                data.getUserTransactions.results.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <LineHeightText>
                          {formatTimestamp(
                            Number(item.purchase_date),
                            'h:mm a, MMM DD - YYYY',
                            true
                          )}
                        </LineHeightText>
                      </TableCell>
                      <TableCell>
                        {item?.event?.id ? (
                          <Link
                            to={`/admin/track/${item.track?.id}/events/${
                              item.event?.id
                            }/participants?date=${moment(
                              item.event?.start_date
                            ).format('YYYY-MM-DD')}`}
                            style={{
                              color: theme.colors.primary,
                            }}
                          >
                            <LineHeightText>
                              {moment(
                                item?.event?.start_date,
                                'MM-DD-YYYY'
                              ).format('MMM DD - YYYY')}
                            </LineHeightText>
                          </Link>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>
                        {item.account?.business_dba?.length > 40
                          ? item.account?.business_dba?.substring(0, 40) + '...'
                          : item.account?.business_dba}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/admin/track/events/${item.track?.id}`}
                          style={{
                            color: theme.colors.primary,
                          }}
                        >
                          <LineHeightText>{item.track?.name}</LineHeightText>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <OpenModal
                          onClick={() => openTransactionDetail(item.id)}
                        >
                          {item.total}
                        </OpenModal>
                      </TableCell>
                      <TableCell>{item.fee}</TableCell>
                      <TableCell>{item.discount}</TableCell>
                      <TableCell>
                        {item.refund ? item.refund : '$0.00'}
                      </TableCell>
                      <TableCell>{item.credit}</TableCell>

                      <TableCell></TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <p style={{ marginTop: 20 }}>No transactions for this user</p>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {showModal ? (
          <TransactionModal
            isUserTransaction={true}
            currentTransaction={showModal}
            close={() => setShowModal(null)}
            admin={location.pathname.includes('/admin/users/transactions/')}
          />
        ) : null}

        <Pagination
          count={props.data.getUserTransactions.count}
          perPage={15}
          currentPage={currentPage || 1}
        />

        <ApproveUser
          match={props.match}
          location={props.location}
          hideButtons={true}
        />
      </Container>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={false}
        vieEmployeeSearch={false}
        customerId={data.variables.input.user_id ?? ''}
      />
    </>
  );
};

export default GetUserTransaction(Transactions);
