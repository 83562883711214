import moment from 'moment';
import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import { DraggableRow, getOrderedItems } from 'components/Form/DraggableFields';
import { Label, LabelWrapper } from 'components/Form/styles';
import { ResponsiveCol } from 'pages/app/Events/AddEvents/styles';
import {
  InputWrapper,
  OrderText,
} from 'pages/app/Events/EventEdit/EditEventTickets';

const AnnualTicketOrder = ({ values, handleSort }) => {
  const annualTicketsWithOrderNumbers = getOrderedItems(
    values.annual_tickets || []
  );
  return (
    <div>
      {annualTicketsWithOrderNumbers?.length > 0 && (
        <ResponsiveCol>
          <LabelWrapper style={{ marginLeft: -20 }}>
            <Label>{'Annual Ticket Order In App'}</Label>
          </LabelWrapper>
          <ReactSortable
            list={annualTicketsWithOrderNumbers}
            setList={(values) => handleSort('annual_tickets', values)}
          >
            {annualTicketsWithOrderNumbers.map((ticket) => (
              <DraggableRow key={ticket.order}>
                <InputWrapper
                  style={{
                    width: '100%',
                    borderRight: `15px solid ${ticket.color_code}`,
                  }}
                >
                  <OrderText>
                    {ticket.name} -{' '}
                    <span style={{ fontFamily: 'Roboto' }}>
                      {' '}
                      ${ticket.price}
                      {ticket.start_date
                        ? ` - ${moment(ticket.start_date, 'MM-DD-YYYY').format(
                            'MM/DD/YYYY'
                          )}`
                        : ''}
                      {ticket.end_date
                        ? ` - ${moment(ticket.end_date, 'MM-DD-YYYY').format(
                            'MM/DD/YYYY'
                          )}`
                        : ''}
                    </span>
                  </OrderText>
                </InputWrapper>
              </DraggableRow>
            ))}
          </ReactSortable>
        </ResponsiveCol>
      )}
    </div>
  );
};

export default AnnualTicketOrder;
