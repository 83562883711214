import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
// components
import { Faqs } from 'components/Faqs';
import { ParticipantsListMobile } from 'components/Participants';
import { EventParticipantDetail } from 'components/Participants/EventParticipantDetail';
import { PrintParticipants } from 'components/Participants/PrintParticipants';
import { TicketsListMobile } from 'components/Participants/TicketsListMobile';
import {
  RegistrationsDesktop,
  RegistrationsMobile,
} from 'components/Registrations';
import ThemeProvider from 'components/ThemeProvider';
import { TicketDetails } from 'components/Transactions/TransactionModal/TicketDetails';
import useWindowSize from 'hooks/useWindowSize';
// app admin
import Account from 'pages/app/Account';
import Accounts from 'pages/app/Accounts';
import AddAccounts from 'pages/app/Accounts/AddAccounts/AddAccounts';
import AddAnnualTickets from 'pages/app/AnnualTickets/AddAnnualTickets/AddAnnualTickets';
import AnnualDetail from 'pages/app/AnnualTickets/Annuals/MemberDetail/MemberDetail';
import AnnualsAccount from 'pages/app/AnnualTickets/Annuals/MembersAccount';
import AnnualsList from 'pages/app/AnnualTickets/Annuals/MembersList';
import AnnualsListMobile from 'pages/app/AnnualTickets/Annuals/MembersMobile/MembersListMobile';
import AnnualTickets from 'pages/app/AnnualTickets/AnnualTickets';
import AnnualCategories from 'pages/app/AnnualTickets/Categories/AnnualCategories';
import TransferMembership from 'pages/app/AnnualTickets/Members/MemberDetail/Components/TransferMembership';
import MemberDetail from 'pages/app/AnnualTickets/Members/MemberDetail/MemberDetail';
import MembersAccount from 'pages/app/AnnualTickets/Members/MembersAccount';
import MembersList from 'pages/app/AnnualTickets/Members/MembersList';
import MembersListMobile from 'pages/app/AnnualTickets/Members/MembersMobile/MembersListMobile';
import AnnualPurchases from 'pages/app/AnnualTickets/Purchases/AnnualPurchases';
import PurchaseList from 'pages/app/AnnualTickets/Purchases/PurchaseList';
import AnnualPurchaseMobile from 'pages/app/AnnualTickets/Purchases/PurchaseMobile/AnnualPurchaseMobile';
import PurchaseListMobile from 'pages/app/AnnualTickets/Purchases/PurchaseMobile/PurchaseListMobile';
import CartPage from 'pages/app/Cart';
import Categories from 'pages/app/Categories/Categories';
import Credits from 'pages/app/Credits';
import CreditsMobile from 'pages/app/Credits/CreditMobile/CreditsMobile';
import ExpiredCreditDetailMobile from 'pages/app/Credits/CreditMobile/ExpiredCreditsMobile/ExpiredCreditDetailMobile';
import ExpiredCreditMobile from 'pages/app/Credits/CreditMobile/ExpiredCreditsMobile/ExpiredCreditMobile';
import FinancialsMobile from 'pages/app/Credits/CreditMobile/FinancialsMobile/FinancialsMobile';
import OpenCreditsDetailMobile from 'pages/app/Credits/CreditMobile/OpenCreditMobile/OpenCreditsDetailMobile';
import OpenCreditsMobile from 'pages/app/Credits/CreditMobile/OpenCreditMobile/OpenCreditsMobile';
import RedeemedCreditDetailMobile from 'pages/app/Credits/CreditMobile/RedeemedCreditsMobile/RedeemedCreditDetailMobile';
import RedeemedCreditMobile from 'pages/app/Credits/CreditMobile/RedeemedCreditsMobile/RedeemedCreditMobile';
import ExpiredCredits from 'pages/app/Credits/ExpiredCredits/ExpiredCredits';
import ExpiredCreditsDetail from 'pages/app/Credits/ExpiredCredits/ExpiredCreditsDetail';
import Financials from 'pages/app/Credits/Financials/Financials';
import OpenCredits from 'pages/app/Credits/OpenCredits/OpenCredits';
import OpenCreditsDetail from 'pages/app/Credits/OpenCredits/OpenCreditsDetail';
import RedeemedCredits from 'pages/app/Credits/RedeemedCredits/RedeemedCredits';
import RedeemedCreditsDetail from 'pages/app/Credits/RedeemedCredits/RedeemedCreditsDetail';
import { Events } from 'pages/app/Events';
import EventDetails from 'pages/app/Events/EventDetails';
import { EventsMenu } from 'pages/app/Events/EventsMenu';
import { RefundEventFlow } from 'pages/app/Events/EventsMenu/components/RefundEventFlow';
import { ReservedSeats } from 'pages/app/Events/EventsMenu/components/ReservedSeats';
import { EventsReport } from 'pages/app/Events/EventsReport';
import AppReport from 'pages/app/Events/EventsReport/Reports/AppReport';
import CashReport from 'pages/app/Events/EventsReport/Reports/CashReport';
import { LapSponsorshipReport } from 'pages/app/Events/EventsReport/Reports/LapSponsorshipReport';
import PosCardReport from 'pages/app/Events/EventsReport/Reports/PosCardReport';
import PosCashReport from 'pages/app/Events/EventsReport/Reports/PosCashReport';
import TicketBreakdownReport from 'pages/app/Events/EventsReport/Reports/TicketBreakdownReport';
import MobileMainAdminEvents from 'pages/app/Events/MobileEvents';
import { FaqsAdmin } from 'pages/app/Faqs';
import Infos from 'pages/app/Infos';
import ApprovedUsers from 'pages/app/NewUsers/ApprovedUsers/ApprovedUsers';
import ApproveUser from 'pages/app/NewUsers/ApproveUser/ApproveUser';
import TemporaryUsers from 'pages/app/NewUsers/TemporaryUsers/TemporaryUsers';
import Notifications from 'pages/app/Notifications';
import Participants from 'pages/app/Participants';
import Promos from 'pages/app/Promos';
import PromoDetail from 'pages/app/Promos/PromoDetail';
import {
  RegistrationsEventsDesktop,
  RegistrationsEventsMobile,
} from 'pages/app/Registrations';
import { Reports } from 'pages/app/Reports';
import Series from 'pages/app/Series';
import SeriesEdit from 'pages/app/Series/EditSeries';
import SeriesTypes from 'pages/app/SeriesTypes';
import { StripeRedirect } from 'pages/app/Stripe';
import TextLists from 'pages/app/TextLists';
import Tickets from 'pages/app/Tickets';
import Tracks from 'pages/app/Tracks';
import { TrackEdit } from 'pages/app/Tracks/TrackEdit';
import TrackTypes from 'pages/app/TrackTypes';
import TransactionsEvents from 'pages/app/Transactions/';
import MobileAdminTransactions from 'pages/app/Transactions/mobileTransactions';
import TransactionDetail from 'pages/app/Transactions/TransactionDetail';
import Transactions from 'pages/app/Transactions/transactionsList';
import Users from 'pages/app/Users';
import UserTransactions from 'pages/app/Users/transactions';
import UserEdit from 'pages/app/Users/UserEdit';
import { Videos } from 'pages/app/Videos';
import Waivers from 'pages/app/Waivers';
// auth
import Auth from 'pages/Auth';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import PromoterSignup from 'pages/Auth/PromoterSignup';
import SetPassword from 'pages/Auth/SetPassword';
import TrackLogIn from 'pages/Auth/track-log-in';
import VerifyPassword from 'pages/Auth/VerifyPassword';
// employee admin
import EventsEmployee from 'pages/employee/Events';
import ScanEmployee from 'pages/employee/Scan';
// track admin
import TrackContact from 'pages/track/Contact';
import TrackFinancials from 'pages/track/CreditTrack/Financials/TrackFinancials';
import TrackExpiredCredits from 'pages/track/CreditTrack/TrackExpiredCredits/TrackExpiredCredits';
import TrackExpiredCreditsDetail from 'pages/track/CreditTrack/TrackExpiredCredits/TrackExpiredCreditsDetail';
import TrackOpenCredits from 'pages/track/CreditTrack/TrackOpenCredit/TrackOpenCredits';
import TrackOpenCreditsDetail from 'pages/track/CreditTrack/TrackOpenCredit/TrackOpenCreditsDetail';
import TrackRedeemedCredits from 'pages/track/CreditTrack/TrackRedeemedCredits/TrackRedeemedCredits';
import TrackRedeemedCreditsDetail from 'pages/track/CreditTrack/TrackRedeemedCredits/TrackRedeemedCreditsDetail';
import TrackExpiredCreditDetailMobile from 'pages/track/CreditTrackMobile/TrackExpiredCreditsMobile/TrackExpiredCreditDetailMobile';
import TrackExpiredCreditMobile from 'pages/track/CreditTrackMobile/TrackExpiredCreditsMobile/TrackExpiredCreditMobile';
import TrackFinancialsMobile from 'pages/track/CreditTrackMobile/TrackFinancialsMobile/TrackFinancialsMobile';
import TrackOpenCreditDetailMobile from 'pages/track/CreditTrackMobile/TrackOpenCreditMobile/TrackOpenCreditDetailMobile';
import TrackOpenCreditMobile from 'pages/track/CreditTrackMobile/TrackOpenCreditMobile/TrackOpenCreditMobile';
import TrackRedeemedCreditDetailMobile from 'pages/track/CreditTrackMobile/TrackRedeemedCreditsMobile/TrackRedeemedCreditDetailMobile';
import TrackRedeemedCreditMobile from 'pages/track/CreditTrackMobile/TrackRedeemedCreditsMobile/TrackRedeemedCreditMobile';
import Customers from 'pages/track/Customers';
import CustomerDetail from 'pages/track/Customers/CustomerDetail';
import CustomersListMobile from 'pages/track/Customers/CustomersMobile';
import EmployeesTrack from 'pages/track/Employees';
import EmployeeDetail from 'pages/track/Employees/EmployeeDetail';
import { EmployeeEdit } from 'pages/track/Employees/EmployeeEdit';
import EmployeeList from 'pages/track/Employees/EmployeeMobile/employeeList';
import EventsTrack from 'pages/track/Events/Page';
import { GuestListDesktop } from 'pages/track/GuestList/EventGuestList/Desktop';
import { GuestListMobile } from 'pages/track/GuestList/EventGuestList/Mobile';
import GuestEvents from 'pages/track/GuestList/GuestListEvents';
import GuestEventsMobile from 'pages/track/GuestList/GuestListEventsMobile';
import { Guests } from 'pages/track/Guests';
import { GuestDetail } from 'pages/track/Guests/GuestDetail';
import { TrackHome } from 'pages/track/Home';
import MobileTransactions from 'pages/track/MobileTransactions';
import { MobileTransactionsList } from 'pages/track/MobileTransactions/transactionsList';
import TrackNotifications from 'pages/track/Notifications';
import {
  TrackRegistrationsEventsDesktop,
  TrackRegistrationsEventsMobile,
} from 'pages/track/Registrations';
import { SetTrackPassword } from 'pages/track/SignUp';
import { SetStripe } from 'pages/track/SignUp/setStripe';
import TrackTransactionsEvents from 'pages/track/Transactions';
import TrackVideos from 'pages/track/Videos';
import TrackVideosMobile from 'pages/track/Videos/videosMobile';
import TrackWeather from 'pages/track/Weather';
import { AppContext } from 'shared/AppContext';
import Apollo from '../apollo';
// layouts
import AddEvents from '../pages/app/Events/AddEvents';
import AdminRoute from './AdminRoute';
import AuthRoute from './AuthRoute';
import EmployeeRoute from './EmployeeRoute';
import TrackAdminRoute from './TrackAdminRoute';

const AppRouter = () => {
  const [width] = useWindowSize();
  const { state } = useContext(AppContext);

  const GlobalStyle = createGlobalStyle`
body {
  background-color: ${(props) =>
    'light' === state.theme
      ? 768 > width
        ? props.theme.colors.primary
        : props.theme.colors.secondary
      : props.theme.colors.secondary};
}
`;

  return (
    <Apollo>
      <ThemeProvider>
        <Router>
          <Switch>
            {/* Auth */}
            <AuthRoute exact path="/" component={Auth} />
            <AuthRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <AuthRoute
              exact
              path="/promoter-signup"
              component={PromoterSignup}
            />
            <AuthRoute
              exact
              path="/verify-password"
              component={VerifyPassword}
            />
            <AuthRoute path="/stripe" component={SetStripe} />
            <AuthRoute
              path="/track-sign-up"
              exact
              component={SetTrackPassword}
            />
            <AuthRoute path="/track-log-in" exact component={TrackLogIn} />
            <AuthRoute
              backgroundColor="#fff"
              exact
              path="/set-password"
              component={SetPassword}
            />
            {/* Super Admin */}
            <AdminRoute exact path="/admin/settings" component={Account} />
            <AdminRoute
              exact
              path="/admin/events/addEvent"
              component={AddEvents}
            />
            <AdminRoute
              exact
              path="/admin/events/editEvent/:id"
              component={AddEvents}
            />
            <AdminRoute
              exact
              path="/admin/track/events/:id"
              component={(props) =>
                width > 860 ? (
                  <Events {...props} />
                ) : (
                  <MobileMainAdminEvents {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/annualTickets"
              component={AnnualTickets}
            />
            <AdminRoute
              exact
              path="/admin/annualcategories"
              component={AnnualCategories}
            />
            <AdminRoute
              exact
              path="/admin/annualTickets/add"
              component={AddAnnualTickets}
            />
            <AdminRoute
              exact
              path="/admin/editAnnualTicket/:id"
              component={AddAnnualTickets}
            />
            <AdminRoute
              exact
              path="/admin/annualPurchases"
              component={AnnualPurchases}
            />
            <AdminRoute
              exact
              path="/admin/annualPurchases/purchase/:id"
              component={(props) =>
                width > 860 ? (
                  <PurchaseList {...props} />
                ) : (
                  <PurchaseListMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/annualMembersAccount"
              component={MembersAccount}
            />
            <AdminRoute
              exact
              path="/admin/annualMembers/:id/:name"
              component={(props) =>
                width > 860 ? (
                  <MembersList {...props} />
                ) : (
                  <MembersListMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/annualMembersDetail/member/:id"
              component={MemberDetail}
            />
            <AdminRoute
              exact
              path="/admin/transferMembership/:id"
              component={TransferMembership}
            />
            <AdminRoute
              exact
              path="/admin/annualsAccount"
              component={AnnualsAccount}
            />
            <AdminRoute
              exact
              path="/admin/annualUsers/:id/:name"
              component={(props) =>
                width > 860 ? (
                  <AnnualsList {...props} />
                ) : (
                  <AnnualsListMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/annualsDetail/member/:id"
              component={AnnualDetail}
            />
            <AdminRoute
              exact
              path="/admin/series/events/:id"
              component={(props) =>
                width > 860 ? (
                  <Events {...props} />
                ) : (
                  <MobileMainAdminEvents {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/track/:track_id/events/:id/participants"
              component={
                process.env.REACT_APP_PLATFORM !== 'tickethoss'
                  ? Participants
                  : Tickets
              }
            />
            <AdminRoute
              exact
              path="/admin/track/:track_id/events/:id/participants/:userId"
              component={(props) =>
                process.env.REACT_APP_PLATFORM !== 'tickethoss' ? (
                  <EventParticipantDetail {...props} />
                ) : (
                  <TicketDetails {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/series/:track_id/events/:id/participants/:userId"
              component={(props) =>
                process.env.REACT_APP_PLATFORM !== 'tickethoss' ? (
                  <EventParticipantDetail {...props} />
                ) : (
                  <TicketDetails {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/series/:track_id/events/:id/participants"
              component={(props) =>
                process.env.REACT_APP_PLATFORM !== 'tickethoss' ? (
                  width > 860 ? (
                    <Participants {...props} />
                  ) : (
                    <ParticipantsListMobile {...props} />
                  )
                ) : width > 860 ? (
                  <Tickets {...props} />
                ) : (
                  <TicketsListMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/events/"
              component={(props) =>
                width > 860 ? (
                  <Events {...props} />
                ) : (
                  <MobileMainAdminEvents {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/events/:id"
              component={EventDetails}
            />
            <AdminRoute exact path="/admin/faqs" component={FaqsAdmin} />
            <AdminRoute exact path="/admin/infos" component={Infos} />
            <AdminRoute exact path="/admin/categories" component={Categories} />
            <AdminRoute
              exact
              path="/admin/notifications"
              component={Notifications}
            />
            <AdminRoute exact path="/admin/promos" component={Promos} />
            <AdminRoute
              exact
              path="/admin/promos/:promo_id"
              component={PromoDetail}
            />
            <AdminRoute exact path="/admin/reports" component={Reports} />
            <AdminRoute exact path="/admin/series" component={Series} />
            <AdminRoute exact path="/admin/tracks" component={Tracks} />
            <AdminRoute
              exact
              path="/admin/tracks/editTrack/:id"
              component={TrackEdit}
            />
            <AdminRoute
              exact
              path="/admin/tracks/addTrack"
              component={TrackEdit}
            />
            <AdminRoute
              exact
              path="/admin/series/editSeries/:id"
              component={SeriesEdit}
            />
            <AdminRoute
              exact
              path="/admin/series/addSeries"
              component={SeriesEdit}
            />
            <AdminRoute exact path="/admin/accounts" component={Accounts} />
            <AdminRoute
              exact
              path="/admin/accounts/addAccount"
              component={AddAccounts}
            />
            <AdminRoute
              exact
              path="/admin/accounts/editAccount/:id"
              component={AddAccounts}
            />
            <AdminRoute exact path="/admin/users" component={Users} />
            <AdminRoute
              exact
              path="/admin/temporaryUsers"
              component={TemporaryUsers}
            />
            <AdminRoute
              exact
              path="/admin/temporaryUsers/:id"
              component={ApproveUser}
            />
            <AdminRoute
              exact
              path="/admin/approvedUsers"
              component={ApprovedUsers}
            />
            <AdminRoute
              exact
              path="/admin/users/transactions/:id"
              component={UserTransactions}
            />
            <AdminRoute
              exact
              path="/admin/transactions/detail/:id"
              component={TransactionDetail}
            />
            <AdminRoute
              exact
              path="/admin/users/addUser"
              component={UserEdit}
            />
            <AdminRoute
              exact
              path="/admin/users/editUser/:id"
              component={UserEdit}
            />
            <AdminRoute exact path="/admin/videos" component={Videos} />
            <AdminRoute exact path="/admin/stripe" component={StripeRedirect} />
            <AdminRoute
              exact
              path="/admin/track-types"
              component={TrackTypes}
            />
            <AdminRoute
              exact
              path="/admin/series-types"
              component={SeriesTypes}
            />
            <AdminRoute exact path="/admin/text-lists" component={TextLists} />
            <AdminRoute
              exact
              path="/admin/openCredits"
              component={(props) =>
                width > 860 ? (
                  <Credits {...props} />
                ) : (
                  <CreditsMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/redeemedCredits"
              component={(props) =>
                width > 860 ? (
                  <Credits {...props} />
                ) : (
                  <CreditsMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/expiredCredits"
              component={(props) =>
                width > 860 ? (
                  <Credits {...props} />
                ) : (
                  <CreditsMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/financials"
              component={(props) =>
                width > 860 ? (
                  <Credits {...props} />
                ) : (
                  <CreditsMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/openCredits/:id"
              component={(props) =>
                width > 860 ? (
                  <OpenCredits {...props} />
                ) : (
                  <OpenCreditsMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/openCredits/:id/creditDetail/:id2"
              component={(props) =>
                width > 860 ? (
                  <OpenCreditsDetail {...props} />
                ) : (
                  <OpenCreditsDetailMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/redeemedCredits/:id"
              component={(props) =>
                width > 860 ? (
                  <RedeemedCredits {...props} />
                ) : (
                  <RedeemedCreditMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/expiredCredits/:id"
              component={(props) =>
                width > 860 ? (
                  <ExpiredCredits {...props} />
                ) : (
                  <ExpiredCreditMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/redeemedCredits/:id/creditDetail/:id2"
              component={(props) =>
                width > 860 ? (
                  <RedeemedCreditsDetail {...props} />
                ) : (
                  <RedeemedCreditDetailMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/expiredCredits/:id/creditDetail/:id2"
              component={(props) =>
                width > 860 ? (
                  <ExpiredCreditsDetail {...props} />
                ) : (
                  <ExpiredCreditDetailMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/financials/:id"
              component={(props) =>
                width > 860 ? (
                  <Financials {...props} />
                ) : (
                  <FinancialsMobile {...props} />
                )
              }
            />
            <AdminRoute exact path="/admin/waivers" component={Waivers} />
            <AdminRoute exact path="/admin/scan/:id" component={ScanEmployee} />
            <AdminRoute
              exact
              path="/admin/transactions"
              component={(props) =>
                width > 860 ? (
                  <TransactionsEvents {...props} />
                ) : (
                  <MobileAdminTransactions {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/transactions/event/:id"
              component={(props) =>
                width > 860 ? (
                  <Transactions {...props} />
                ) : (
                  <MobileTransactionsList {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/registrations"
              component={(props) =>
                width > 860 ? (
                  <RegistrationsEventsDesktop {...props} />
                ) : (
                  <RegistrationsEventsMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/registrations/event/:id"
              component={(props) =>
                width > 860 ? (
                  <RegistrationsDesktop {...props} />
                ) : (
                  <RegistrationsMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/registrationDetail/registration/:id"
              component={MemberDetail}
            />
            <AdminRoute exact path="/admin/cart/:id" component={CartPage} />
            <AdminRoute
              exact
              path="/admin/events/menu/:id"
              component={(props) => <EventsMenu {...props} />}
            />
            <AdminRoute
              exact
              path="/admin/events/reports/:id"
              component={(props) => <EventsReport {...props} />}
            />
            <AdminRoute
              exact
              path="/admin/events/reports/cash-report/:id"
              component={(props) => <CashReport {...props} />}
            />
            <AdminRoute
              exact
              path="/admin/events/refund/:id"
              component={(props) => (
                <RefundEventFlow {...props} isVisible={true} />
              )}
            />
            <AdminRoute
              exact
              path="/admin/events/reservedSeats/:id"
              component={(props) => <ReservedSeats {...props} />}
            />
            <AdminRoute
              exact
              path="/admin/events/reports/spectatorsList/:id"
              component={(props) =>
                process.env.REACT_APP_PLATFORM !== 'tickethoss' ? (
                  width > 860 ? (
                    <Participants {...props} />
                  ) : (
                    <ParticipantsListMobile {...props} />
                  )
                ) : width > 860 ? (
                  <Tickets {...props} />
                ) : (
                  <TicketsListMobile {...props} />
                )
              }
            />
            <AdminRoute
              exact
              path="/admin/events/reports/ticketBreakdown/:id"
              component={(props) => <TicketBreakdownReport {...props} />}
            />
            <AdminRoute
              exact
              path="/admin/events/reports/posCashReport/:id"
              component={(props) => <PosCashReport {...props} />}
            />
            <AdminRoute
              exact
              path="/admin/events/reports/posCardReport/:id"
              component={(props) => <PosCardReport {...props} />}
            />
            <AdminRoute
              exact
              path="/admin/events/reports/appReport/:id"
              component={(props) => <AppReport {...props} />}
            />
            <AdminRoute
              exact
              path="/admin/events/reports/lapSponsorshipReport/:id"
              component={(props) => <LapSponsorshipReport {...props} />}
            />
            {/* Track Admin */}
            <TrackAdminRoute
              path="/admin-track/stripe"
              component={StripeRedirect}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/home"
              component={TrackHome}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/users/editUser/:id"
              component={UserEdit}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events"
              // component={EventsTrack}
              component={(props) =>
                width > 860 ? (
                  <EventsTrack {...props} />
                ) : (
                  <EventsEmployee {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/guest-lists"
              component={(props) =>
                width > 860 ? (
                  <GuestEvents {...props} />
                ) : (
                  <GuestEventsMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/guest-lists/event/:eventId"
              component={(props) =>
                width > 860 ? (
                  <GuestListDesktop {...props} />
                ) : (
                  <GuestListMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/guest-lists/event/:eventId/guest/:guestId"
              component={(props) =>
                width > 860 ? (
                  <GuestDetail {...props} />
                ) : (
                  <GuestDetail {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/guests"
              component={(props) => <Guests {...props} />}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/guests/:guestId"
              component={GuestDetail}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/track/:track_id/events/:id/participants"
              component={(props) =>
                process.env.REACT_APP_PLATFORM !== 'tickethoss' ? (
                  width > 860 ? (
                    <Participants {...props} />
                  ) : (
                    <ParticipantsListMobile {...props} />
                  )
                ) : width > 860 ? (
                  <Tickets {...props} />
                ) : (
                  <TicketsListMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/track/:track_id/events/:id/participants/:userId"
              component={(props) =>
                process.env.REACT_APP_PLATFORM !== 'tickethoss' ? (
                  <EventParticipantDetail {...props} />
                ) : (
                  <TicketDetails {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/employees"
              component={(props) =>
                width > 860 ? (
                  <EmployeesTrack {...props} />
                ) : (
                  <EmployeeList {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/employees/addEmployee"
              component={EmployeeEdit}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/employees/editEmployee/:id"
              component={EmployeeEdit}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/employees/:employee_id"
              component={EmployeeDetail}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/promos"
              component={Promos}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/promos/:promo_id"
              component={PromoDetail}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/settings/:tab"
              component={Account}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/scan/:id"
              component={ScanEmployee}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/notifications"
              component={TrackNotifications}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/faqs"
              component={(props) => <Faqs {...props} />}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/weather"
              component={TrackWeather}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/training"
              component={(props) =>
                width > 860 ? (
                  <TrackVideos {...props} />
                ) : (
                  <TrackVideosMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/contact"
              component={TrackContact}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/openCredits"
              component={(props) =>
                width > 860 ? (
                  <TrackOpenCredits {...props} />
                ) : (
                  <TrackOpenCreditMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/redeemedCredits"
              component={(props) =>
                width > 860 ? (
                  <TrackRedeemedCredits {...props} />
                ) : (
                  <TrackRedeemedCreditMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/expiredCredits"
              component={(props) =>
                width > 860 ? (
                  <TrackExpiredCredits {...props} />
                ) : (
                  <TrackExpiredCreditMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/financials"
              component={(props) =>
                width > 860 ? (
                  <TrackFinancials {...props} />
                ) : (
                  <TrackFinancialsMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/openCredits/creditDetail/:id"
              component={(props) =>
                width > 860 ? (
                  <TrackOpenCreditsDetail {...props} />
                ) : (
                  <TrackOpenCreditDetailMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/redeemedCredits/creditDetail/:id"
              component={(props) =>
                width > 860 ? (
                  <TrackRedeemedCreditsDetail {...props} />
                ) : (
                  <TrackRedeemedCreditDetailMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/expiredCredits/creditDetail/:id"
              component={(props) =>
                width > 860 ? (
                  <TrackExpiredCreditsDetail {...props} />
                ) : (
                  <TrackExpiredCreditDetailMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/transactions"
              component={(props) =>
                width > 860 ? (
                  <TrackTransactionsEvents {...props} />
                ) : (
                  <MobileTransactions {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/transactions/detail/:id"
              component={TransactionDetail}
            />
            exact path="/admin/transactions/detail/:id" component=
            {TransactionDetail}
            <TrackAdminRoute
              exact
              path="/admin-track/transactions/event/:id"
              component={(props) =>
                width > 860 ? (
                  <Transactions {...props} />
                ) : (
                  <MobileTransactionsList {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/registrations"
              component={(props) =>
                width > 860 ? (
                  <TrackRegistrationsEventsDesktop {...props} />
                ) : (
                  <TrackRegistrationsEventsMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/registrations/event/:id"
              component={(props) =>
                width > 860 ? (
                  <RegistrationsDesktop {...props} />
                ) : (
                  <RegistrationsMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/users"
              component={(props) =>
                width > 860 ? (
                  <Customers {...props} />
                ) : (
                  <CustomersListMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/users/transactions/:id"
              component={CustomerDetail}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/annualPurchases"
              component={(props) =>
                width > 860 ? (
                  <AnnualPurchases {...props} />
                ) : (
                  <AnnualPurchaseMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/annualPurchases/purchase/:id"
              component={(props) =>
                width > 860 ? (
                  <PurchaseList {...props} />
                ) : (
                  <PurchaseListMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/annualMembers"
              component={(props) =>
                width > 860 ? (
                  <MembersList {...props} />
                ) : (
                  <MembersListMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/annualMembersDetail/member/:id"
              component={MemberDetail}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/transferMembership/:id"
              component={TransferMembership}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/annualUsers"
              component={(props) =>
                width > 860 ? (
                  <AnnualsList {...props} />
                ) : (
                  <AnnualsListMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/annualsDetail/member/:id"
              component={AnnualDetail}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/print"
              render={(props) => <PrintParticipants {...props} />}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/registrationDetail/registration/:id"
              component={MemberDetail}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/cart/:id"
              component={CartPage}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events/menu/:id"
              component={(props) => <EventsMenu {...props} />}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events/reports/:id"
              component={(props) => <EventsReport {...props} />}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events/refund/:id"
              component={(props) => (
                <RefundEventFlow {...props} isVisible={true} />
              )}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events/reservedSeats/:id"
              component={(props) => <ReservedSeats {...props} />}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events/reports/spectatorsList/:id"
              component={(props) =>
                process.env.REACT_APP_PLATFORM !== 'tickethoss' ? (
                  width > 860 ? (
                    <Participants {...props} />
                  ) : (
                    <ParticipantsListMobile {...props} />
                  )
                ) : width > 860 ? (
                  <Tickets {...props} />
                ) : (
                  <TicketsListMobile {...props} />
                )
              }
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events/reports/posCashReport/:id"
              component={(props) => <PosCashReport {...props} />}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events/reports/posCardReport/:id"
              component={(props) => <PosCardReport {...props} />}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events/reports/appReport/:id"
              component={(props) => <AppReport {...props} />}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events/reports/ticketBreakdown/:id"
              component={(props) => <TicketBreakdownReport {...props} />}
            />
            <TrackAdminRoute
              exact
              path="/admin-track/events/reports/lapSponsorshipReport/:id"
              component={(props) => <LapSponsorshipReport {...props} />}
            />
            {/* Employee  Admin */}
            <EmployeeRoute
              exact
              path="/admin-employee/home"
              component={TrackHome}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/users/editUser/:id"
              component={UserEdit}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events"
              // component={EventsTrack}
              component={(props) =>
                width > 860 ? (
                  <EventsTrack {...props} />
                ) : (
                  <EventsEmployee {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/weather"
              component={TrackWeather}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guest-lists"
              component={(props) =>
                width > 860 ? (
                  <GuestEvents {...props} />
                ) : (
                  <GuestEventsMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guest-lists/event/:eventId"
              component={(props) =>
                width > 860 ? (
                  <GuestListDesktop {...props} />
                ) : (
                  <GuestListMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guest-lists/event/:eventId/guest/:guestId"
              component={(props) =>
                width > 860 ? (
                  <GuestDetail {...props} />
                ) : (
                  <GuestDetail {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guests"
              component={(props) => <Guests {...props} />}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guests/:guestId"
              component={GuestDetail}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guest-lists"
              component={GuestEventsMobile}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guest-lists/event/:eventId"
              component={GuestListMobile}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guest-lists/event/:eventId/guest/:guestId"
              component={GuestDetail}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/promos"
              component={Promos}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/openCredits"
              component={(props) =>
                width > 860 ? (
                  <TrackOpenCredits {...props} />
                ) : (
                  <TrackOpenCreditMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/redeemedCredits"
              component={(props) =>
                width > 860 ? (
                  <TrackRedeemedCredits {...props} />
                ) : (
                  <TrackRedeemedCreditMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/financials"
              component={(props) =>
                width > 860 ? (
                  <TrackFinancials {...props} />
                ) : (
                  <TrackFinancialsMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/openCredits/creditDetail/:id"
              component={(props) =>
                width > 860 ? (
                  <TrackOpenCreditsDetail {...props} />
                ) : (
                  <TrackOpenCreditDetailMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/redeemedCredits/creditDetail/:id"
              component={(props) =>
                width > 860 ? (
                  <TrackRedeemedCreditsDetail {...props} />
                ) : (
                  <TrackRedeemedCreditDetailMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guest-lists"
              component={GuestEventsMobile}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guests"
              component={(props) => <Guests {...props} />}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guests/:guestId"
              component={GuestDetail}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guest-lists/event/:eventId"
              component={GuestListMobile}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/guest-lists/event/:eventId/guest/:guestId"
              component={GuestDetail}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/track/:track_id/events/:id/participants"
              component={(props) =>
                process.env.REACT_APP_PLATFORM !== 'tickethoss' ? (
                  width > 860 ? (
                    <Participants {...props} />
                  ) : (
                    <ParticipantsListMobile {...props} />
                  )
                ) : width > 860 ? (
                  <Tickets {...props} />
                ) : (
                  <TicketsListMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/notifications"
              component={TrackNotifications}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/track/:track_id/events/:id/participants/:userId"
              component={(props) =>
                process.env.REACT_APP_PLATFORM !== 'tickethoss' ? (
                  <EventParticipantDetail {...props} />
                ) : (
                  <TicketDetails {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/scan/:id"
              component={ScanEmployee}
            />
            <EmployeeRoute exact path="/admin-employee/faqs" component={Faqs} />
            <EmployeeRoute
              exact
              path="/admin-employee/contact"
              component={TrackContact}
            />
            <EmployeeRoute
              backgroundColor="#fff"
              exact
              path="/admin-employee/settings/:tab"
              component={Account}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/transactions"
              component={(props) =>
                width > 860 ? (
                  <TrackTransactionsEvents {...props} />
                ) : (
                  <MobileTransactions {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/transactions/detail/:id"
              component={TransactionDetail}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/transactions/event/:id"
              component={(props) =>
                width > 860 ? (
                  <Transactions {...props} />
                ) : (
                  <MobileTransactionsList {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/registrations"
              component={(props) =>
                width > 860 ? (
                  <TrackRegistrationsEventsDesktop {...props} />
                ) : (
                  <TrackRegistrationsEventsMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/users"
              component={Customers}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/users/transactions/:id"
              component={CustomerDetail}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/employees"
              component={EmployeesTrack}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/employees/addEmployee"
              component={EmployeeEdit}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/employees/editEmployee/:id"
              component={EmployeeEdit}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/employees/:employee_id"
              component={EmployeeDetail}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/contact"
              component={TrackContact}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/annualPurchases"
              component={AnnualPurchases}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/annualPurchases/purchase/:id"
              component={(props) =>
                width > 860 ? (
                  <PurchaseList {...props} />
                ) : (
                  <PurchaseListMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/annualMembers"
              component={(props) =>
                width > 860 ? (
                  <MembersList {...props} />
                ) : (
                  <MembersListMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/annualsDetail/member/:id"
              component={AnnualDetail}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/annualUsers"
              component={(props) =>
                width > 860 ? (
                  <AnnualsList {...props} />
                ) : (
                  <AnnualsListMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/annualMembersDetail/member/:id"
              component={MemberDetail}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/transferMembership/:id"
              component={TransferMembership}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/registrations"
              component={(props) =>
                width > 860 ? (
                  <TrackRegistrationsEventsDesktop {...props} />
                ) : (
                  <TrackRegistrationsEventsMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/registrations/event/:id"
              component={(props) =>
                width > 860 ? (
                  <RegistrationsDesktop {...props} />
                ) : (
                  <RegistrationsMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/registrationDetail/registration/:id"
              component={MemberDetail}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/cart/:id"
              component={CartPage}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events/menu/:id"
              component={(props) => <EventsMenu {...props} />}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events/reports/:id"
              component={(props) => <EventsReport {...props} />}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events/refund/:id"
              component={(props) => (
                <RefundEventFlow {...props} isVisible={true} />
              )}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events/reservedSeats/:id"
              component={(props) => <ReservedSeats {...props} />}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events/reports/lapSponsorshipReport/:id"
              component={(props) => <LapSponsorshipReport {...props} />}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events/reports/spectatorsList/:id"
              component={(props) =>
                process.env.REACT_APP_PLATFORM !== 'tickethoss' ? (
                  width > 860 ? (
                    <Participants {...props} />
                  ) : (
                    <ParticipantsListMobile {...props} />
                  )
                ) : width > 860 ? (
                  <Tickets {...props} />
                ) : (
                  <TicketsListMobile {...props} />
                )
              }
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events/reports/ticketBreakdown/:id"
              component={(props) => <TicketBreakdownReport {...props} />}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events/reports/posCashReport/:id"
              component={(props) => <PosCashReport {...props} />}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events/reports/posCardReport/:id"
              component={(props) => <PosCardReport {...props} />}
            />
            <EmployeeRoute
              exact
              path="/admin-employee/events/reports/appReport/:id"
              component={(props) => <AppReport {...props} />}
            />
            {/* used as a catch all redirect */}
            <AuthRoute component={Auth} />
          </Switch>
          <GlobalStyle />
        </Router>
      </ThemeProvider>
    </Apollo>
  );
};

export default AppRouter;
