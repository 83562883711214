import React from 'react';
import { DateCard } from 'components/DateCard';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';

export const TransactionsListHeader = ({ event }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: 40,
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DateCard
          item={event.getEvent}
          margin={'margin: 0 10px 0 0'}
          horizontal
        />
        <Text type="heading" fontSize={32} color={theme.colors.secondary}>
          {event.getEvent.name}
        </Text>
        {'postponed' === event.getEvent.status ? (
          <img
            style={{
              width: '20%',
              height: 'auto',
              display: 'block',
              marginLeft: 20,
              transform: 'rotate(10deg)',
            }}
            src="https://d3294qt0f4hbwl.cloudfront.net/postponed.png"
            alt="postponed-stamp"
          />
        ) : null}
        {'cancelled' === event.getEvent.status ? (
          <img
            style={{
              width: '20%',
              height: 'auto',
              display: 'block',
              marginLeft: 20,
            }}
            src="https://d3294qt0f4hbwl.cloudfront.net/cancelled.png"
            alt="cancelled-stamp"
          />
        ) : null}
      </div>
    </div>
  );
};
