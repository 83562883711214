import React, { useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/Button';
import { ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
// import { SearchInput } from 'components/Form/SearchInput';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
// import { useUpdateQueryString } from 'hooks/useSearchInput';
import { TitleContainer } from 'pages/track/Contact/style';
import { IssueCreditModal } from '../issueCreditModal';
import { useGetAccountCreditsBreakdown } from './gql/get-account-credits-breakdown';

export const CreditButton = styled.div`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`;

const Financials = (props) => {
  const [credits, setCredits] = useState();
  const [count, setCount] = useState();
  // const [search, setSearch] = useState('');

  const [showIssueCredit, setShowIssueCredit] = useState(false);

  const { res, loading } = useGetAccountCreditsBreakdown();

  useEffect(() => {
    if (res && res.getAccountCreditsBreakdown) {
      setCredits(res?.getAccountCreditsBreakdown);
      setCount(res?.getAccountCreditsBreakdown.length);
    }
  }, [res]);

  // const updateQueryString = useUpdateQueryString();

  // const handleChange = (e) => setSearch(e.target.value);

  // const handleBlur = (e) => {
  //   updateQueryString(e.target.value);
  // };

  // const handleKeyPress = (e) => {
  //   if (e.keyCode === 13) {
  //     updateQueryString(e.target.value);
  //   }
  // };

  const columns = [
    {
      label: 'Year',
      key: 'year',
    },
    {
      label: 'Total',
      key: 'total',
    },
    {
      label: 'Open',
      key: 'open',
    },
    {
      label: 'Redeemed',
      key: 'redeemed',
    },
    {
      label: 'Expired',
      key: 'expired',
    },
  ];

  const urlParams = new URLSearchParams(window.location.queryString);
  const currentPage = parseInt(urlParams.get('page'));

  function renderRows(credit) {
    const { year, total, open, redeemed, expired } = credit;

    return {
      year: year,
      total: total,
      open: open,
      redeemed: redeemed,
      expired: expired,
    };
  }

  return loading ? (
    <div />
  ) : (
    <>
      <Container>
        <ContainerHeader>
          <ContainerWrapper>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginTop: 10 }}
              >
                Financials
              </Text>
              {/* <div style={{ marginRight: 15, marginTop: 10 }}>
                <SearchInput
                  placeholder="Search Credits"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                />
              </div> */}
            </TitleContainer>
            <CreditButton>
              <Button
                onClick={() => setShowIssueCredit(true)}
                buttonStyle={{ height: 35 }}
              >
                {'Issue Credit'}
              </Button>
            </CreditButton>
          </ContainerWrapper>
        </ContainerHeader>
        <div style={{ padding: 2 }}>{props.children}</div>
        {!credits ? (
          <div />
        ) : (
          <div style={{ padding: 20 }}>
            <Table items={credits} columns={columns} renderRows={renderRows} />
            <Pagination
              count={count}
              perPage={15}
              currentPage={currentPage || 1}
            />
          </div>
        )}
      </Container>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={false}
      />
    </>
  );
};

export default withTheme(Financials);
