import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const EVENT_POS_TRANSACTIONS = gql`
  mutation GetEventPOSCardTransactions($event_id: Int!) {
    getEventPOSCardTransactions(event_id: $event_id) {
      event {
        name
        start_date
        end_date
        fullDate
        isMultiDay
        hide_fee
        marketing_fee
        track {
          name
        }
      }
      num_transactions
      num_tickets
      num_participants
      gross_sales
      net_sales
      marketing_fund
      label
      data {
        type
        total
        data {
          id
          purchaser
          total
          subtotal
          refund
          discount
          credit
          fee
          purchase_date
          ticket_holders {
            name
            tickets {
              name
              price
            }
          }
          participants {
            name
            tickets {
              name
              price
            }
          }
        }
      }
    }
  }
`;

export function useGetCardReport() {
  const [eventCardTransactions] = useMutation(EVENT_POS_TRANSACTIONS);

  const result = useCallback(
    (event_id) => {
      return eventCardTransactions({
        variables: {
          event_id: Number(event_id),
        },
      });
    },
    [eventCardTransactions]
  );
  return result;
}
