import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const GET_EVENT = gql`
  query GetEvent($input: GetEventInput!) {
    getEvent(input: $input) {
      id
      track_id
      image
      image_id
      logo
      logo_id
      name
      start_date
      end_date
      eventDates
      isMultiDay
      listDates
      day
      date
      month
      has_reserved_seats
      seat_events {
        date
        seat_id
      }
      account_id
      seats_id
      pos_settings {
        ticket_delivery
        receipt_delivery
        payment
        calculator
      }
      status
    }
  }
`;

export function useGetEvent(currentEventId) {
  const { id: idParam } = useParams();
  const { pathname } = useLocation();
  const isTrackEventsView = pathname.includes('/admin/track/events');
  const id =
    currentEventId ?? (!isTrackEventsView && idParam ? Number(idParam) : null);

  return useQuery(GET_EVENT, {
    variables: { input: { id } },
    skip: !id,
    fetchPolicy: 'no-cache',
  });
}
