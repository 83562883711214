import { graphql } from '@apollo/react-hoc';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_TRACK = gql`
  query GetTrack($id: Int!) {
    getTrackAdmin(id: $id) {
      id
      image
      logo
      image_id
      twitter_image_id
      facebook_image_id
      facebook_image
      twitter_image
      logo_id
      name
      short_name
      street
      state
      city
      country
      zipcode
      phone
      country_code
      calling_code
      website
      schedule
      added_by
      added_by_user {
        id
        name
      }
      type {
        id
        key
        value
      }
      size
      bio
      twitter
      facebook
      instagram
      youtube
      tiktok
      poc {
        id
      }
      user_id
      faqs {
        id
        question
        answer
      }
      status
      timezone
      fullAddress
      account_id
      admin_infos {
        id
        logo
        logo_id
        link
        text
        info {
          id
          name
        }
        order
        video_url
        image_id
        image_url
        pdf_id
        pdf_url
      }
      url
      promoter_name
      promoter_email
      promoter_phone
      chart_id
    }
    getAllUsers {
      id
      name
      first_name
      middle_name
      last_name
      email
      role
    }
    getTrackTypes {
      id
      value
      key
    }
  }
`;

export function useGetTrackEditData(currentTrack) {
  const { data, loading, error } = useQuery(GET_TRACK, {
    variables: { id: currentTrack ? currentTrack : 0 },
    fetchPolicy: 'no-cache',
    skip: !currentTrack,
  });
  return { data, loading, error };
}

export default graphql(GET_TRACK, {
  options: ({ currentTrack }) => {
    return {
      fetchPolicy: 'cache-and-network',
      variables: { input: { id: currentTrack ? currentTrack : 0 } },
    };
  },
});
