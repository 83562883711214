import moment from 'moment';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import { ApproveModal } from 'components/Modal';
import {
  TableComponent as Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'components/Table';
import Text from 'components/Text';
import { ResponsiveCol } from 'pages/app/Events/AddEvents/styles';
import {
  useApproveMember,
  useUnApproveMember,
} from '../../gql/useApproveMemberships';

const MainContainer = styled.div`
  padding: 30px;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const Membership = ({ data, isMembership }) => {
  const {
    approved,
    id,
    membership,
    divison,
    serial_number,
    user,
    purchase,
    section,
    row,
    seat,
  } = data;
  const [currentMember, setCurrentMember] = useState(null);

  const [shouldDisplayApproveModal, setShouldDisplayApproveModal] =
    useState(false);

  const [unCheck, setUncheck] = useState(false);

  const approveMember = useApproveMember();
  const unApproveMember = useUnApproveMember();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const theme = useTheme();

  async function onConfirmApprove() {
    const { id } = currentMember;
    return unCheck ? await unApproveMember(id) : await approveMember(id);
  }

  const now = moment(purchase.purchase_date, 'MM-DD-YYYY HH:mm:ss.SSSZ');
  const momentInSpecificTimezone = now.tz('America/New_York');
  const dateTimeStamp =
    momentInSpecificTimezone.format('MMM DD - YYYY h:mm A') + ' EST';

  return (
    <MainContainer>
      {!isMobile ? (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" padding={'10px'}>
                  Full Name
                </TableCell>
                {serial_number ? (
                  <TableCell scope="col" padding={'10px'}>
                    Member #
                  </TableCell>
                ) : null}

                <TableCell scope="col" padding={'10px'}>
                  {isMembership ? 'Membership Type' : 'Registration Type'}
                </TableCell>
                <TableCell scope="col" padding={'10px'}>
                  Division/Class
                </TableCell>
                <TableCell scope="col" padding={'10px'}>
                  Purchase Date
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell padding={'10px'}>{user.formatted_name}</TableCell>
                {serial_number ? (
                  <TableCell padding={'10px'}>{serial_number}</TableCell>
                ) : null}

                <TableCell padding={'10px'}>{membership.name}</TableCell>
                <TableCell padding={'10px'}>{divison}</TableCell>
                <TableCell padding={'10px'}>{dateTimeStamp}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {(row || seat || section) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
                padding: 10,
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  borderBottom: '1px solid #e9e9e9',
                  display: 'block',
                  paddingBottom: 5,
                }}
              >
                Reserved
              </p>
              <p style={{ padding: 10 }}>
                {section}
                {section && ','}
                {row}
                {row && ','}
                {seat}
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Full Name :
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {user.formatted_name}
            </Text>
          </ResponsiveCol>
          {isMembership ? (
            <ResponsiveCol>
              <Text
                type="bold"
                color={theme.colors.text.gray}
                inlineStyle={{ width: '35%', marginRight: 8 }}
              >
                Membership # :
              </Text>
            </ResponsiveCol>
          ) : null}
          {isMembership ? (
            <ResponsiveCol>
              <Text
                type="bold"
                fontWeight="600"
                inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
              >
                {serial_number}
              </Text>
            </ResponsiveCol>
          ) : null}

          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              {isMembership ? 'Membership Type :' : 'Registration Type :'}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {membership.name}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Division/Class :
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {divison}
            </Text>
          </ResponsiveCol>

          {(row || seat || section) && (
            <ResponsiveCol>
              <Text
                type="bold"
                color={theme.colors.text.gray}
                inlineStyle={{ width: '35%', marginRight: 8 }}
              >
                Reserved :
              </Text>
            </ResponsiveCol>
          )}
          {(row || seat || section) && (
            <ResponsiveCol>
              <Text
                type="bold"
                fontWeight="600"
                inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
              >
                {section}
                {section && ','}
                {row}
                {row && ','}
                {seat}
              </Text>
            </ResponsiveCol>
          )}
          {isMembership ? (
            <ButtonWrapper>
              <Button
                type="button"
                block
                onClick={() => {
                  setCurrentMember({ id });
                  setUncheck(!!approved);
                  setShouldDisplayApproveModal(true);
                }}
              >
                {`${approved ? 'Unapprove' : 'Approve'} Membership`}
              </Button>
            </ButtonWrapper>
          ) : null}
        </>
      )}
      <ApproveModal
        itemType="Member"
        isVisible={shouldDisplayApproveModal}
        onConfirm={onConfirmApprove}
        setIsVisible={setShouldDisplayApproveModal}
        unCheck={unCheck}
      />
    </MainContainer>
  );
};

export default Membership;
