import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const EVENT_PERFORMANCE_REPORT = gql`
  mutation GetEventPerformanceReport($event_id: Int!) {
    getEventPerformanceReport(event_id: $event_id) {
      event {
        name
        start_date
        end_date
        isMultiDay
        hide_fee
        track {
          name
        }
      }
      ticket_breakdown_data {
        qty
        name
        price
      }
      lap_sponsorships_data {
        id
        name
        total
      }
      lap_sponsorships_total
      net_sales
      marketing_fund
      sales_data {
        label
        amount
      }
      num_transactions
      num_tickets
      num_participants
      gross_sales
      label
      data {
        type
        total
        data {
          id
          purchaser
          total
          subtotal
          refund
          discount
          credit
          fee
          purchase_date
          ticket_holders {
            name
            tickets {
              name
              price
            }
          }
          participants {
            name
            tickets {
              name
              price
            }
          }
        }
      }
    }
  }
`;

export function useGetEventPerformanceReport() {
  const [eventPerformanceMutation] = useMutation(EVENT_PERFORMANCE_REPORT);

  const result = useCallback(
    (event_id) => {
      return eventPerformanceMutation({
        variables: {
          event_id: Number(event_id),
        },
      });
    },
    [eventPerformanceMutation]
  );
  return result;
}
