import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useSearchInput } from 'hooks/useSearchInput';

export const query = gql`
  query GetCategories($input: GetCategoriesInput!) {
    getCategories(input: $input) {
      count
      results {
        id
        name
        type
        icon_id
        icon
        code
        code_date
        code_time
      }
    }
  }
`;

export function useGetCategories() {
  const { input: search } = useSearchInput();

  return useQuery(query, {
    variables: {
      input: {
        ...search,
      },
    },
  });
}
