import React from 'react';
import { useTheme } from 'styled-components';
import { DateCard } from 'components/DateCard';
import Text from 'components/Text';

export const EventHeader = ({ event, margin = 40 }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin,
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <DateCard item={event} margin={'margin: 0 10px 0 0'} horizontal />
        <Text type="heading" fontSize={32} color={theme.colors.secondary}>
          {event.name}
        </Text>

        {'postponed' === event.status ? (
          <img
            style={{
              width: '10%',
              height: 'auto',
              display: 'block',
              marginLeft: 20,
              transform: 'rotate(10deg)',
            }}
            src="https://d3294qt0f4hbwl.cloudfront.net/postponed.png"
            alt="postponed-stamp"
          />
        ) : null}
        {'cancelled' === event.status ? (
          <img
            style={{
              width: '10%',
              height: 'auto',
              display: 'block',
              marginLeft: 20,
            }}
            src="https://d3294qt0f4hbwl.cloudfront.net/cancelled.png"
            alt="cancelled-stamp"
          />
        ) : null}
      </div>
      <div style={{ width: '20%', marginLeft: 40, maxWidth: 110 }}>
        <img
          style={{ width: '100%', height: 'auto', display: 'block' }}
          src={event?.logo ?? event.track?.logo}
          alt="logo"
        />
      </div>
    </div>
  );
};
