import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { Table } from 'components/Table';
import Text from 'components/Text';
import AddInfo from './AddInfo';
import DeleteInfo from './gql/delete-info';
import GetInfos from './gql/get-infos';

const Infos = (props) => {
  const { theme, data, deleteInfo } = props;
  const infos = data?.getInfos?.results ?? [];

  const [currentInfo, setCurrentInfo] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleOutClick = () => {
    setIsVisible(!isVisible);
    setCurrentInfo(null);
  };

  if (!data?.getInfos) return null;

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: '',
      key: 'actions',
    },
  ];

  function renderRows(info) {
    const { id, name } = info;
    return {
      name: (
        <Text style={{ fontSize: 16, color: theme.colors.primary }}>
          <LineHeightText> {name}</LineHeightText>
        </Text>
      ),
      actions: (
        <>
          <Icon
            icon="edit"
            size={18}
            color={theme.colors.primary}
            onClick={async () => {
              setCurrentInfo(id);
              setIsVisible(true);
            }}
            padding="0 15px 0 0"
          />
          <Icon
            icon="trash"
            size={18}
            color={theme.colors.primary}
            onClick={async () => {
              const successMessage = () =>
                toast.success('Info Tab Categories Successfully Deleted');
              const errorMessage = () =>
                toast.error('Error Deleting Info Tab Categories');

              if (
                window.confirm(
                  'Are you sure you want to delete this Info Tab Categories?'
                )
              ) {
                const response = await deleteInfo(id);
                if (!response || response.errors) {
                  errorMessage();
                } else successMessage();
              }
            }}
          />
        </>
      ),
    };
  }

  return (
    <>
      <SearchableListContainer
        pageCount={data.getInfos.count}
        paginated
        title="Info Tab Categories"
        searchInputPlaceholder="Search Info Tab Categories"
        onAddClick={() => setIsVisible(true)}
      >
        <Table items={infos} columns={columns} renderRows={renderRows} />
      </SearchableListContainer>

      <AddInfo
        isVisible={isVisible}
        handleOutClick={handleOutClick}
        currentInfo={currentInfo}
      />
    </>
  );
};

export default withTheme(compose(GetInfos, DeleteInfo)(Infos));
