import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import styled, { useTheme } from 'styled-components';
import { EventHeaderMobile } from 'components/Events';
import { Modal } from 'components/Modal';
import { useRefundEventV2 } from '../gql/mutation/useRefundEventV2';
import { useCalculateEventRefundFees } from '../gql/queries/calculate-event-refund-fees';
import { useGetRefundSteps } from '../gql/queries/get-refund-steps';
import { RefundEventContent } from './RefundEventContent';
import { RefundVideo } from './RefundVideo';

const Card = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.18);
  margin-inline: auto;
  max-height: calc(100% - 120px);
  width: 95%;
`;

export const RefundEventFlow = ({ isVisible, close, event }) => {
  const theme = useTheme();
  const history = useHistory();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [step, setStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const refundEvent = useRefundEventV2();

  const { data, loading } = useGetRefundSteps(isVisible);
  const steps = data?.getRefundSteps;

  const { data: refundFeeData } = useCalculateEventRefundFees(
    step === steps?.length + 1,
    selectedOptions
  );

  useEffect(() => {
    if (!isVisible) {
      setStep(0);
    }
  }, [isVisible]);

  const onContinue = async () => {
    if (
      Object.keys(selectedOptions)?.length === steps.length &&
      step !== steps.length + 1
    ) {
      setStep(steps.length);
    } else if (step === steps.length + 1) {
      try {
        const response = await refundEvent(selectedOptions);
        if (response && !response.errors) {
          toast.success('Event refunded successfully');

          !isMobile ? close() : history.pop();
        } else {
          toast.error('Error refunding event');
        }
      } catch (error) {
        toast.error('Error refunding event');

        console.error('Error calculating refund fees', error);
      }
    } else setStep(step + 1);
  };

  const onOpenVideo = () => {
    setIsVideoOpen(true);
  };

  if (loading || !isVisible || !steps || steps.length === 0) return null;

  return isMobile ? (
    <>
      <EventHeaderMobile queryDate={event?.date} noDropdown />
      <Card>
        <RefundEventContent
          step={step}
          setStep={setStep}
          steps={steps}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          onContinue={onContinue}
          onOpenVideo={onOpenVideo}
          isMobile={isMobile}
          theme={theme}
          close={close}
          refundFeeData={refundFeeData}
          event={event}
        />
      </Card>
    </>
  ) : (
    <Modal
      isVisible={isVisible}
      hideModal={close}
      maxWidth={600}
      noHeader
      modalStyle={{ position: 'static' }}
    >
      <RefundEventContent
        step={step}
        setStep={setStep}
        steps={steps}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        onContinue={onContinue}
        onOpenVideo={onOpenVideo}
        isMobile={isMobile}
        theme={theme}
        close={close}
        refundFeeData={refundFeeData}
        event={event}
      />

      <RefundVideo
        isVisible={isVideoOpen}
        close={() => setIsVideoOpen(false)}
        url={steps[step]?.video_url}
      />
    </Modal>
  );
};
