import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled, { withTheme } from 'styled-components';
import { ExportExcelButton } from 'components/ExportExcelButton/ExportExcelButton';
import { formatPhoneNumber } from 'shared/formatters';
import { useGetUserExport } from './gql/mutations/get-users-export';

const Container = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 20px 40px 0 40px;
  padding: 0;
  width: calc(100% - 80px);

  &:focus {
    outline: none;
  }

  @media (max-width: 859px) {
    display: none;
  }
`;

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const exportCvs = async (getUserExport, setDisabled) => {
  setDisabled(true);
  const response = await getUserExport();

  if (!response || response.errors) {
    toast.error('Ticket Holder List can not be retrieved');
    return;
  }

  if (response?.data?.getUserExportV2) {
    const data = response?.data?.getUserExportV2;

    const csv = `
Name, Email, Phone, Address, Role, Races Attended, Purchases, Total, # Favorites
${data
  .map(
    (value) =>
      `"${value.name}","${value.email}","${
        value.cellphone ? formatPhoneNumber(value.cellphone) : ''
      }","${value.address ? value.address : ''}","${value.role}","${
        value.races
      }","${value.purchases}","${value.lifetimeValue}","${
        value.favoritesCount
      }"`
  )
  .join('\n')}`;

    download('UsersList.csv', csv.trim());
  }

  setDisabled(false);
};

const Export = () => {
  const getUserExport = useGetUserExport();
  const [disabled, setDisabled] = useState(false);
  return (
    <Container
      onClick={() => exportCvs(getUserExport, setDisabled)}
      disabled={disabled}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
      <ExportExcelButton />
    </Container>
  );
};

export default withTheme(Export);
