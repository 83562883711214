import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useCart } from 'shared/CartContext';
import { useGetGATickets } from './gql/queries/useGetGATickets';
// import Badge from './icons/badge';
// import Fastfood from './icons/fastfood';
// import Merch from './icons/merch';
// import TicketIcon from './icons/ticketIcon';
import SeatsManagerModal from './SeatsManager';
import { Ticket } from './Ticket';

const ListContainer = styled.div`
  flex-grow: 1; /* Grow to fill remaining space in LeftContainer */
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden; /* Prevent content overflow */
`;

const TicketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  font-weight: bold;
  flex-shrink: 0; /* Prevent shrinking or growing vertically */
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0; /* Fixed padding */
  justify-content: start;
  gap: 5px;
  overflow-x: auto;
  flex-shrink: 0; /* Prevent shrinking or growing vertically */

  @media (max-width: 768px) {
    max-width: -webkit-fill-available;
  }
`;

const TicketContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 66px;
  flex: 1;
  cursor: pointer;
  background-color: ${(props) => (!props.selected ? 'transparent' : '#fa4616')};
  border: ${(props) => (props.selected ? null : '2px solid rgb(250, 70, 22)')};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  color: ${(props) => (props.selected ? 'white' : 'rgb(250, 70, 22)')};
  min-width: fit-content;
`;

const ButtonText = styled.span`
  //styleName: PP - H3;
  font-family: Barlow Condensed;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
`;

const TicketsContainer = styled.div`
  flex-grow: 1; /* Grow to fill available space in ListContainer */
  overflow-y: auto; /* Enable vertical scrolling */
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FilteredImage = styled.img`
  width: 28px;
  height: 28px;
  filter: ${(props) =>
    props.selected
      ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)' // Pure white
      : 'brightness(0) saturate(100%) invert(42%) sepia(96%) saturate(1352%) hue-rotate(349deg) brightness(97%) contrast(105%)'}; // #fa4616 orange
`;

export const TicketList = () => {
  const {
    tickets,
    setTickets,
    setTypes,
    seatsModalIsVisible,
    setSeatsModalIsVisible,
    setTicketCount,
    selected,
    setSelected,
    chartRef,
  } = useCart();

  const { data, loading } = useGetGATickets();

  useEffect(() => {
    if (!loading && data?.getGATicketsV2.length > 0) {
      // Compare the saved tickets and the tickets from the API based on id and price, if it's the same, set the count to the saved count
      const savedTickets = localStorage.getItem(`tickets`);
      const storedTickets = JSON.parse(savedTickets);

      let ticketCount = 0;

      const ticketWithCount = Object.keys(data.getGATicketsV2)?.reduce(
        (acc, key) => {
          setTypes((types) =>
            types?.length > 0
              ? types.concat(data.getGATicketsV2[key]?.type)
              : [data.getGATicketsV2[key]?.type]
          );

          return {
            ...acc,
            [key]: {
              ...data.getGATicketsV2[key],
              tickets: data.getGATicketsV2[key]?.tickets?.map((ticket) => {
                const savedTicket =
                  storedTickets &&
                  storedTickets[key]?.tickets?.find(
                    (t) =>
                      t.id === ticket.id && t.track_price === ticket.track_price
                  );

                if (savedTicket) ticketCount += savedTicket.count;

                return {
                  ...ticket,
                  price: ticket.track_price ? ticket.track_price : ticket.price,
                  count: savedTicket ? savedTicket.count : 0,
                  ...(ticket.reserved_seat
                    ? { seats: savedTicket?.seats }
                    : null),
                };
              }),
            },
          };
        },
        {}
      );

      setTicketCount(ticketCount);
      setTickets(ticketWithCount);

      // Set the selected ticket to the first ticket in the list
      setSelected(Object.keys(ticketWithCount)[0]);
    }
  }, [
    data,
    loading,
    setSelected,
    setTicketCount,
    setTickets,
    setTypes,
    tickets.reserved_seat,
  ]);

  useEffect(() => {
    if (seatsModalIsVisible) {
      if (chartRef.current?.chart) chartRef.current.chart.resetView();

      setSeatsModalIsVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef]);

  return (
    <ListContainer>
      <TabContainer>
        {Object.keys(tickets)?.map((key) => (
          <TicketContainer
            key={key}
            selected={selected === key}
            onClick={() => setSelected(key)}
          >
            <FilteredImage
              selected={selected === key}
              src={tickets[key]?.type?.logo}
              alt={`${tickets[key]?.type?.name}`}
            />
            <ButtonText>{tickets[key]?.type?.name}</ButtonText>
          </TicketContainer>
        ))}
      </TabContainer>

      <TicketHeader>
        <span style={{ marginLeft: '24px' }}>TICKET TYPE</span>
      </TicketHeader>
      <TicketsContainer>
        {tickets[selected]?.tickets?.map((ticket) => (
          <Ticket ticket={ticket} key={ticket.id} />
        ))}
      </TicketsContainer>

      <SeatsManagerModal
        isVisible={seatsModalIsVisible}
        setIsVisible={setSeatsModalIsVisible}
      />
    </ListContainer>
  );
};
