import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { SearchInput } from 'components/Form/SearchInput';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import Text from 'components/Text';
import ToolTip from 'components/Tooltip';
import {
  TransactionModal,
  useGetTrackTransactionsGetEvent,
} from 'components/Transactions';
import { useGetTrackTransaction } from 'components/Transactions/gql/useGetPurchase';
import {
  TransactionDateFilter,
  withTransactionDateFilterContext,
} from 'components/Transactions/TransactionDateFilter';
import { useSearchInput, useUpdateQueryString } from 'hooks/useSearchInput';
import { TitleContainer } from 'pages/track/Contact/style';
import { formatPhoneNumber, formatTimestampEST } from 'shared/formatters';
import { useTransactionFilter } from 'shared/transactionFilterContext';
import { is_cellphone } from '../Users';
import { TransactionsListHeader as Header } from './header';

export const OrderOptions = [
  {
    label: 'Last Name',
    value: 'name',
  },
  {
    label: 'Date',
    value: 'date',
  },
];

export const SourceOptions = [
  { label: 'App', value: 'app' },
  { label: 'POS', value: 'pos' },
];

export const UserOptions = [
  { label: 'Known', value: 'known' },
  { label: 'Anonymous', value: 'anonymous' },
];

export const PaymentOptions = [
  { label: 'Card', value: 'card' },
  { label: 'Cash', value: 'cash' },
];

export const DeliveryOptions = [
  { label: 'App', value: 'app' },
  { label: 'Mobile', value: 'mobile' },
  { label: 'Ticket', value: 'ticket' },
];

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: start;
  max-width: 85vw;
  width: 100%;
`;

const AutoSuggestContainer = styled.div`
  margin: 10px;
  min-width: 150px;
`;

const Transactions = withTransactionDateFilterContext((props) => {
  const { location, match } = props;
  const theme = useTheme();

  const [currentTransaction, setCurrentTransaction] = useState(null);

  const {
    sortBy,
    setSortBy,
    source,
    setSource,
    userType,
    setUserType,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    paymentMethod,
    setPaymentMethod,
    deliveryMethod,
    setDeliveryMethod,
  } = useTransactionFilter();

  const updateQueryString = useUpdateQueryString();
  const [searchInput, setSearchInput] = useState('');

  const { data } = useGetTrackTransactionsGetEvent();
  const { purchase_id } = qs.parse(window.location.search.substring(1));

  const { pathname } = useLocation();
  const userTypeRoute = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  const [fetch, setFetch] = useState(false);
  const { data: purchase } = useGetTrackTransaction({
    id: purchase_id,
    fetch: fetch,
  });

  useEffect(() => {
    if (purchase_id && data?.getTrackTransactionsV2?.results) {
      const transaction = purchase
        ? purchase.getTrackTransaction
        : data.getTrackTransactionsV2?.results.find(
            (transaction) => transaction.id === Number(purchase_id)
          );

      if (transaction) setCurrentTransaction(transaction);
      else {
        setFetch(true);
      }
    }
  }, [data, purchase_id, purchase]);

  const handleChange = (e) => setSearchInput(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const { input } = useSearchInput();
  const currentPage = input.page ? Number(input.page) : 1;

  if (!data?.getEvent && !data?.getTrackTransactionsV2) return null;

  return (
    <>
      <Header event={data} {...props} />
      <Container>
        <ContainerHeader
          borderWidth={'1px'}
          style={{ position: 'relative', height: 160, width: '97%' }}
        >
          <TitleContainer style={{ flexDirection: 'column', width: '100%' }}>
            <FilterContainer
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                paddingRight: 10,
              }}
            >
              <FilterContainer>
                <Text
                  type="heading"
                  color={theme.colors.secondary}
                  inlineStyle={{
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    marginRight: 25,
                  }}
                >
                  Transactions
                </Text>

                <AutoSuggestContainer>
                  <AutoSuggest
                    placeholder="Filter By"
                    options={OrderOptions}
                    value={sortBy}
                    isSearchable
                    isClearable
                    onChange={(value) => {
                      setFetch(true);
                      setSortBy(value ?? null);
                    }}
                  />
                </AutoSuggestContainer>

                <div
                  style={{
                    maxWidth: 300,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <SearchInput
                    placeholder={'Search Transactions'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyPress}
                    value={searchInput}
                    style={{ marginRight: 15 }}
                  />
                  <ToolTip
                    title="Search Criteria"
                    info={true}
                    content={'Filter by Last Name'}
                  />
                </div>
              </FilterContainer>

              <Text
                type="heading"
                as="h3"
                color={theme.colors.text.header}
                inlineStyle={{ marginRight: 20, whiteSpace: 'nowrap' }}
              >
                {`Sales: ${data.getEvent.sales}`}
              </Text>
            </FilterContainer>

            <FilterContainer>
              <div style={{ margin: '5px 0' }}>
                <TransactionDateFilter
                  customStartDate={startDateFilter}
                  customSetStartDateFilter={setStartDateFilter}
                  customEndDate={endDateFilter}
                  customSetEndDateFilter={setEndDateFilter}
                />
              </div>

              <AutoSuggestContainer>
                <AutoSuggest
                  placeholder="Source"
                  options={SourceOptions}
                  value={source}
                  isSearchable
                  isClearable
                  onChange={(value) => {
                    setFetch(true);
                    setSource(value ?? null);
                  }}
                />
              </AutoSuggestContainer>
              {source?.value === 'pos' && (
                <AutoSuggestContainer>
                  <AutoSuggest
                    placeholder="User Type"
                    options={UserOptions}
                    value={userType}
                    isSearchable
                    isClearable
                    onChange={(value) => {
                      setFetch(true);
                      setUserType(value ?? null);
                    }}
                  />
                </AutoSuggestContainer>
              )}
              {source?.value === 'pos' && (
                <AutoSuggestContainer>
                  <AutoSuggest
                    placeholder="Payment Method"
                    options={PaymentOptions}
                    value={paymentMethod}
                    isSearchable
                    isClearable
                    onChange={(value) => {
                      setFetch(true);
                      setPaymentMethod(value ?? null);
                    }}
                  />
                </AutoSuggestContainer>
              )}
              {source?.value === 'pos' && (
                <AutoSuggestContainer>
                  <AutoSuggest
                    placeholder="Delivery Method"
                    options={DeliveryOptions}
                    value={deliveryMethod}
                    isSearchable
                    isClearable
                    onChange={(value) => {
                      setFetch(true);
                      setDeliveryMethod(value ?? null);
                    }}
                  />
                </AutoSuggestContainer>
              )}
            </FilterContainer>
          </TitleContainer>
        </ContainerHeader>

        <div style={{ padding: 20 }}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" style={{ padding: '15px 4px' }}>
                  Date/Time
                </TableCell>
                <TableCell scope="col" style={{ padding: '15px 4px' }}>
                  Purchaser Name
                </TableCell>
                <TableCell scope="col" style={{ padding: '15px 4px' }}>
                  Order Total
                </TableCell>
                <TableCell scope="col" style={{ padding: '15px 4px' }}>
                  Discount
                </TableCell>
                <TableCell scope="col" style={{ padding: '15px 4px' }}>
                  Refund
                </TableCell>
                <TableCell scope="col" style={{ padding: '15px 4px' }}>
                  Credit
                </TableCell>
                {data?.getEvent?.hide_fee && (
                  <TableCell scope="col" style={{ padding: '15px 4px' }}>
                    Fee
                  </TableCell>
                )}
                <TableCell scope="col" style={{ padding: '15px 4px' }}>
                  Transaction Total
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!data.getTrackTransactionsV2?.results.length &&
              data?.variables?.getTrackTransactionsInput.queryString ? (
                <p
                  style={{
                    color: '#000',
                    fontSize: 20,
                    fontFamily: 'Barlow Condensed',
                    fontWeight: 600,
                    paddingTop: 20,
                  }}
                >
                  No transactions with this name
                </p>
              ) : !data.getTrackTransactionsV2?.results.length ? (
                <p
                  style={{
                    color: '#000',
                    fontSize: 20,
                    fontFamily: 'Barlow Condensed',
                    fontWeight: 600,
                    paddingTop: 20,
                  }}
                >
                  No transactions for this event yet
                </p>
              ) : null}
              {data.getTrackTransactionsV2?.results.map((transaction) => (
                <TableRow key={0}>
                  <TableCell style={{ padding: '15px 4px' }}>
                    {formatTimestampEST(Number(transaction.purchase_date))}
                  </TableCell>
                  <TableCell style={{ padding: '15px 4px' }}>
                    <LineHeightText>
                      <Link
                        to={{
                          pathname:
                            userTypeRoute === 'admin'
                              ? `/admin/transactions/detail/${transaction.id}`
                              : `/admin-${userTypeRoute}/transactions/detail/${transaction.id}`,
                          state: { event: data?.getEvent },
                        }}
                        style={{
                          color: theme.colors.primary,
                        }}
                      >
                        {transaction.user
                          ? is_cellphone(transaction.user.last_name)
                            ? formatPhoneNumber(transaction.user.last_name)
                            : `${transaction.user.last_name}, ${transaction.user.first_name}`
                          : transaction.id}
                      </Link>
                    </LineHeightText>
                  </TableCell>
                  <TableCell style={{ padding: '15px 4px' }}>
                    {transaction.subtotal ?? '$0.00'}
                  </TableCell>
                  <TableCell style={{ padding: '15px 4px' }}>
                    {`-${transaction.discount}` ?? '-$0.00'}
                  </TableCell>
                  <TableCell style={{ padding: '15px 4px' }}>
                    {transaction.refund ? `-${transaction.refund}` : '-$0.00'}
                  </TableCell>
                  <TableCell style={{ padding: '15px 4px' }}>
                    {`-${transaction.credit}` ?? '-$0.00'}
                  </TableCell>
                  {data?.getEvent?.hide_fee && (
                    <TableCell style={{ padding: '15px 4px' }}>
                      {`-${transaction.fee}` ?? '-$0.00'}
                    </TableCell>
                  )}
                  <TableCell style={{ padding: '15px 4px' }}>
                    {transaction.total ?? '$0.00'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination
            count={data.getTrackTransactionsV2?.count ?? 0}
            currentPage={currentPage}
          />
        </div>
      </Container>
      {currentTransaction ? (
        <TransactionModal
          admin={location.pathname.includes('/admin/transactions/')}
          match={match}
          currentTransaction={currentTransaction}
          close={() => {
            setCurrentTransaction(null);
          }}
        />
      ) : null}
    </>
  );
});

export default Transactions;
