import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { DatePicker } from 'components/Form/DatePicker';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import Text from 'components/Text';
import { ResponsiveCol, ResponsiveRow } from '../AddEvents/styles';

const VisibilityOptions = [
  { value: 'visible', label: 'Visible' },
  { value: 'hidden', label: 'Hidden' },
];

const RegistrationTickets = ({
  handleChange,
  COLOR_CODES,
  ticket,
  index,
  ticketType,
  errors,
  allCategories,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Fragment>
      <ResponsiveRow style={{ alignItems: 'center' }}>
        <Text
          style={{ marginRight: '15px' }}
          type="label"
          color={theme.colors.black}
          inlineStyle={{ textTransform: 'capitalize' }}
        >
          Registration ticket
        </Text>
        <ResponsiveCol>
          <DatePicker
            name={`${ticketType}.${index}.start_date`}
            error={
              errors[`${ticketType}.${index}.start_date`]
                ? errors[`${ticketType}.${index}.start_date`]
                : ''
            }
            type="date"
            label=""
            value={ticket.start_date}
            onChange={(_, value) => {
              handleChange({ target: { name: 'start_date', value } });
              errors[`${ticketType}.${index}.start_date`] = '';
            }}
            isResponsive={true}
          />
        </ResponsiveCol>
        <ResponsiveCol>
          <DatePicker
            name={`${ticketType}.${index}.end_date`}
            error={
              errors[`${ticketType}.${index}.end_date`]
                ? errors[`${ticketType}.${index}.end_date`]
                : ''
            }
            type="date"
            label=""
            value={ticket.end_date}
            onChange={(_, value) => {
              handleChange({ target: { name: 'end_date', value } });
              errors[`${ticketType}.${index}.end_date`] = '';
            }}
            isResponsive={true}
          />
        </ResponsiveCol>
      </ResponsiveRow>

      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 70%' : '0 0 100%' }}>
          <Input
            id={`${ticketType}.${index}.name`}
            name={`${ticketType}.${index}.name`}
            error={
              errors[`${ticketType}.${index}.name`]
                ? errors[`${ticketType}.${index}.name`]
                : ''
            }
            label="Ticket Name"
            placeholder=""
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `name`,
                  value: target.value,
                },
              });
              errors[`${ticketType}.${index}.name`] = '';
            }}
            value={ticket.name ?? ''}
          />
        </ResponsiveCol>
        <ResponsiveCol>
          <Input
            id={`${ticketType}.${index}.price`}
            name={`${ticketType}.${index}.price`}
            error={
              errors[`${ticketType}.${index}.price`]
                ? errors[`${ticketType}.${index}.price`]
                : ''
            }
            label="Ticket Price"
            type="number"
            placeholder=""
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `price`,
                  value: target.value ? parseFloat(target.value) : '',
                },
              });
              errors[`${ticketType}.${index}.price`] = '';
            }}
            value={ticket.price ?? ''}
          />
        </ResponsiveCol>
      </ResponsiveRow>

      <ResponsiveRow>
        <ResponsiveCol>
          <Input
            id={`${ticketType}.${index}.form_id`}
            name={`${ticketType}.${index}.form_id`}
            label="Form"
            placeholder="Formstack Form ID"
            type="number"
            onChange={({ target }) =>
              handleChange({
                target: {
                  name: `form_id`,
                  value: target.value,
                },
              })
            }
            value={ticket.form_id}
          />
        </ResponsiveCol>
      </ResponsiveRow>
      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 70%' : '0 0 100%' }}>
          <Input
            as="textarea"
            rows={4}
            inputStyle={{ minHeight: 'unset' }}
            id={`${ticketType}.${index}.description`}
            name={`${ticketType}.${index}.description`}
            label="Description"
            placeholder=""
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `description`,
                  value: target.value,
                },
              });
            }}
            value={ticket.description ?? ''}
          />
        </ResponsiveCol>
        <ResponsiveCol>
          <Select
            id={`${ticketType}.${index}.color_code`}
            name={`${ticketType}.${index}.color_code`}
            error={
              errors[`${ticketType}.${index}.color_code`]
                ? errors[`${ticketType}.${index}.color_code`]
                : ''
            }
            label="Color Code"
            placeholder="Select a Color"
            options={COLOR_CODES}
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `color_code`,
                  value: target.value,
                },
              });
              errors[`${ticketType}.${index}.color_code`] = '';
            }}
            value={ticket.color_code}
            backgroundColor={ticket.color_code}
            changeTextColor={true}
          />
          <Select
            id={`${ticketType}.${index}.category_id`}
            name={`${ticketType}.${index}.category_id`}
            error={
              errors[`${ticketType}.${index}.category_id`]
                ? errors[`${ticketType}.${index}.category_id`]
                : ''
            }
            label="Ticket Category"
            placeholder="Category"
            options={allCategories}
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `category_id`,
                  value: +target.value,
                },
              });
              errors[`${ticketType}.${index}.category_id`] = '';
            }}
            value={ticket.category_id}
          />
          <Select
            id={`${ticketType}.${index}.visibility`}
            name={`${ticketType}.${index}.visibility`}
            error={
              errors[`${ticketType}.${index}.visibility`]
                ? errors[`${ticketType}.${index}.visibility`]
                : ''
            }
            label="Visibility"
            placeholder="Visibility"
            options={VisibilityOptions}
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `visibility`,
                  value: target.value,
                },
              });
              errors[`${ticketType}.${index}.visibility`] = '';
            }}
            value={ticket.visibility ?? 'visible'}
          />
        </ResponsiveCol>
      </ResponsiveRow>
    </Fragment>
  );
};

export default RegistrationTickets;
