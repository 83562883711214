import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import { HideOnMobile, HideOnDesktop } from 'components/Card/cardStyle';
import Icon from 'components/Icon';
import { SearchableListContainer } from 'components/SearchableListContainer';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import Text from 'components/Text';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import DeletePromo from './gql/mutations/delete-promo';
import { GetPromos } from './gql/queries/get-promos';
import { GetPromosV2 } from './gql/queries/get-promos-v2';
import PromosEdit from './PromosEdit';

const Promos = ({ match, deletePromo, ...props }) => {
  const {
    state: { capabilities, accountId },
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const userType = pathname.includes('/admin/')
    ? 'admin'
    : pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = urlParams.get('page');

  const { data } =
    userType === 'admin' ? GetPromos(currentPage) : GetPromosV2(currentPage);

  const [isVisible, setIsVisible] = useState(false);
  const [editPromo, setEditPromo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newPromo, setNewPromo] = useState(false);

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const handleOutClick = () => {
    setEditPromo(null);
    setShowModal(false);
    setNewPromo(false);
    setIsVisible(!isVisible);
  };

  const adminTrack = window.location.pathname.indexOf(`/admin-${userType}`);

  if (!data) return false;

  const { results, count } =
    userType === 'admin' ? data?.getPromos : data?.getPromosV2;

  return data.loading ? (
    <div />
  ) : (
    <>
      <SearchableListContainer
        onAddClick={
          !capabilities || capabilities?.add_promos
            ? () => {
                setIsVisible(true);
                setNewPromo(true);
                setShowModal(true);
              }
            : () => setShowPermissionModal(true)
        }
        paginated
        pageCount={count}
        searchInputPlaceholder="Search Discounts"
        title="Discounts"
      >
        <HideOnMobile>
          <Table>
            <TableHeader>
              <TableRow>
                {adminTrack === 0 ? null : (
                  <TableCell scope="col">Issuer</TableCell>
                )}
                <TableCell scope="col">Code Name</TableCell>
                <TableCell scope="col">Expiration</TableCell>
                <TableCell scope="col">Ticket Discount %</TableCell>
                <TableCell scope="col">Fee Discount %</TableCell>
                <TableCell scope="col">Use Limit</TableCell>
                <TableCell scope="col" />
              </TableRow>
            </TableHeader>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item.id}>
                  {adminTrack === 0 ? null : (
                    <TableCell>{item.issuer || 'Admin'}</TableCell>
                  )}
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {item.expiration ? item.expiration : 'None'}
                  </TableCell>
                  <TableCell>{`${item.ticket_discount}%` || 0}</TableCell>
                  <TableCell>{`${item.service_discount}%` || 0}</TableCell>
                  <TableCell>{item.limit}</TableCell>
                  <TableCell>
                    <Icon
                      icon="edit"
                      size={18}
                      color={props.theme.colors.primary}
                      padding="0 15px 0 0"
                      onClick={async () => {
                        setEditPromo(item.id);
                        setIsVisible(true);
                        setTimeout(() => {
                          setShowModal(true);
                        }, 300);
                      }}
                    />
                    <Icon
                      icon="trash"
                      size={18}
                      color={props.theme.colors.primary}
                      onClick={async () => {
                        const successMessage = () =>
                          toast.success('Discount Successfully Deleted');
                        const errorMessage = () =>
                          toast.error('Error Deleting Discount');

                        if (
                          window.confirm(
                            'Are you sure you want to delete this discount?'
                          )
                        ) {
                          const response = await deletePromo(
                            {
                              id: item.id,
                            },
                            accountId
                          );
                          if (!response || response.errors) {
                            errorMessage();
                          }
                          successMessage();
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </HideOnMobile>
        <HideOnDesktop>
          {results.map((item) => (
            <Link
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                textDecoration: 'none',
                marginBottom: 20,
              }}
              to={`${match.url}/${item.id}`}
            >
              <Text
                type="heading"
                color={props.theme.colors.secondary}
                inlineStyle={{ textTransform: 'uppercase' }}
              >
                {item.name}
              </Text>
              <div style={{ transform: 'rotate(-90deg)' }}>
                <Icon
                  size={22}
                  icon="chevron"
                  color={props.theme.colors.primary}
                />
              </div>
            </Link>
          ))}
        </HideOnDesktop>
      </SearchableListContainer>
      {isVisible && (
        <PromosEdit
          newPromo={newPromo}
          isVisible={showModal}
          handleOutClick={handleOutClick}
          currentPromo={editPromo}
        />
      )}

      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/1`}
        history={props.history}
      />
    </>
  );
};

export default withTheme(compose(DeletePromo)(Promos));
